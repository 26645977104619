var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "sub-menu-container" }, [
    _c(
      "ul",
      { staticClass: "sub-menu-li-container" },
      [
        _c("woot-dropdown-header", { attrs: { title: _vm.title } }),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }