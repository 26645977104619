var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideoIntegrationEnabled
    ? _c("woot-button", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip.top-end",
            value: _vm.$t(
              "INTEGRATION_SETTINGS.DYTE.START_VIDEO_CALL_HELP_TEXT"
            ),
            expression:
              "\n    $t('INTEGRATION_SETTINGS.DYTE.START_VIDEO_CALL_HELP_TEXT')\n  ",
            modifiers: { "top-end": true }
          }
        ],
        attrs: {
          icon: "video",
          "is-loading": _vm.isLoading,
          "color-scheme": "secondary",
          variant: "smooth",
          size: "small"
        },
        on: { click: _vm.onClick }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }