var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns profile--settings" },
    [
      _vm.uiFlags.isFetchingItem
        ? _c("woot-loading-state")
        : !_vm.hasABillingPlan
        ? _c("div", [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("BILLING_SETTINGS.NO_BILLING_USER")))
            ])
          ])
        : _c(
            "div",
            { staticClass: "small-12 columns " },
            [
              _c("div", { staticClass: "current-plan--details" }, [
                _c("h6", [
                  _vm._v(_vm._s(_vm.$t("BILLING_SETTINGS.CURRENT_PLAN.TITLE")))
                ]),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.formatMessage(
                        _vm.$t("BILLING_SETTINGS.CURRENT_PLAN.PLAN_NOTE", {
                          plan: _vm.planName,
                          quantity: _vm.subscribedQuantity
                        })
                      ),
                      expression:
                        "\n          formatMessage(\n            $t('BILLING_SETTINGS.CURRENT_PLAN.PLAN_NOTE', {\n              plan: planName,\n              quantity: subscribedQuantity,\n            })\n          )\n        "
                    }
                  ]
                })
              ]),
              _vm._v(" "),
              _c("billing-item", {
                attrs: {
                  title: _vm.$t("BILLING_SETTINGS.MANAGE_SUBSCRIPTION.TITLE"),
                  description: _vm.$t(
                    "BILLING_SETTINGS.MANAGE_SUBSCRIPTION.DESCRIPTION"
                  ),
                  "button-label": _vm.$t(
                    "BILLING_SETTINGS.MANAGE_SUBSCRIPTION.BUTTON_TXT"
                  )
                },
                on: { click: _vm.onClickBillingPortal }
              }),
              _vm._v(" "),
              _c("billing-item", {
                attrs: {
                  title: _vm.$t("BILLING_SETTINGS.CHAT_WITH_US.TITLE"),
                  description: _vm.$t(
                    "BILLING_SETTINGS.CHAT_WITH_US.DESCRIPTION"
                  ),
                  "button-label": _vm.$t(
                    "BILLING_SETTINGS.CHAT_WITH_US.BUTTON_TXT"
                  ),
                  "button-icon": "chat-multiple"
                },
                on: { click: _vm.onToggleChatWindow }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }