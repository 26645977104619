var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "settings--content" }, [
    _c("div", { staticClass: "pre-chat--title" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("INBOX_MGMT.PRE_CHAT_FORM.DESCRIPTION")) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.updateInbox($event)
          }
        }
      },
      [
        _c("label", { staticClass: "medium-3 columns" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("INBOX_MGMT.PRE_CHAT_FORM.ENABLE.LABEL")) +
              "\n      "
          ),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.preChatFormEnabled,
                  expression: "preChatFormEnabled"
                }
              ],
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.preChatFormEnabled = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c("option", { domProps: { value: true } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.ENABLED")
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("option", { domProps: { value: false } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("INBOX_MGMT.PRE_CHAT_FORM.ENABLE.OPTIONS.DISABLED")
                    ) +
                    "\n        "
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _vm.preChatFormEnabled
          ? _c("div", [
              _c("label", { staticClass: "medium-3 columns" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("INBOX_MGMT.PRE_CHAT_FORM.PRE_CHAT_MESSAGE.LABEL")
                    ) +
                    "\n        "
                ),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.preChatMessage,
                      expression: "preChatMessage",
                      modifiers: { trim: true }
                    }
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "INBOX_MGMT.PRE_CHAT_FORM.PRE_CHAT_MESSAGE.PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.preChatMessage },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.preChatMessage = $event.target.value.trim()
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-8 columns" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS")))
                ]),
                _vm._v(" "),
                _c(
                  "table",
                  { staticClass: "table table-striped w-full" },
                  [
                    _c("thead", { staticClass: "thead-dark" }, [
                      _c("tr", [
                        _c("th", { attrs: { scope: "col" } }),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.KEY"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.TYPE"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.REQUIRED"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.LABEL"
                                )
                              ) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "INBOX_MGMT.PRE_CHAT_FORM.SET_FIELDS_HEADER.PLACE_HOLDER"
                                )
                              ) +
                              "\n              "
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("pre-chat-fields", {
                      attrs: { "pre-chat-fields": _vm.preChatFields },
                      on: {
                        update: _vm.handlePreChatFieldOptions,
                        "drag-end": _vm.changePreChatFieldFieldsOrder
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("woot-submit-button", {
          attrs: {
            "button-text": _vm.$t("INBOX_MGMT.SETTINGS_POPUP.UPDATE"),
            loading: _vm.uiFlags.isUpdating
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }