var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("woot-message-editor", {
        staticClass: "input--note",
        attrs: {
          placeholder: _vm.$t("NOTES.ADD.PLACEHOLDER"),
          "enable-suggestions": false
        },
        model: {
          value: _vm.noteContent,
          callback: function($$v) {
            _vm.noteContent = $$v
          },
          expression: "noteContent"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "woot-button",
            {
              attrs: {
                "color-scheme": "warning",
                title: _vm.$t("NOTES.ADD.TITLE"),
                "is-disabled": _vm.buttonDisabled
              },
              on: { click: _vm.onAdd }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("NOTES.ADD.BUTTON")) + " (⌘⏎)\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }