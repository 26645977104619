var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.title,
          "header-content": _vm.message,
          "header-content-value": _vm.messageValue
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-footer delete-item" },
        [
          _c(
            "woot-button",
            {
              staticClass: "action-button",
              attrs: { variant: "clear" },
              on: { click: _vm.onClose }
            },
            [_vm._v("\n      " + _vm._s(_vm.rejectText) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "woot-button",
            {
              staticClass: "action-button",
              attrs: { "color-scheme": "alert", variant: "smooth" },
              on: { click: _vm.onConfirm }
            },
            [_vm._v("\n      " + _vm._s(_vm.confirmText) + "\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }