var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "small-2 report-card",
      class: { active: _vm.selected },
      on: {
        click: function($event) {
          return _vm.onClick(_vm.index)
        }
      }
    },
    [
      _c(
        "h3",
        { staticClass: "heading" },
        [
          _c("span", [_vm._v(_vm._s(_vm.heading))]),
          _vm._v(" "),
          _vm.infoText
            ? _c("fluent-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.infoText,
                    expression: "infoText"
                  }
                ],
                staticClass: "info-icon",
                attrs: { size: "14", icon: "info" }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "metric-wrap" }, [
        _c("h4", { staticClass: "metric" }, [
          _vm._v("\n      " + _vm._s(_vm.point) + "\n    ")
        ]),
        _vm._v(" "),
        _vm.trend !== 0
          ? _c("span", { class: _vm.trendClass }, [
              _vm._v(_vm._s(_vm.trendValue))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "desc" }, [
        _vm._v("\n    " + _vm._s(_vm.desc) + "\n  ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }