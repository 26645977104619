var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-body small-12 medium-9 columns height-auto" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.AUTH.TITLE"),
          "header-content": _vm.useInstallationName(
            _vm.$t("INBOX_MGMT.ADD.AUTH.DESC"),
            _vm.globalConfig.installationName
          )
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row channel-list" },
        _vm._l(_vm.channelList, function(channel) {
          return _c("channel-item", {
            key: channel.key,
            attrs: {
              channel: channel,
              "enabled-features": _vm.enabledFeatures
            },
            on: { "channel-item-click": _vm.initChannelAuth }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }