var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "csat--table-container" },
    [
      _c("ve-table", {
        attrs: {
          "max-height": "calc(100vh - 35rem)",
          "fixed-header": true,
          columns: _vm.columns,
          "table-data": _vm.tableData
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.tableData.length,
              expression: "!tableData.length"
            }
          ],
          staticClass: "csat--empty-records"
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("CSAT_REPORTS.NO_RECORDS")) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.metrics.totalResponseCount
        ? _c(
            "div",
            { staticClass: "table-pagination" },
            [
              _c("ve-pagination", {
                attrs: {
                  total: _vm.metrics.totalResponseCount,
                  "page-index": _vm.pageIndex,
                  "page-size": 25,
                  "page-size-option": [25]
                },
                on: { "on-page-number-change": _vm.onPageNumberChange }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }