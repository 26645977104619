var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closePortalPopover,
          expression: "closePortalPopover"
        }
      ],
      staticClass: "portal-popover__container"
    },
    [
      _c("header", [
        _c("div", { staticClass: "actions" }, [
          _c("h2", { staticClass: "block-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("HELP_CENTER.PORTAL.POPOVER.TITLE")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "woot-button",
                {
                  attrs: {
                    variant: "smooth",
                    "color-scheme": "secondary",
                    icon: "settings",
                    size: "small"
                  },
                  on: { click: _vm.openPortalPage }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("HELP_CENTER.PORTAL.POPOVER.PORTAL_SETTINGS")
                      ) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("woot-button", {
                attrs: {
                  variant: "clear",
                  "color-scheme": "secondary",
                  icon: "dismiss",
                  size: "small"
                },
                on: { click: _vm.closePortalPopover }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "subtitle" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("HELP_CENTER.PORTAL.POPOVER.SUBTITLE")) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        _vm._l(_vm.portals, function(portal) {
          return _c("portal-switch", {
            key: portal.id,
            attrs: {
              portal: portal,
              "active-portal-slug": _vm.activePortalSlug,
              "active-locale": _vm.activeLocale,
              active: portal.slug === _vm.activePortalSlug
            },
            on: {
              "open-portal-page": _vm.closePortalPopover,
              "fetch-portal": _vm.fetchPortalAndItsCategories
            }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }