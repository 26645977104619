var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "draggable",
    {
      attrs: { tag: "tbody" },
      on: { end: _vm.onDragEnd },
      model: {
        value: _vm.preChatFieldOptions,
        callback: function($$v) {
          _vm.preChatFieldOptions = $$v
        },
        expression: "preChatFieldOptions"
      }
    },
    _vm._l(_vm.preChatFieldOptions, function(item, index) {
      return _c("tr", { key: index }, [
        _c(
          "td",
          { staticClass: "pre-chat-field" },
          [_c("fluent-icon", { attrs: { icon: "drag" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "td",
          { staticClass: "pre-chat-field" },
          [
            _c("woot-switch", {
              attrs: { value: item["enabled"] },
              on: {
                input: function($event) {
                  return _vm.handlePreChatFieldOptions($event, "enabled", item)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "td",
          {
            staticClass: "pre-chat-field",
            class: { "disabled-text": !item["enabled"] }
          },
          [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "td",
          {
            staticClass: "pre-chat-field",
            class: { "disabled-text": !item["enabled"] }
          },
          [_vm._v("\n      " + _vm._s(item.type) + "\n    ")]
        ),
        _vm._v(" "),
        _c("td", { staticClass: "pre-chat-field" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: item["required"],
                expression: "item['required']"
              }
            ],
            attrs: { type: "checkbox", disabled: !item["enabled"] },
            domProps: {
              value: item.name + "-required",
              checked: Array.isArray(item["required"])
                ? _vm._i(item["required"], item.name + "-required") > -1
                : item["required"]
            },
            on: {
              click: function($event) {
                return _vm.handlePreChatFieldOptions($event, "required", item)
              },
              change: function($event) {
                var $$a = item["required"],
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = item.name + "-required",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(item, "required", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        item,
                        "required",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(item, "required", $$c)
                }
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "td",
          {
            staticClass: "pre-chat-field",
            class: { "disabled-text": !item["enabled"] }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: item.label,
                  expression: "item.label",
                  modifiers: { trim: true }
                }
              ],
              attrs: { type: "text", disabled: _vm.isFieldEditable(item) },
              domProps: { value: item.label },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(item, "label", $event.target.value.trim())
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "td",
          {
            staticClass: "pre-chat-field",
            class: { "disabled-text": !item["enabled"] }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: item.placeholder,
                  expression: "item.placeholder",
                  modifiers: { trim: true }
                }
              ],
              attrs: { type: "text", disabled: _vm.isFieldEditable(item) },
              domProps: { value: item.placeholder },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(item, "placeholder", $event.target.value.trim())
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }