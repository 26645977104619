var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length
    ? _c("div", { ref: "mentionsListContainer", staticClass: "mention--box" }, [
        _c(
          "ul",
          { staticClass: "vertical dropdown menu" },
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "woot-dropdown-item",
              {
                key: item.key,
                attrs: { id: "mention-item-" + index },
                on: {
                  mouseover: function($event) {
                    return _vm.onHover(index)
                  }
                }
              },
              [
                _c(
                  "woot-button",
                  {
                    staticClass: "canned-item__button",
                    class: { active: index === _vm.selectedIndex },
                    attrs: {
                      variant: index === _vm.selectedIndex ? "" : "clear"
                    },
                    on: {
                      click: function($event) {
                        return _vm.onListItemSelection(index)
                      }
                    }
                  },
                  [
                    _c("strong", [_vm._v(_vm._s(item.label))]),
                    _vm._v(" - " + _vm._s(item.description) + "\n      ")
                  ]
                )
              ],
              1
            )
          }),
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }