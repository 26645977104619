var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      ref: "dropdownMenu",
      staticClass: "dropdown menu vertical",
      class: [_vm.placement && "dropdown--" + _vm.placement]
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }