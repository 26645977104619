var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-conversation--panel" },
    [
      !_vm.uiFlags.isFetching
        ? _c("div", { staticClass: "contact-conversation__wrap" }, [
            !_vm.previousConversations.length
              ? _c("div", { staticClass: "no-label-message" }, [
                  _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("CONTACT_PANEL.CONVERSATIONS.NO_RECORDS_FOUND")
                        ) +
                        "\n      "
                    )
                  ])
                ])
              : _c(
                  "div",
                  { staticClass: "contact-conversation--list" },
                  _vm._l(_vm.previousConversations, function(conversation) {
                    return _c("conversation-card", {
                      key: conversation.id,
                      staticClass: "compact",
                      attrs: {
                        chat: conversation,
                        "hide-inbox-name": false,
                        "hide-thumbnail": true
                      }
                    })
                  }),
                  1
                )
          ])
        : _c("spinner")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }