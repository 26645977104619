var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-dropdown-item", [
    _c(
      "div",
      { staticClass: "item-wrap" },
      [
        _c(
          "woot-button",
          { attrs: { variant: "clear" }, on: { click: _vm.onClick } },
          [
            _c("div", { staticClass: "button-wrap" }, [
              _c("div", { staticClass: "name-label-wrap" }, [
                _vm.color
                  ? _c("div", {
                      staticClass: "label-color--display",
                      style: { backgroundColor: _vm.color }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "label-text", attrs: { title: _vm.title } },
                  [_vm._v(_vm._s(_vm.title))]
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _vm.selected
                  ? _c("i", { staticClass: "icon ion-checkmark-round" })
                  : _vm._e()
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }