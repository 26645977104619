var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("AGENT_MGMT.ADD.TITLE"),
              "header-content": _vm.$t("AGENT_MGMT.ADD.DESC")
            }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "row",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.addAgent()
                }
              }
            },
            [
              _c("div", { staticClass: "medium-12 columns" }, [
                _c("label", { class: { error: _vm.$v.agentName.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("AGENT_MGMT.ADD.FORM.NAME.LABEL")) +
                      "\n          "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.agentName,
                        expression: "agentName",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "AGENT_MGMT.ADD.FORM.NAME.PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.agentName },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.agentName = $event.target.value.trim()
                        },
                        _vm.$v.agentName.$touch
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns" }, [
                _c("label", { class: { error: _vm.$v.agentType.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("AGENT_MGMT.ADD.FORM.AGENT_TYPE.LABEL")) +
                      "\n          "
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.agentType,
                          expression: "agentType"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.agentType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.roles, function(role) {
                      return _c(
                        "option",
                        { key: role.name, domProps: { value: role.name } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(role.label) +
                              "\n            "
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.$v.agentType.$error
                    ? _c("span", { staticClass: "message" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("AGENT_MGMT.ADD.FORM.AGENT_TYPE.ERROR")
                            ) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns" }, [
                _c("label", { class: { error: _vm.$v.agentEmail.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("AGENT_MGMT.ADD.FORM.EMAIL.LABEL")) +
                      "\n          "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.agentEmail,
                        expression: "agentEmail",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.agentEmail },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.agentEmail = $event.target.value.trim()
                        },
                        _vm.$v.agentEmail.$touch
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "div",
                  { staticClass: "medium-12 columns" },
                  [
                    _c("woot-submit-button", {
                      attrs: {
                        disabled:
                          _vm.$v.agentEmail.$invalid ||
                          _vm.$v.agentName.$invalid ||
                          _vm.uiFlags.isCreating,
                        "button-text": _vm.$t("AGENT_MGMT.ADD.FORM.SUBMIT"),
                        loading: _vm.uiFlags.isCreating
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClose($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("AGENT_MGMT.ADD.CANCEL_BUTTON_TEXT")
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }