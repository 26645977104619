var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "macro-preview" },
    [
      _c("h6", { staticClass: "text-block-title macro-title" }, [
        _vm._v(_vm._s(_vm.macro.name))
      ]),
      _vm._v(" "),
      _vm._l(_vm.resolvedMacro, function(action, i) {
        return _c("div", { key: i, staticClass: "macro-block" }, [
          i !== _vm.macro.actions.length - 1
            ? _c("div", { staticClass: "macro-block-border" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "macro-block-dot" }),
          _vm._v(" "),
          _c("p", { staticClass: "macro-action-name" }, [
            _vm._v(_vm._s(action.actionName))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "macro-action-params" }, [
            _vm._v(_vm._s(action.actionValue))
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }