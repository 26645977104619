var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "woot-button",
        {
          staticClass: "join-call-button",
          attrs: {
            size: "small",
            variant: "smooth",
            "color-scheme": "secondary",
            icon: "video-add",
            "is-loading": _vm.isLoading
          },
          on: { click: _vm.joinTheCall }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("INTEGRATION_SETTINGS.DYTE.CLICK_HERE_TO_JOIN")) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _vm.dyteAuthToken
        ? _c(
            "div",
            { staticClass: "video-call--container" },
            [
              _c("iframe", {
                attrs: {
                  src: _vm.meetingLink,
                  allow:
                    "camera;microphone;fullscreen;display-capture;picture-in-picture;clipboard-write;"
                }
              }),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  staticClass: "join-call-button",
                  attrs: {
                    size: "small",
                    variant: "smooth",
                    "color-scheme": "secondary"
                  },
                  on: { click: _vm.leaveTheRoom }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("INTEGRATION_SETTINGS.DYTE.LEAVE_THE_ROOM")
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }