var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "settings-section",
        {
          attrs: {
            title: _vm.$t("INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS"),
            "sub-title": _vm.$t(
              "INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT"
            )
          }
        },
        [
          _c("multiselect", {
            attrs: {
              options: _vm.agentList,
              "track-by": "id",
              label: "name",
              multiple: true,
              "close-on-select": false,
              "clear-on-select": false,
              "hide-selected": true,
              placeholder: "Pick some",
              "selected-label": "",
              "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
              "deselect-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_REMOVE")
            },
            on: { select: _vm.$v.selectedAgents.$touch },
            model: {
              value: _vm.selectedAgents,
              callback: function($$v) {
                _vm.selectedAgents = $$v
              },
              expression: "selectedAgents"
            }
          }),
          _vm._v(" "),
          _c("woot-submit-button", {
            attrs: {
              "button-text": _vm.$t("INBOX_MGMT.SETTINGS_POPUP.UPDATE"),
              loading: _vm.isAgentListUpdating
            },
            on: { click: _vm.updateAgents }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "settings-section",
        {
          attrs: {
            title: _vm.$t("INBOX_MGMT.SETTINGS_POPUP.AGENT_ASSIGNMENT"),
            "sub-title": _vm.$t(
              "INBOX_MGMT.SETTINGS_POPUP.AGENT_ASSIGNMENT_SUB_TEXT"
            )
          }
        },
        [
          _c("label", { staticClass: "medium-9 columns settings-item" }, [
            _c("div", { staticClass: "enter-to-send--checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.enableAutoAssignment,
                    expression: "enableAutoAssignment"
                  }
                ],
                attrs: { id: "enableAutoAssignment", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.enableAutoAssignment)
                    ? _vm._i(_vm.enableAutoAssignment, null) > -1
                    : _vm.enableAutoAssignment
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.enableAutoAssignment,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.enableAutoAssignment = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.enableAutoAssignment = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.enableAutoAssignment = $$c
                      }
                    },
                    _vm.handleEnableAutoAssignment
                  ]
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "enableAutoAssignment" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT")
                    ) +
                    "\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "help-text" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT_SUB_TEXT")
                  ) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.enableAutoAssignment && _vm.isEnterprise
            ? _c(
                "div",
                { staticClass: "max-assignment-container" },
                [
                  _c("woot-input", {
                    class: { error: _vm.$v.maxAssignmentLimit.$error },
                    attrs: {
                      type: "number",
                      error: _vm.maxAssignmentLimitErrors,
                      label: _vm.$t(
                        "INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT"
                      )
                    },
                    on: { blur: _vm.$v.maxAssignmentLimit.$touch },
                    model: {
                      value: _vm.maxAssignmentLimit,
                      callback: function($$v) {
                        _vm.maxAssignmentLimit =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "maxAssignmentLimit"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "help-text" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT_SUB_TEXT"
                          )
                        ) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("woot-submit-button", {
                    attrs: {
                      "button-text": _vm.$t("INBOX_MGMT.SETTINGS_POPUP.UPDATE"),
                      disabled: _vm.$v.maxAssignmentLimit.$invalid
                    },
                    on: { click: _vm.updateInbox }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }