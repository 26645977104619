var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.activeLabels.length,
          expression: "activeLabels.length"
        }
      ],
      ref: "labelContainer",
      staticClass: "label-container"
    },
    [
      _c(
        "div",
        { staticClass: "labels-wrap", class: { expand: _vm.showAllLabels } },
        [
          _vm._l(_vm.activeLabels, function(label, index) {
            return _c("woot-label", {
              key: label.id,
              class: {
                hidden: !_vm.showAllLabels && index > _vm.labelPosition
              },
              attrs: {
                title: label.title,
                description: label.description,
                color: label.color,
                variant: "smooth",
                small: ""
              }
            })
          }),
          _vm._v(" "),
          _vm.showExpandLabelButton
            ? _c("woot-button", {
                staticClass: "show-more--button",
                attrs: {
                  title: _vm.showAllLabels
                    ? _vm.$t("CONVERSATION.CARD.HIDE_LABELS")
                    : _vm.$t("CONVERSATION.CARD.SHOW_LABELS"),
                  "color-scheme": "secondary",
                  variant: "hollow",
                  icon: _vm.showAllLabels ? "chevron-left" : "chevron-right",
                  size: "tiny"
                },
                on: { click: _vm.onShowLabels }
              })
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }