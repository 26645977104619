var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.thumbnailBoxClass,
      style: { height: _vm.size, width: _vm.size },
      attrs: { title: _vm.title }
    },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.shouldShowImage,
            expression: "shouldShowImage"
          }
        ],
        class: _vm.thumbnailClass,
        attrs: { src: _vm.src },
        on: { load: _vm.onImgLoad, error: _vm.onImgError }
      }),
      _vm._v(" "),
      _c("Avatar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.shouldShowImage,
            expression: "!shouldShowImage"
          }
        ],
        class: _vm.thumbnailClass,
        attrs: { username: _vm.userNameWithoutEmoji, size: _vm.avatarSize }
      }),
      _vm._v(" "),
      _vm.badgeSrc
        ? _c("img", {
            staticClass: "source-badge",
            style: _vm.badgeStyle,
            attrs: {
              src: "/integrations/channels/badges/" + _vm.badgeSrc + ".png",
              alt: "Badge"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showStatusIndicator
        ? _c("div", {
            class:
              "source-badge user-online-status user-online-status--" +
              _vm.status,
            style: _vm.statusStyle
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }