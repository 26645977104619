var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "conversation-details-wrap",
      class: { "with-border-left": !_vm.isOnExpandedLayout }
    },
    [
      _vm.currentChat.id
        ? _c("conversation-header", {
            attrs: {
              chat: _vm.currentChat,
              "is-contact-panel-open": _vm.isContactPanelOpen,
              "show-back-button": _vm.isOnExpandedLayout
            },
            on: { "contact-panel-toggle": _vm.onToggleContactPanel }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.dashboardApps.length && _vm.currentChat.id
        ? _c(
            "woot-tabs",
            {
              staticClass: "dashboard-app--tabs",
              attrs: { index: _vm.activeIndex },
              on: { change: _vm.onDashboardAppTabChange }
            },
            _vm._l(_vm.dashboardAppTabs, function(tab) {
              return _c("woot-tabs-item", {
                key: tab.key,
                attrs: { name: tab.name, "show-badge": false }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.activeIndex
        ? _c(
            "div",
            { staticClass: "messages-and-sidebar" },
            [
              _vm.currentChat.id
                ? _c("messages-view", {
                    attrs: {
                      "inbox-id": _vm.inboxId,
                      "is-contact-panel-open": _vm.isContactPanelOpen
                    },
                    on: { "contact-panel-toggle": _vm.onToggleContactPanel }
                  })
                : _c("empty-state", {
                    attrs: { "is-on-expanded-layout": _vm.isOnExpandedLayout }
                  }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showContactPanel,
                      expression: "showContactPanel"
                    }
                  ],
                  staticClass: "conversation-sidebar-wrap"
                },
                [
                  _vm.showContactPanel
                    ? _c("contact-panel", {
                        attrs: {
                          "conversation-id": _vm.currentChat.id,
                          "inbox-id": _vm.currentChat.inbox_id,
                          "on-toggle": _vm.onToggleContactPanel
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _c("dashboard-app-frame", {
            key: _vm.currentChat.id + "-" + _vm.activeIndex,
            attrs: {
              config: _vm.dashboardApps[_vm.activeIndex - 1].content,
              "current-chat": _vm.currentChat
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }