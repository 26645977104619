var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-dropdown-menu",
    [
      _c("woot-dropdown-header", {
        attrs: { title: _vm.$t("SIDEBAR.SET_AVAILABILITY_TITLE") }
      }),
      _vm._v(" "),
      _vm._l(_vm.availabilityStatuses, function(status) {
        return _c(
          "woot-dropdown-item",
          { key: status.value, staticClass: "status-items" },
          [
            _c(
              "woot-button",
              {
                attrs: {
                  size: "small",
                  "color-scheme": status.disabled ? "" : "secondary",
                  variant: status.disabled ? "smooth" : "clear",
                  "class-names": "status-change--dropdown-button"
                },
                on: {
                  click: function($event) {
                    return _vm.changeAvailabilityStatus(status.value)
                  }
                }
              },
              [
                _c("availability-status-badge", {
                  attrs: { status: status.value }
                }),
                _vm._v("\n      " + _vm._s(status.label) + "\n    ")
              ],
              1
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("woot-dropdown-divider"),
      _vm._v(" "),
      _c(
        "woot-dropdown-item",
        { staticClass: "auto-offline--toggle" },
        [
          _c(
            "div",
            { staticClass: "info-wrap" },
            [
              _c("fluent-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.right-start",
                    value: _vm.$t("SIDEBAR.SET_AUTO_OFFLINE.INFO_TEXT"),
                    expression: "$t('SIDEBAR.SET_AUTO_OFFLINE.INFO_TEXT')",
                    modifiers: { "right-start": true }
                  }
                ],
                staticClass: "info-icon",
                attrs: { icon: "info", size: "14" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "auto-offline--text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("SIDEBAR.SET_AUTO_OFFLINE.TEXT")) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("woot-switch", {
            staticClass: "auto-offline--switch",
            attrs: { size: "small", value: _vm.currentUserAutoOffline },
            on: { input: _vm.updateAutoOffline }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-dropdown-divider")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }