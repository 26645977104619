var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-container flex-dir-column medium-flex-dir-row" },
    [
      _c(
        "div",
        { staticClass: "small-12 medium-3 pull-right" },
        [
          _c("multiselect", {
            attrs: {
              "track-by": "name",
              label: "name",
              placeholder: _vm.$t("FORMS.MULTISELECT.SELECT_ONE"),
              "selected-label": "",
              "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
              "deselect-label": "",
              options: _vm.dateRange,
              searchable: false,
              "allow-empty": false
            },
            on: { select: _vm.changeDateSelection },
            model: {
              value: _vm.currentDateRangeSelection,
              callback: function($$v) {
                _vm.currentDateRangeSelection = $$v
              },
              expression: "currentDateRangeSelection"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isDateRangeSelected
        ? _c("woot-date-range-picker", {
            attrs: {
              "show-range": "",
              value: _vm.customDateRange,
              "confirm-text": _vm.$t("REPORT.CUSTOM_DATE_RANGE.CONFIRM"),
              placeholder: _vm.$t("REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER")
            },
            on: { change: _vm.onChange }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.notLast7Days && _vm.groupByFilter
        ? _c(
            "div",
            { staticClass: "small-12 medium-3 pull-right margin-left-small" },
            [
              _c(
                "p",
                { staticClass: "hide", attrs: { "aria-hidden": "true" } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("REPORT.GROUP_BY_FILTER_DROPDOWN_LABEL")) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  "track-by": "id",
                  label: "groupBy",
                  placeholder: _vm.$t("REPORT.GROUP_BY_FILTER_DROPDOWN_LABEL"),
                  options: _vm.filterItemsList,
                  "allow-empty": false,
                  "show-labels": false
                },
                on: { input: _vm.changeFilterSelection },
                model: {
                  value: _vm.currentSelectedFilter,
                  callback: function($$v) {
                    _vm.currentSelectedFilter = $$v
                  },
                  expression: "currentSelectedFilter"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.agentsFilter
        ? _c(
            "div",
            { staticClass: "small-12 medium-3 pull-right margin-left-small" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.agentsFilterItemsList,
                  "track-by": "id",
                  label: "name",
                  multiple: true,
                  "close-on-select": false,
                  "clear-on-select": false,
                  "hide-selected": true,
                  placeholder: _vm.$t(
                    "CSAT_REPORTS.FILTERS.AGENTS.PLACEHOLDER"
                  ),
                  "selected-label": "",
                  "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                  "deselect-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_REMOVE")
                },
                on: { input: _vm.handleAgentsFilterSelection },
                model: {
                  value: _vm.selectedAgents,
                  callback: function($$v) {
                    _vm.selectedAgents = $$v
                  },
                  expression: "selectedAgents"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showBusinessHoursSwitch
        ? _c("div", { staticClass: "small-12 medium-3 business-hours" }, [
            _c(
              "span",
              { staticClass: "business-hours-text margin-right-small" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("REPORT.BUSINESS_HOURS")) +
                    "\n    "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              [
                _c("woot-switch", {
                  model: {
                    value: _vm.businessHoursSelected,
                    callback: function($$v) {
                      _vm.businessHoursSelected = $$v
                    },
                    expression: "businessHoursSelected"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }