var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "woot-sidebar" },
    [
      _c("primary-sidebar", {
        attrs: {
          "logo-source": _vm.globalConfig.logoThumbnail,
          "installation-name": _vm.globalConfig.installationName,
          "is-a-custom-branded-instance": _vm.isACustomBrandedInstance,
          "account-id": _vm.accountId,
          "menu-items": _vm.primaryMenuItems,
          "active-menu-item": _vm.activePrimaryMenu.key
        },
        on: {
          "toggle-accounts": _vm.toggleAccountModal,
          "key-shortcut-modal": _vm.toggleKeyShortcutModal,
          "open-notification-panel": _vm.openNotificationPanel
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "secondary-sidebar" },
        [
          _vm.showSecondarySidebar
            ? _c("secondary-sidebar", {
                class: _vm.sidebarClassName,
                attrs: {
                  "account-id": _vm.accountId,
                  inboxes: _vm.inboxes,
                  labels: _vm.labels,
                  teams: _vm.teams,
                  "custom-views": _vm.customViews,
                  "menu-config": _vm.activeSecondaryMenu,
                  "current-role": _vm.currentRole,
                  "is-on-chatwoot-cloud": _vm.isOnChatwootCloud
                },
                on: {
                  "add-label": _vm.showAddLabelPopup,
                  "toggle-accounts": _vm.toggleAccountModal
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }