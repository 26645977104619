var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm._v("\n  " + _vm._s(_vm.textToBeDisplayed) + "\n  "),
    _vm.text.length > _vm.limit
      ? _c(
          "button",
          {
            staticClass: "show-more--button",
            on: { click: _vm.toggleShowMore }
          },
          [_vm._v("\n    " + _vm._s(_vm.buttonLabel) + "\n  ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }