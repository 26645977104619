var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showShowCurrentAccountContext
    ? _c(
        "div",
        {
          staticClass: "account-context--group",
          on: { mouseover: _vm.setShowSwitch, mouseleave: _vm.resetShowSwitch }
        },
        [
          _vm._v(
            "\n  " +
              _vm._s(_vm.$t("SIDEBAR.CURRENTLY_VIEWING_ACCOUNT")) +
              "\n  "
          ),
          _c("p", { staticClass: "account-context--name text-ellipsis" }, [
            _vm._v("\n    " + _vm._s(_vm.account.name) + "\n  ")
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.showSwitchButton
              ? _c(
                  "div",
                  { staticClass: "account-context--switch-group" },
                  [
                    _c(
                      "woot-button",
                      {
                        staticClass: "switch-button",
                        attrs: {
                          variant: "clear",
                          size: "tiny",
                          icon: "arrow-swap"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("toggle-accounts")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("SIDEBAR.SWITCH")) +
                            "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }