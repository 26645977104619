var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("MERGE_CONTACTS.TITLE"),
          "header-content": _vm.$t("MERGE_CONTACTS.DESCRIPTION")
        }
      }),
      _vm._v(" "),
      _c("merge-contact", {
        attrs: {
          "primary-contact": _vm.primaryContact,
          "is-searching": _vm.isSearching,
          "is-merging": _vm.uiFlags.isMerging,
          "search-results": _vm.searchResults
        },
        on: {
          search: _vm.onContactSearch,
          cancel: _vm.onClose,
          submit: _vm.onMergeContacts
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }