var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: { "header-title": _vm.$t("ATTRIBUTES_MGMT.ADD.TITLE") }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "row",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.addAttributes($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "medium-12 columns" },
                [
                  _c(
                    "label",
                    { class: { error: _vm.$v.attributeModel.$error } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("ATTRIBUTES_MGMT.ADD.FORM.MODEL.LABEL")
                          ) +
                          "\n          "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.attributeModel,
                              expression: "attributeModel"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.attributeModel = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.models, function(model) {
                          return _c(
                            "option",
                            { key: model.id, domProps: { value: model.id } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(model.option) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.$v.attributeModel.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("ATTRIBUTES_MGMT.ADD.FORM.MODEL.ERROR")
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("woot-input", {
                    class: { error: _vm.$v.displayName.$error },
                    attrs: {
                      label: _vm.$t("ATTRIBUTES_MGMT.ADD.FORM.NAME.LABEL"),
                      type: "text",
                      error: _vm.$v.displayName.$error
                        ? _vm.$t("ATTRIBUTES_MGMT.ADD.FORM.NAME.ERROR")
                        : "",
                      placeholder: _vm.$t(
                        "ATTRIBUTES_MGMT.ADD.FORM.NAME.PLACEHOLDER"
                      )
                    },
                    on: {
                      input: _vm.onDisplayNameChange,
                      blur: _vm.$v.displayName.$touch
                    },
                    model: {
                      value: _vm.displayName,
                      callback: function($$v) {
                        _vm.displayName = $$v
                      },
                      expression: "displayName"
                    }
                  }),
                  _vm._v(" "),
                  _c("woot-input", {
                    class: { error: _vm.$v.attributeKey.$error },
                    attrs: {
                      label: _vm.$t("ATTRIBUTES_MGMT.ADD.FORM.KEY.LABEL"),
                      type: "text",
                      error: _vm.$v.attributeKey.$error
                        ? _vm.keyErrorMessage
                        : "",
                      placeholder: _vm.$t(
                        "ATTRIBUTES_MGMT.ADD.FORM.KEY.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.attributeKey.$touch },
                    model: {
                      value: _vm.attributeKey,
                      callback: function($$v) {
                        _vm.attributeKey = $$v
                      },
                      expression: "attributeKey"
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { class: { error: _vm.$v.description.$error } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("ATTRIBUTES_MGMT.ADD.FORM.DESC.LABEL")) +
                        "\n          "
                    ),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.description,
                          expression: "description"
                        }
                      ],
                      attrs: {
                        rows: "3",
                        type: "text",
                        placeholder: _vm.$t(
                          "ATTRIBUTES_MGMT.ADD.FORM.DESC.PLACEHOLDER"
                        )
                      },
                      domProps: { value: _vm.description },
                      on: {
                        blur: _vm.$v.description.$touch,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.description = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.description.$error
                      ? _c("span", { staticClass: "message" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("ATTRIBUTES_MGMT.ADD.FORM.DESC.ERROR")
                              ) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    { class: { error: _vm.$v.attributeType.$error } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("ATTRIBUTES_MGMT.ADD.FORM.TYPE.LABEL")
                          ) +
                          "\n          "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.attributeType,
                              expression: "attributeType"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.attributeType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.types, function(type) {
                          return _c(
                            "option",
                            { key: type.id, domProps: { value: type.id } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(type.option) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.$v.attributeType.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("ATTRIBUTES_MGMT.ADD.FORM.TYPE.ERROR")
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isAttributeTypeList
                    ? _c(
                        "div",
                        { staticClass: "multiselect--wrap" },
                        [
                          _c("label", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.LABEL"
                                  )
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("multiselect", {
                            ref: "tagInput",
                            class: { invalid: _vm.isMultiselectInvalid },
                            attrs: {
                              placeholder: _vm.$t(
                                "ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.PLACEHOLDER"
                              ),
                              label: "name",
                              "track-by": "name",
                              options: _vm.options,
                              multiple: true,
                              taggable: true
                            },
                            on: { close: _vm.onTouch, tag: _vm.addTagValue },
                            model: {
                              value: _vm.values,
                              callback: function($$v) {
                                _vm.values = $$v
                              },
                              expression: "values"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isMultiselectInvalid,
                                  expression: "isMultiselectInvalid"
                                }
                              ],
                              staticClass: "error-message"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.ERROR"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-footer" },
                    [
                      _c("woot-submit-button", {
                        attrs: {
                          disabled: _vm.isButtonDisabled,
                          "button-text": _vm.$t("ATTRIBUTES_MGMT.ADD.SUBMIT")
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button clear",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onClose($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("ATTRIBUTES_MGMT.ADD.CANCEL_BUTTON_TEXT")
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }