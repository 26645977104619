var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings--content" },
    [
      _c(
        "settings-section",
        {
          attrs: {
            title: _vm.$t("INBOX_MGMT.SMTP.TITLE"),
            "sub-title": _vm.$t("INBOX_MGMT.SMTP.SUBTITLE")
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.updateInbox($event)
                }
              }
            },
            [
              _c("label", { attrs: { for: "toggle-enable-smtp" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isSMTPEnabled,
                      expression: "isSMTPEnabled"
                    }
                  ],
                  attrs: { type: "checkbox", name: "toggle-enable-smtp" },
                  domProps: {
                    checked: Array.isArray(_vm.isSMTPEnabled)
                      ? _vm._i(_vm.isSMTPEnabled, null) > -1
                      : _vm.isSMTPEnabled
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.isSMTPEnabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isSMTPEnabled = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isSMTPEnabled = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isSMTPEnabled = $$c
                      }
                    }
                  }
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("INBOX_MGMT.SMTP.TOGGLE_AVAILABILITY")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.$t("INBOX_MGMT.SMTP.TOGGLE_HELP")))]),
              _vm._v(" "),
              _vm.isSMTPEnabled
                ? _c(
                    "div",
                    { staticClass: "smtp-details-wrap" },
                    [
                      _c("woot-input", {
                        staticClass: "medium-9 columns",
                        class: { error: _vm.$v.address.$error },
                        attrs: {
                          label: _vm.$t("INBOX_MGMT.SMTP.ADDRESS.LABEL"),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.SMTP.ADDRESS.PLACE_HOLDER"
                          )
                        },
                        on: { blur: _vm.$v.address.$touch },
                        model: {
                          value: _vm.address,
                          callback: function($$v) {
                            _vm.address =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "address"
                        }
                      }),
                      _vm._v(" "),
                      _c("woot-input", {
                        staticClass: "medium-9 columns",
                        class: { error: _vm.$v.port.$error },
                        attrs: {
                          type: "number",
                          label: _vm.$t("INBOX_MGMT.SMTP.PORT.LABEL"),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.SMTP.PORT.PLACE_HOLDER"
                          )
                        },
                        on: { blur: _vm.$v.port.$touch },
                        model: {
                          value: _vm.port,
                          callback: function($$v) {
                            _vm.port = $$v
                          },
                          expression: "port"
                        }
                      }),
                      _vm._v(" "),
                      _c("woot-input", {
                        staticClass: "medium-9 columns",
                        class: { error: _vm.$v.login.$error },
                        attrs: {
                          label: _vm.$t("INBOX_MGMT.SMTP.LOGIN.LABEL"),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.SMTP.LOGIN.PLACE_HOLDER"
                          )
                        },
                        on: { blur: _vm.$v.login.$touch },
                        model: {
                          value: _vm.login,
                          callback: function($$v) {
                            _vm.login = $$v
                          },
                          expression: "login"
                        }
                      }),
                      _vm._v(" "),
                      _c("woot-input", {
                        staticClass: "medium-9 columns",
                        class: { error: _vm.$v.password.$error },
                        attrs: {
                          label: _vm.$t("INBOX_MGMT.SMTP.PASSWORD.LABEL"),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.SMTP.PASSWORD.PLACE_HOLDER"
                          ),
                          type: "password"
                        },
                        on: { blur: _vm.$v.password.$touch },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _vm._v(" "),
                      _c("woot-input", {
                        staticClass: "medium-9 columns",
                        class: { error: _vm.$v.domain.$error },
                        attrs: {
                          label: _vm.$t("INBOX_MGMT.SMTP.DOMAIN.LABEL"),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.SMTP.DOMAIN.PLACE_HOLDER"
                          )
                        },
                        on: { blur: _vm.$v.domain.$touch },
                        model: {
                          value: _vm.domain,
                          callback: function($$v) {
                            _vm.domain =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "domain"
                        }
                      }),
                      _vm._v(" "),
                      _c("input-radio-group", {
                        attrs: {
                          label: _vm.$t("INBOX_MGMT.SMTP.ENCRYPTION"),
                          items: _vm.encryptionProtocols,
                          action: _vm.handleEncryptionChange
                        }
                      }),
                      _vm._v(" "),
                      _c("single-select-dropdown", {
                        staticClass: "medium-9 columns",
                        attrs: {
                          label: _vm.$t("INBOX_MGMT.SMTP.OPEN_SSL_VERIFY_MODE"),
                          selected: _vm.openSSLVerifyMode,
                          options: _vm.openSSLVerifyModes,
                          action: _vm.handleSSLModeChange
                        }
                      }),
                      _vm._v(" "),
                      _c("single-select-dropdown", {
                        staticClass: "medium-9 columns",
                        attrs: {
                          label: _vm.$t("INBOX_MGMT.SMTP.AUTH_MECHANISM"),
                          selected: _vm.authMechanism,
                          options: _vm.authMechanisms,
                          action: _vm.handleAuthMechanismChange
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("woot-submit-button", {
                attrs: {
                  "button-text": _vm.$t("INBOX_MGMT.SMTP.UPDATE"),
                  loading: _vm.uiFlags.isUpdatingSMTP,
                  disabled:
                    (_vm.$v.$invalid && _vm.isSMTPEnabled) ||
                    _vm.uiFlags.isUpdatingSMTP
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }