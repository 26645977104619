var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "location message-text__wrap" }, [
    _c(
      "div",
      { staticClass: "icon-wrap" },
      [
        _c("fluent-icon", {
          staticClass: "file--icon",
          attrs: { icon: "location", size: "32" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "meta" }, [
      _c("h5", { staticClass: "text-block-title text-truncate" }, [
        _vm._v("\n      " + _vm._s(_vm.name) + "\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "link-wrap" }, [
        _c(
          "a",
          {
            staticClass: "download clear link button small",
            attrs: {
              rel: "noreferrer noopener nofollow",
              target: "_blank",
              href: _vm.mapUrl
            }
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("COMPONENTS.LOCATION_BUBBLE.SEE_ON_MAP")) +
                "\n      "
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }