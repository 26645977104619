var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.ADD.TITLE"),
          "header-content": _vm.useInstallationName(
            _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.FORM.DESC"),
            _vm.globalConfig.installationName
          )
        }
      }),
      _vm._v(" "),
      _c("webhook-form", {
        attrs: {
          "is-submitting": _vm.uiFlags.creatingItem,
          "submit-label": _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.FORM.ADD_SUBMIT")
        },
        on: { submit: _vm.onSubmit, cancel: _vm.onClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }