var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-container flex-dir-column medium-flex-dir-row" },
    [
      _vm.type === "agent"
        ? _c(
            "div",
            { staticClass: "small-12 medium-3 pull-right" },
            [
              _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("AGENT_REPORTS.FILTER_DROPDOWN_LABEL")) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  placeholder: _vm.multiselectLabel,
                  label: "name",
                  "track-by": "id",
                  options: _vm.filterItemsList,
                  "option-height": 24,
                  "show-labels": false
                },
                on: { input: _vm.changeFilterSelection },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "singleLabel",
                      fn: function(props) {
                        return [
                          _c(
                            "div",
                            { staticClass: "reports-option__wrap" },
                            [
                              _c("thumbnail", {
                                staticClass: "margin-right-small",
                                attrs: {
                                  src: props.option.thumbnail,
                                  status: props.option.availability_status,
                                  username: props.option.name,
                                  size: "22px"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "reports-option__desc" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "reports-option__title" },
                                    [_vm._v(_vm._s(props.option.name))]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "option",
                      fn: function(props) {
                        return [
                          _c(
                            "div",
                            { staticClass: "reports-option__wrap" },
                            [
                              _c("thumbnail", {
                                staticClass: "margin-right-small",
                                attrs: {
                                  src: props.option.thumbnail,
                                  status: props.option.availability_status,
                                  username: props.option.name,
                                  size: "22px"
                                }
                              }),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(props.option.name))])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3961691904
                ),
                model: {
                  value: _vm.currentSelectedFilter,
                  callback: function($$v) {
                    _vm.currentSelectedFilter = $$v
                  },
                  expression: "currentSelectedFilter"
                }
              })
            ],
            1
          )
        : _vm.type === "label"
        ? _c(
            "div",
            { staticClass: "small-12 medium-3 pull-right" },
            [
              _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("LABEL_REPORTS.FILTER_DROPDOWN_LABEL")) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  placeholder: _vm.multiselectLabel,
                  label: "title",
                  "track-by": "id",
                  options: _vm.filterItemsList,
                  "option-height": 24,
                  "show-labels": false
                },
                on: { input: _vm.changeFilterSelection },
                scopedSlots: _vm._u([
                  {
                    key: "singleLabel",
                    fn: function(props) {
                      return [
                        _c("div", { staticClass: "reports-option__wrap" }, [
                          _c("div", {
                            staticClass:
                              "reports-option__rounded--item margin-right-small",
                            style: { backgroundColor: props.option.color }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "reports-option__desc" }, [
                            _c(
                              "span",
                              { staticClass: "reports-option__title" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(props.option.title) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    }
                  },
                  {
                    key: "option",
                    fn: function(props) {
                      return [
                        _c("div", { staticClass: "reports-option__wrap" }, [
                          _c("div", {
                            staticClass:
                              "\n              reports-option__rounded--item\n              reports-option__item\n              reports-option__label--swatch\n            ",
                            style: { backgroundColor: props.option.color }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "reports-option__desc" }, [
                            _c(
                              "span",
                              { staticClass: "reports-option__title" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(props.option.title) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.currentSelectedFilter,
                  callback: function($$v) {
                    _vm.currentSelectedFilter = $$v
                  },
                  expression: "currentSelectedFilter"
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "small-12 medium-3 pull-right" },
            [
              _c(
                "p",
                [
                  _vm.type === "inbox"
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t("INBOX_REPORTS.FILTER_DROPDOWN_LABEL")
                            ) +
                            "\n      "
                        )
                      ]
                    : _vm.type === "team"
                    ? [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t("TEAM_REPORTS.FILTER_DROPDOWN_LABEL")
                            ) +
                            "\n      "
                        )
                      ]
                    : [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("FORMS.MULTISELECT.SELECT_ONE")) +
                            "\n      "
                        )
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  "track-by": "id",
                  label: "name",
                  placeholder: _vm.multiselectLabel,
                  "selected-label": "",
                  "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                  "deselect-label": "",
                  options: _vm.filterItemsList,
                  searchable: false,
                  "allow-empty": false
                },
                on: { input: _vm.changeFilterSelection },
                model: {
                  value: _vm.currentSelectedFilter,
                  callback: function($$v) {
                    _vm.currentSelectedFilter = $$v
                  },
                  expression: "currentSelectedFilter"
                }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "small-12 medium-3 pull-right margin-left-small" },
        [
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("REPORT.DURATION_FILTER_LABEL")) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("multiselect", {
            attrs: {
              "track-by": "name",
              label: "name",
              placeholder: _vm.$t("FORMS.MULTISELECT.SELECT_ONE"),
              "selected-label": "",
              "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
              "deselect-label": "",
              options: _vm.dateRange,
              searchable: false,
              "allow-empty": false
            },
            on: { select: _vm.changeDateSelection },
            model: {
              value: _vm.currentDateRangeSelection,
              callback: function($$v) {
                _vm.currentDateRangeSelection = $$v
              },
              expression: "currentDateRangeSelection"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isDateRangeSelected
        ? _c("woot-date-range-picker", {
            attrs: {
              "show-range": "",
              value: _vm.customDateRange,
              "confirm-text": _vm.$t("REPORT.CUSTOM_DATE_RANGE.CONFIRM"),
              placeholder: _vm.$t("REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER")
            },
            on: { change: _vm.onChange }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.notLast7Days
        ? _c(
            "div",
            { staticClass: "small-12 medium-3 pull-right margin-left-small" },
            [
              _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("REPORT.GROUP_BY_FILTER_DROPDOWN_LABEL")) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  "track-by": "id",
                  label: "groupBy",
                  placeholder: _vm.$t("REPORT.GROUP_BY_FILTER_DROPDOWN_LABEL"),
                  options: _vm.groupByFilterItemsList,
                  "allow-empty": false,
                  "show-labels": false
                },
                on: { input: _vm.changeGroupByFilterSelection },
                model: {
                  value: _vm.currentSelectedGroupByFilter,
                  callback: function($$v) {
                    _vm.currentSelectedGroupByFilter = $$v
                  },
                  expression: "currentSelectedGroupByFilter"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "small-12 medium-3 business-hours" }, [
        _c("span", { staticClass: "business-hours-text margin-right-small" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("REPORT.BUSINESS_HOURS")) + "\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "span",
          [
            _c("woot-switch", {
              model: {
                value: _vm.businessHoursSelected,
                callback: function($$v) {
                  _vm.businessHoursSelected = $$v
                },
                expression: "businessHoursSelected"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }