var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "code--container" },
    [
      _c(
        "button",
        {
          staticClass: "button small button--copy-code",
          on: { click: _vm.onCopy }
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("COMPONENTS.CODE.BUTTON_TEXT")) + "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _vm.script
        ? _c("highlightjs", { attrs: { language: _vm.lang, code: _vm.script } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }