var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications-link" },
    [
      _c(
        "woot-button",
        {
          class: { "is-active": _vm.isNotificationPanelActive },
          attrs: {
            "class-names": "notifications-link--button",
            variant: "clear",
            "color-scheme": "secondary"
          },
          on: { click: _vm.openNotificationPanel }
        },
        [
          _c("fluent-icon", { attrs: { icon: "alert" } }),
          _vm._v(" "),
          _vm.unreadCount
            ? _c("span", { staticClass: "badge warning" }, [
                _vm._v(_vm._s(_vm.unreadCount))
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }