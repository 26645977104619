var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card note-wrap" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "div",
          { staticClass: "meta" },
          [
            _c("thumbnail", {
              attrs: {
                title: _vm.noteAuthorName,
                src: _vm.noteAuthor.thumbnail,
                username: _vm.noteAuthorName,
                size: "20px"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "date-wrap" }, [
              _c("span", { staticClass: "fw-medium" }, [
                _vm._v(" " + _vm._s(_vm.noteAuthorName) + " ")
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.$t("NOTES.LIST.LABEL")) + " ")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "fw-medium" }, [
                _vm._v(" " + _vm._s(_vm.readableTime) + " ")
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "actions" },
          [
            _c("woot-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("NOTES.CONTENT_HEADER.DELETE"),
                  expression: "$t('NOTES.CONTENT_HEADER.DELETE')"
                }
              ],
              attrs: {
                variant: "smooth",
                size: "tiny",
                icon: "delete",
                "color-scheme": "secondary"
              },
              on: { click: _vm.toggleDeleteModal }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.showDeleteModal
          ? _c("woot-delete-modal", {
              attrs: {
                show: _vm.showDeleteModal,
                "on-close": _vm.closeDelete,
                "on-confirm": _vm.confirmDeletion,
                title: _vm.$t("DELETE_NOTE.CONFIRM.TITLE"),
                message: _vm.$t("DELETE_NOTE.CONFIRM.MESSAGE"),
                "confirm-text": _vm.$t("DELETE_NOTE.CONFIRM.YES"),
                "reject-text": _vm.$t("DELETE_NOTE.CONFIRM.NO")
              },
              on: {
                "update:show": function($event) {
                  _vm.showDeleteModal = $event
                }
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("p", {
      directives: [
        {
          name: "dompurify-html",
          rawName: "v-dompurify-html",
          value: _vm.formatMessage(_vm.note || ""),
          expression: "formatMessage(note || '')"
        }
      ],
      staticClass: "note__content"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }