var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-box" },
    [
      _c(
        "div",
        { staticClass: "mode-wrap button-group" },
        [
          _c(
            "woot-button",
            {
              staticClass: "button--reply",
              class: _vm.replyButtonClass,
              attrs: { variant: "clear" },
              on: { click: _vm.handleReplyClick }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CONVERSATION.REPLYBOX.REPLY")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-button",
            {
              staticClass: "button--note",
              class: _vm.noteButtonClass,
              attrs: { variant: "clear", "color-scheme": "warning" },
              on: { click: _vm.handleNoteClick }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CONVERSATION.REPLYBOX.PRIVATE_NOTE")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "action-wrap" }, [
        _vm.isMessageLengthReachingThreshold
          ? _c("div", { staticClass: "tabs-title" }, [
              _c("span", { class: _vm.charLengthClass }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.characterLengthWarning) + "\n      "
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.popoutReplyBox
        ? _c("woot-button", {
            attrs: {
              variant: "clear",
              icon: "dismiss",
              "color-scheme": "secondary",
              "class-names": "popout-button"
            },
            on: {
              click: function($event) {
                return _vm.$emit("click")
              }
            }
          })
        : _c("woot-button", {
            attrs: {
              variant: "clear",
              icon: "resize-large",
              "color-scheme": "secondary",
              "class-names": "popout-button"
            },
            on: {
              click: function($event) {
                return _vm.$emit("click")
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }