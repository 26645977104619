var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("campaigns-table", {
        attrs: {
          campaigns: _vm.campaigns,
          "show-empty-result": _vm.showEmptyResult,
          "is-loading": _vm.uiFlags.isFetching,
          "campaign-type": _vm.type
        },
        on: {
          "on-edit-click": _vm.openEditPopup,
          "on-delete-click": _vm.openDeletePopup
        }
      }),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showEditPopup, "on-close": _vm.hideEditPopup },
          on: {
            "update:show": function($event) {
              _vm.showEditPopup = $event
            }
          }
        },
        [
          _c("edit-campaign", {
            attrs: { "selected-campaign": _vm.selectedCampaign },
            on: { "on-close": _vm.hideEditPopup }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("CAMPAIGN.DELETE.CONFIRM.TITLE"),
          message: _vm.$t("CAMPAIGN.DELETE.CONFIRM.MESSAGE"),
          "confirm-text": _vm.$t("CAMPAIGN.DELETE.CONFIRM.YES"),
          "reject-text": _vm.$t("CAMPAIGN.DELETE.CONFIRM.NO")
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }