var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts-page row" },
    [
      _c(
        "div",
        { staticClass: "left-wrap", class: _vm.wrapClas },
        [
          _c("contacts-header", {
            attrs: {
              "search-query": _vm.searchQuery,
              "segments-id": _vm.segmentsId,
              "on-search-submit": _vm.onSearchSubmit,
              "this-selected-contact-id": "",
              "on-input-search": _vm.onInputSearch,
              "on-toggle-create": _vm.onToggleCreate,
              "on-toggle-import": _vm.onToggleImport,
              "on-toggle-filter": _vm.onToggleFilters,
              "header-title": _vm.pageTitle
            },
            on: {
              "on-toggle-save-filter": _vm.onToggleSaveFilters,
              "on-toggle-delete-filter": _vm.onToggleDeleteFilters
            }
          }),
          _vm._v(" "),
          _c("contacts-table", {
            attrs: {
              contacts: _vm.records,
              "show-search-empty-state": _vm.showEmptySearchResult,
              "is-loading": _vm.uiFlags.isFetching,
              "on-click-contact": _vm.openContactInfoPanel,
              "active-contact-id": _vm.selectedContactId
            },
            on: { "on-sort-change": _vm.onSortChange }
          }),
          _vm._v(" "),
          _c("table-footer", {
            attrs: {
              "current-page": Number(_vm.meta.currentPage),
              "total-count": _vm.meta.count,
              "page-size": 15
            },
            on: { "page-change": _vm.onPageChange }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showAddSegmentsModal
        ? _c("add-custom-views", {
            attrs: {
              "custom-views-query": _vm.segmentsQuery,
              "filter-type": _vm.filterType,
              "open-last-saved-item": _vm.openSavedItemInSegment
            },
            on: { close: _vm.onCloseAddSegmentsModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDeleteSegmentsModal
        ? _c("delete-custom-views", {
            attrs: {
              "show-delete-popup": _vm.showDeleteSegmentsModal,
              "active-custom-view": _vm.activeSegment,
              "custom-views-id": _vm.segmentsId,
              "active-filter-type": _vm.filterType,
              "open-last-item-after-delete":
                _vm.openLastItemAfterDeleteInSegment
            },
            on: {
              "update:showDeletePopup": function($event) {
                _vm.showDeleteSegmentsModal = $event
              },
              "update:show-delete-popup": function($event) {
                _vm.showDeleteSegmentsModal = $event
              },
              close: _vm.onCloseDeleteSegmentsModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showContactViewPane
        ? _c("contact-info-panel", {
            attrs: {
              contact: _vm.selectedContact,
              "on-close": _vm.closeContactInfoPanel
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("create-contact", {
        attrs: { show: _vm.showCreateModal },
        on: { cancel: _vm.onToggleCreate }
      }),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showImportModal, "on-close": _vm.onToggleImport },
          on: {
            "update:show": function($event) {
              _vm.showImportModal = $event
            }
          }
        },
        [
          _vm.showImportModal
            ? _c("import-contacts", {
                attrs: { "on-close": _vm.onToggleImport }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showFiltersModal,
            "on-close": _vm.onToggleFilters,
            size: "medium"
          },
          on: {
            "update:show": function($event) {
              _vm.showFiltersModal = $event
            }
          }
        },
        [
          _vm.showFiltersModal
            ? _c("contacts-advanced-filters", {
                attrs: {
                  "on-close": _vm.onToggleFilters,
                  "initial-filter-types": _vm.contactFilterItems
                },
                on: {
                  applyFilter: _vm.onApplyFilter,
                  clearFilters: _vm.clearFilters
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }