var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row app-wrapper" },
    [
      _c("sidebar", {
        attrs: {
          route: _vm.currentRoute,
          "show-secondary-sidebar": _vm.isSidebarOpen
        },
        on: {
          "open-notification-panel": _vm.openNotificationPanel,
          "toggle-account-modal": _vm.toggleAccountModal,
          "open-key-shortcut-modal": _vm.toggleKeyShortcutModal,
          "close-key-shortcut-modal": _vm.closeKeyShortcutModal,
          "show-add-label-popup": _vm.showAddLabelPopup
        }
      }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "app-content columns" },
        [
          _c("router-view"),
          _vm._v(" "),
          _c("command-bar"),
          _vm._v(" "),
          _c("account-selector", {
            attrs: { "show-account-modal": _vm.showAccountModal },
            on: {
              "close-account-modal": _vm.toggleAccountModal,
              "show-create-account-modal": _vm.openCreateAccountModal
            }
          }),
          _vm._v(" "),
          _c("add-account-modal", {
            attrs: { show: _vm.showCreateAccountModal },
            on: { "close-account-create-modal": _vm.closeCreateAccountModal }
          }),
          _vm._v(" "),
          _c("woot-key-shortcut-modal", {
            attrs: { show: _vm.showShortcutModal },
            on: {
              "update:show": function($event) {
                _vm.showShortcutModal = $event
              },
              close: _vm.closeKeyShortcutModal,
              clickaway: _vm.closeKeyShortcutModal
            }
          }),
          _vm._v(" "),
          _vm.isNotificationPanel
            ? _c("notification-panel", {
                on: { close: _vm.closeNotificationPanel }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "woot-modal",
            {
              attrs: {
                show: _vm.showAddLabelModal,
                "on-close": _vm.hideAddLabelPopup
              },
              on: {
                "update:show": function($event) {
                  _vm.showAddLabelModal = $event
                }
              }
            },
            [_c("add-label-modal", { on: { close: _vm.hideAddLabelPopup } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }