var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-container actions--container" },
    [
      !_vm.currentChat.muted
        ? _c("woot-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.$t("CONTACT_PANEL.MUTE_CONTACT"),
                expression: "$t('CONTACT_PANEL.MUTE_CONTACT')"
              }
            ],
            attrs: {
              variant: "clear",
              "color-scheme": "secondary",
              icon: "speaker-mute"
            },
            on: { click: _vm.mute }
          })
        : _c("woot-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.left",
                value: _vm.$t("CONTACT_PANEL.UNMUTE_CONTACT"),
                expression: "$t('CONTACT_PANEL.UNMUTE_CONTACT')",
                modifiers: { left: true }
              }
            ],
            attrs: {
              variant: "clear",
              "color-scheme": "secondary",
              icon: "speaker-1"
            },
            on: { click: _vm.unmute }
          }),
      _vm._v(" "),
      _c("woot-button", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.$t("CONTACT_PANEL.SEND_TRANSCRIPT"),
            expression: "$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
          }
        ],
        attrs: { variant: "clear", "color-scheme": "secondary", icon: "share" },
        on: { click: _vm.toggleEmailActionsModal }
      }),
      _vm._v(" "),
      _c("resolve-action", {
        attrs: {
          "conversation-id": _vm.currentChat.id,
          status: _vm.currentChat.status
        }
      }),
      _vm._v(" "),
      _vm.showEmailActionsModal
        ? _c("email-transcript-modal", {
            attrs: {
              show: _vm.showEmailActionsModal,
              "current-chat": _vm.currentChat
            },
            on: { cancel: _vm.toggleEmailActionsModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }