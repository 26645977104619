var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.onClose,
          expression: "onClose"
        }
      ],
      staticClass: "actions-container"
    },
    [
      _c("div", { staticClass: "triangle", style: _vm.cssVars }, [
        _c(
          "svg",
          { attrs: { height: "12", viewBox: "0 0 24 12", width: "24" } },
          [
            _c("path", {
              attrs: {
                d: "M20 12l-8-8-12 12",
                fill: "var(--white)",
                "fill-rule": "evenodd",
                stroke: "var(--s-50)",
                "stroke-width": "1px"
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "header flex-between" },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("BULK_ACTION.UPDATE.CHANGE_STATUS")))
          ]),
          _vm._v(" "),
          _c("woot-button", {
            attrs: {
              size: "tiny",
              variant: "clear",
              "color-scheme": "secondary",
              icon: "dismiss"
            },
            on: { click: _vm.onClose }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "woot-dropdown-menu",
            [
              _vm._l(_vm.actions, function(action) {
                return [
                  _vm.showAction(action.key)
                    ? _c(
                        "woot-dropdown-item",
                        { key: action.key },
                        [
                          _c(
                            "woot-button",
                            {
                              attrs: {
                                variant: "clear",
                                "color-scheme": "secondary",
                                size: "small",
                                icon: action.icon
                              },
                              on: {
                                click: function($event) {
                                  return _vm.updateConversations(action.key)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.actionLabel(action.key)) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }