var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "integration--image" }, [
        _c("img", {
          attrs: {
            src: "/dashboard/images/integrations/" + _vm.integrationLogo
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "column" }, [
        _c("h3", { staticClass: "integration--title" }, [
          _vm._v("\n      " + _vm._s(_vm.integrationName) + "\n    ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "integration--description" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.useInstallationName(
                  _vm.integrationDescription,
                  _vm.globalConfig.installationName
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "small-2 column button-wrap" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: _vm.frontendURL(
                  "accounts/" +
                    _vm.accountId +
                    "/settings/integrations/" +
                    _vm.integrationId
                )
              }
            },
            [
              _vm.integrationEnabled
                ? _c("div", [
                    _vm.integrationAction === "disconnect"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.openDeletePopup()
                                }
                              }
                            },
                            [
                              _c("woot-submit-button", {
                                attrs: {
                                  "button-text": _vm.$t(
                                    "INTEGRATION_SETTINGS.WEBHOOK.DELETE.BUTTON_TEXT"
                                  ),
                                  "icon-class": "dismiss-circle",
                                  "button-class": "nice alert"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _c("div", [
                          _c("button", { staticClass: "button nice" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "INTEGRATION_SETTINGS.WEBHOOK.CONFIGURE"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        ])
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          !_vm.integrationEnabled
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "button success nice",
                    attrs: { href: _vm.integrationAction }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("INTEGRATION_SETTINGS.CONNECT.BUTTON_TEXT")
                        ) +
                        "\n      "
                    )
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.TITLE"),
          message: _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.MESSAGE"
          ),
          "confirm-text": _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.YES"
          ),
          "reject-text": _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.NO"
          )
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }