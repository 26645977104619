var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box" }, [
    _c("div", { staticClass: "small-12 columns integrations-wrap" }, [
      _c("div", { staticClass: "small-12 columns integration" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "integration--image" }, [
            _c("img", {
              attrs: {
                src: "/dashboard/images/integrations/" + _vm.integration.logo
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column" }, [
            _c("h3", { staticClass: "integration--title" }, [
              _vm._v(
                "\n            " + _vm._s(_vm.integration.name) + "\n          "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "integration--description" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.integration.description) +
                  "\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "small-2 column button-wrap" }, [
            _vm.hasConnectedHooks
              ? _c("div", [
                  _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$emit("delete", _vm.integration.hooks[0])
                        }
                      }
                    },
                    [
                      _c("woot-button", { staticClass: "nice alert" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("INTEGRATION_APPS.DISCONNECT.BUTTON_TEXT")
                            ) +
                            "\n              "
                        )
                      ])
                    ],
                    1
                  )
                ])
              : _c(
                  "div",
                  [
                    _c(
                      "woot-button",
                      {
                        staticClass: "button nice",
                        on: {
                          click: function($event) {
                            return _vm.$emit("add")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("INTEGRATION_APPS.CONNECT.BUTTON_TEXT")
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  ],
                  1
                )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }