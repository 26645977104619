var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDeletePopup
        ? _c("woot-delete-modal", {
            attrs: {
              show: _vm.showDeletePopup,
              "on-close": _vm.closeDeletePopup,
              "on-confirm": _vm.deleteSavedCustomViews,
              title: _vm.$t("FILTER.CUSTOM_VIEWS.DELETE.MODAL.CONFIRM.TITLE"),
              message: _vm.$t(
                "FILTER.CUSTOM_VIEWS.DELETE.MODAL.CONFIRM.MESSAGE"
              ),
              "message-value": _vm.deleteMessage,
              "confirm-text": _vm.deleteConfirmText,
              "reject-text": _vm.deleteRejectText
            },
            on: {
              "update:show": function($event) {
                _vm.showDeletePopup = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }