var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "primary--sidebar" },
    [
      _c("logo", {
        attrs: {
          source: _vm.logoSource,
          name: _vm.installationName,
          "account-id": _vm.accountId
        }
      }),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "menu vertical" },
        _vm._l(_vm.menuItems, function(menuItem) {
          return _c("primary-nav-item", {
            key: menuItem.toState,
            attrs: {
              icon: menuItem.icon,
              name: menuItem.label,
              to: menuItem.toState,
              "is-child-menu-active": menuItem.key === _vm.activeMenuItem
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu vertical user-menu" },
        [
          !_vm.isACustomBrandedInstance
            ? _c("primary-nav-item", {
                attrs: {
                  icon: "book-open-globe",
                  name: "DOCS",
                  "open-in-new-page": true,
                  to: _vm.helpDocsURL
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("notification-bell", {
            on: { "open-notification-panel": _vm.openNotificationPanel }
          }),
          _vm._v(" "),
          _c("agent-details", { on: { "toggle-menu": _vm.toggleOptions } }),
          _vm._v(" "),
          _c("options-menu", {
            attrs: { show: _vm.showOptionsMenu },
            on: {
              "toggle-accounts": _vm.toggleAccountModal,
              "show-support-chat-window": _vm.toggleSupportChatWindow,
              "key-shortcut-modal": function($event) {
                return _vm.$emit("key-shortcut-modal")
              },
              close: _vm.toggleOptions
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }