var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showHead
    ? _c(
        "div",
        {
          staticClass: "message__mail-head",
          class: { "is-incoming": _vm.isIncoming }
        },
        [
          _vm.fromMail
            ? _c("div", { staticClass: "meta-wrap" }, [
                _c("span", { staticClass: "message__content--type" }, [
                  _vm._v(_vm._s(_vm.$t("EMAIL_HEADER.FROM")) + ":")
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.fromMail))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.toMails
            ? _c("div", { staticClass: "meta-wrap" }, [
                _c("span", { staticClass: "message__content--type" }, [
                  _vm._v(_vm._s(_vm.$t("EMAIL_HEADER.TO")) + ":")
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.toMails))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.ccMails
            ? _c("div", { staticClass: "meta-wrap" }, [
                _c("span", { staticClass: "message__content--type" }, [
                  _vm._v(_vm._s(_vm.$t("EMAIL_HEADER.CC")) + ":")
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.ccMails))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.bccMails
            ? _c("div", { staticClass: "meta-wrap" }, [
                _c("span", { staticClass: "message__content--type" }, [
                  _vm._v(_vm._s(_vm.$t("EMAIL_HEADER.BCC")) + ":")
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.bccMails))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.subject
            ? _c("div", { staticClass: "meta-wrap" }, [
                _c("span", { staticClass: "message__content--type" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("EMAIL_HEADER.SUBJECT")) +
                      ":\n    "
                  )
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.subject))])
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }