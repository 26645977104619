var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ninja-keys", {
    ref: "ninjakeys",
    attrs: {
      "no-auto-load-md-icons": true,
      hideBreadcrumbs: "",
      placeholder: _vm.placeholder
    },
    on: { selected: _vm.setCommandbarData }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }