var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preview-card--wrap", class: { activecard: _vm.active } },
    [
      _c(
        "div",
        { staticClass: "header--wrap", class: { active: _vm.active } },
        [
          _c("div", { staticClass: "heading-wrap text-block-title" }, [
            _vm._v(_vm._s(_vm.heading))
          ]),
          _vm._v(" "),
          _vm.active
            ? _c("fluent-icon", {
                staticClass: "checkmark",
                attrs: { icon: "checkmark-circle", type: "solid", size: "24" }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content-wrap" }, [
        _vm._v("\n    " + _vm._s(_vm.content) + "\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "image-wrap" }, [
        _c("img", {
          staticClass: "image",
          class: { activeimage: _vm.active },
          attrs: { src: _vm.src }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }