var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conv-details--item", class: { compact: _vm.compact } },
    [
      _c(
        "h4",
        { staticClass: "conv-details--item__label text-block-title" },
        [
          _c("span", { staticClass: "item__title" }, [
            _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
          ]),
          _vm._v(" "),
          _vm._t("button")
        ],
        2
      ),
      _vm._v(" "),
      _vm.value
        ? _c(
            "div",
            { staticClass: "conv-details--item__value" },
            [
              _vm._t("default", [
                _vm._v("\n      " + _vm._s(_vm.value) + "\n    ")
              ])
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }