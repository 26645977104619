var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: {
        "tabs-title": true,
        "is-active": _vm.active
      }
    },
    [
      _c("a", { on: { click: _vm.onTabClick } }, [
        _vm._v("\n    " + _vm._s(_vm.name) + "\n    "),
        _vm.showBadge
          ? _c("span", { staticClass: "badge" }, [
              _vm._v("\n      " + _vm._s(_vm.getItemCount) + "\n    ")
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }