var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldRenderMessage
    ? _c("li", { class: _vm.alignBubble }, [
        _c(
          "div",
          { class: _vm.wrapClass },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-start",
                    value: _vm.messageToolTip,
                    expression: "messageToolTip",
                    modifiers: { "top-start": true }
                  }
                ],
                class: _vm.bubbleClass
              },
              [
                _c("bubble-mail-head", {
                  attrs: {
                    "email-attributes": _vm.contentAttributes.email,
                    cc: _vm.emailHeadAttributes.cc,
                    bcc: _vm.emailHeadAttributes.bcc,
                    "is-incoming": _vm.isIncoming
                  }
                }),
                _vm._v(" "),
                _vm.data.content
                  ? _c("bubble-text", {
                      attrs: {
                        message: _vm.message,
                        "is-email": _vm.isEmailContentType,
                        "display-quoted-button": _vm.displayQuotedButton
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("bubble-integration", {
                  attrs: {
                    "message-id": _vm.data.id,
                    "content-attributes": _vm.contentAttributes,
                    "inbox-id": _vm.data.inbox_id
                  }
                }),
                _vm._v(" "),
                _vm.isPending && _vm.hasAttachments
                  ? _c(
                      "span",
                      { staticClass: "chat-bubble has-attachment agent" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t("CONVERSATION.UPLOADING_ATTACHMENTS")
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isPending && _vm.hasAttachments
                  ? _c(
                      "div",
                      _vm._l(_vm.data.attachments, function(attachment) {
                        return _c(
                          "div",
                          { key: attachment.id },
                          [
                            attachment.file_type === "image" &&
                            !_vm.hasImageError
                              ? _c("bubble-image", {
                                  attrs: { url: attachment.data_url },
                                  on: { error: _vm.onImageLoadError }
                                })
                              : attachment.file_type === "audio"
                              ? _c("audio", { attrs: { controls: "" } }, [
                                  _c("source", {
                                    attrs: { src: attachment.data_url }
                                  })
                                ])
                              : attachment.file_type === "video"
                              ? _c("bubble-video", {
                                  attrs: { url: attachment.data_url }
                                })
                              : attachment.file_type === "location"
                              ? _c("bubble-location", {
                                  attrs: {
                                    latitude: attachment.coordinates_lat,
                                    longitude: attachment.coordinates_long,
                                    name: attachment.fallback_title
                                  }
                                })
                              : attachment.file_type === "contact"
                              ? _c("bubble-contact", {
                                  attrs: {
                                    name: _vm.data.content,
                                    "phone-number": attachment.fallback_title
                                  }
                                })
                              : _vm.hasImageError && _vm.hasInstagramStory
                              ? _c("instagram-image-error-placeholder")
                              : _c("bubble-file", {
                                  attrs: { url: attachment.data_url }
                                })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("bubble-actions", {
                  attrs: {
                    id: _vm.data.id,
                    sender: _vm.data.sender,
                    "story-sender": _vm.storySender,
                    "story-id": _vm.storyId,
                    "is-a-tweet": _vm.isATweet,
                    "is-a-whatsapp-channel": _vm.isAWhatsAppChannel,
                    "has-instagram-story": _vm.hasInstagramStory,
                    "is-email": _vm.isEmailContentType,
                    "is-private": _vm.data.private,
                    "message-type": _vm.data.message_type,
                    "message-status": _vm.status,
                    "source-id": _vm.data.source_id,
                    "inbox-id": _vm.data.inbox_id,
                    "created-at": _vm.createdAt
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.showTranslateModal
              ? _c(
                  "woot-modal",
                  {
                    attrs: {
                      "modal-type": "right-aligned",
                      show: "",
                      "on-close": _vm.onCloseTranslateModal
                    }
                  },
                  [
                    _c("div", { staticClass: "column content" }, [
                      _c("p", [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.$t("TRANSLATE_MODAL.ORIGINAL_CONTENT"))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value: _vm.data.content,
                            expression: "data.content"
                          }
                        ]
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _vm.translationsAvailable
                        ? _c(
                            "div",
                            [
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "TRANSLATE_MODAL.TRANSLATED_CONTENT"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.translations, function(
                                translation,
                                language
                              ) {
                                return _c("div", { key: language }, [
                                  _c("p", [
                                    _c("strong", [
                                      _vm._v(_vm._s(language) + ":")
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("p", {
                                    directives: [
                                      {
                                        name: "dompurify-html",
                                        rawName: "v-dompurify-html",
                                        value: translation,
                                        expression: "translation"
                                      }
                                    ]
                                  }),
                                  _vm._v(" "),
                                  _c("br")
                                ])
                              })
                            ],
                            2
                          )
                        : _c("p", [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "TRANSLATE_MODAL.NO_TRANSLATIONS_AVAILABLE"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isPending
              ? _c("spinner", { attrs: { size: "tiny" } })
              : _vm._e(),
            _vm._v(" "),
            _vm.showAvatar
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.left",
                        value: _vm.tooltipForSender,
                        expression: "tooltipForSender",
                        modifiers: { left: true }
                      }
                    ],
                    staticClass: "sender--info"
                  },
                  [
                    _c("woot-thumbnail", {
                      attrs: {
                        src: _vm.sender.thumbnail,
                        username: _vm.senderNameForAvatar,
                        size: "16px"
                      }
                    }),
                    _vm._v(" "),
                    _vm.isATweet && _vm.isIncoming
                      ? _c(
                          "a",
                          {
                            staticClass: "sender--available-name",
                            attrs: {
                              href: _vm.twitterProfileLink,
                              target: "_blank",
                              rel: "noopener noreferrer nofollow"
                            }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.sender.name) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isFailed
              ? _c(
                  "div",
                  { staticClass: "message-failed--alert" },
                  [
                    _c("woot-button", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top-end",
                          value: _vm.$t("CONVERSATION.TRY_AGAIN"),
                          expression: "$t('CONVERSATION.TRY_AGAIN')",
                          modifiers: { "top-end": true }
                        }
                      ],
                      attrs: {
                        size: "tiny",
                        "color-scheme": "alert",
                        variant: "clear",
                        icon: "arrow-clockwise"
                      },
                      on: { click: _vm.retrySendMessage }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.shouldShowContextMenu
          ? _c(
              "div",
              { staticClass: "context-menu-wrap" },
              [
                _vm.isBubble && !_vm.isMessageDeleted
                  ? _c("context-menu", {
                      attrs: {
                        "is-open": _vm.showContextMenu,
                        "show-copy": _vm.hasText,
                        "show-delete": _vm.hasText || _vm.hasAttachments,
                        "show-canned-response-option":
                          _vm.isOutgoing && _vm.hasText,
                        "menu-position": _vm.contextMenuPosition,
                        "message-content": _vm.data.content
                      },
                      on: {
                        toggle: _vm.handleContextMenuClick,
                        delete: _vm.handleDelete,
                        translate: _vm.handleTranslate
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }