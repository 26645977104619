var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.top",
          value: {
            content: _vm.tooltipText,
            delay: { show: 1500, hide: 0 },
            hideOnClick: true
          },
          expression:
            "{\n    content: tooltipText,\n    delay: { show: 1500, hide: 0 },\n    hideOnClick: true,\n  }",
          modifiers: { top: true }
        }
      ],
      staticClass: "time-ago"
    },
    [
      _c("span", [
        _vm._v(_vm._s(_vm.createdAtTime + " • " + _vm.lastActivityTime))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }