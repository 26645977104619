var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overlapping-thumbnails" },
    [
      _vm._l(_vm.usersList, function(user) {
        return _c("thumbnail", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: user.name,
              expression: "user.name"
            }
          ],
          key: user.id,
          class: "overlapping-thumbnail gap-" + _vm.gap,
          attrs: {
            title: user.name,
            src: user.thumbnail,
            username: user.name,
            "has-border": true,
            size: _vm.size
          }
        })
      }),
      _vm._v(" "),
      _vm.showMoreThumbnailsCount
        ? _c("span", { staticClass: "thumbnail-more-text" }, [
            _vm._v("\n    " + _vm._s(_vm.moreThumbnailsText) + "\n  ")
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }