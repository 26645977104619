var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-placeholder" },
    [
      _c("fluent-icon", { attrs: { icon: "document-error", size: "32" } }),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          _vm._s(_vm.$t("COMPONENTS.FILE_BUBBLE.INSTAGRAM_STORY_UNAVAILABLE"))
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }