var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-result", on: { click: _vm.onClick } },
    [
      _c("div", { staticClass: "result-header" }, [
        _c(
          "div",
          { staticClass: "conversation--block" },
          [
            _c("fluent-icon", {
              staticClass: "icon--conversation-search-item",
              attrs: { icon: "chat" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "conversation" }, [
              _c(
                "div",
                { staticClass: "user-wrap" },
                [
                  _c("div", { staticClass: "name-wrap" }, [
                    _c("span", { staticClass: "sub-block-title" }, [
                      _vm._v(_vm._s(_vm.userName))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("woot-label", {
                    attrs: {
                      title: _vm.conversationsId,
                      small: true,
                      "color-scheme": "secondary"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "inbox-name" }, [
                _vm._v(_vm._s(_vm.inboxName))
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("span", { staticClass: "timestamp" }, [
          _vm._v(_vm._s(_vm.readableTime) + " ")
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.messages, function(message) {
        return _c("search-message-item", {
          key: message.created_at,
          attrs: {
            "user-name": message.sender_name,
            timestamp: message.created_at,
            "message-type": message.message_type,
            content: message.content,
            "search-term": _vm.searchTerm
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }