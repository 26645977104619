var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "small-12 columns integrations-wrap" }, [
        _c("div", { staticClass: "row integrations" }, [
          _vm.integrationLoaded
            ? _c(
                "div",
                { staticClass: "small-12 columns integration" },
                [
                  _c("integration", {
                    attrs: {
                      "integration-id": _vm.integration.id,
                      "integration-logo": _vm.integration.logo,
                      "integration-name": _vm.integration.name,
                      "integration-description": _vm.integration.description,
                      "integration-enabled": _vm.integration.enabled,
                      "integration-action": _vm.integrationAction()
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.integration.enabled
            ? _c(
                "div",
                { staticClass: "small-12 columns integration" },
                [_c("IntegrationHelpText")],
                1
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }