import { render, staticRenderFns } from "./CreateContact.vue?vue&type=template&id=27c1cbc8&"
import script from "./CreateContact.vue?vue&type=script&lang=js&"
export * from "./CreateContact.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27c1cbc8')) {
      api.createRecord('27c1cbc8', component.options)
    } else {
      api.reload('27c1cbc8', component.options)
    }
    module.hot.accept("./CreateContact.vue?vue&type=template&id=27c1cbc8&", function () {
      api.rerender('27c1cbc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/conversation/contact/CreateContact.vue"
export default component.exports