var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose, size: "modal-big" },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("WHATSAPP_TEMPLATES.MODAL.TITLE"),
          "header-content": _vm.modalHeaderContent
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row modal-content" },
        [
          !_vm.selectedWaTemplate
            ? _c("templates-picker", {
                attrs: { "inbox-id": _vm.inboxId },
                on: { onSelect: _vm.pickTemplate }
              })
            : _c("template-parser", {
                attrs: { template: _vm.selectedWaTemplate },
                on: {
                  resetTemplate: _vm.onResetTemplate,
                  sendMessage: _vm.onSendMessage
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }