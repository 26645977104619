var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c(
            "woot-modal-header",
            { attrs: { "header-title": _vm.$t("IMPORT_CONTACTS.TITLE") } },
            [
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("IMPORT_CONTACTS.DESC")) +
                    "\n        "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.csvUrl,
                      download: "import-contacts-sample"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("IMPORT_CONTACTS.DOWNLOAD_LABEL")))]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row modal-content" }, [
            _c("div", { staticClass: "medium-12 columns" }, [
              _c("label", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("IMPORT_CONTACTS.FORM.LABEL")))
                ]),
                _vm._v(" "),
                _c("input", {
                  ref: "file",
                  attrs: { id: "file", type: "file", accept: "text/csv" },
                  on: { change: _vm.handleFileUpload }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "div",
                { staticClass: "medium-12 columns" },
                [
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        disabled: _vm.uiFlags.isCreating || !_vm.file,
                        loading: _vm.uiFlags.isCreating
                      },
                      on: { click: _vm.uploadFile }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("IMPORT_CONTACTS.FORM.SUBMIT")) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button clear",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onClose($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("IMPORT_CONTACTS.FORM.CANCEL")) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }