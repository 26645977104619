var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isFooterVisible
    ? _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "left-aligned-wrap" }, [
          _c("div", { staticClass: "page-meta" }, [
            _c("strong", [_vm._v(_vm._s(_vm.firstIndex))]),
            _vm._v("\n      - "),
            _c("strong", [_vm._v(_vm._s(_vm.lastIndex))]),
            _vm._v(" of\n      "),
            _c("strong", [_vm._v(_vm._s(_vm.totalCount))]),
            _vm._v(" items\n    ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right-aligned-wrap" }, [
          _vm.totalCount
            ? _c(
                "div",
                { staticClass: "primary button-group pagination-button-group" },
                [
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        size: "small",
                        variant: "smooth",
                        "color-scheme": "secondary",
                        "class-names": "goto-first",
                        "is-disabled": _vm.hasFirstPage
                      },
                      on: { click: _vm.onFirstPage }
                    },
                    [
                      _c("fluent-icon", {
                        attrs: { icon: "chevron-left", size: "18" }
                      }),
                      _vm._v(" "),
                      _c("fluent-icon", {
                        staticClass: "margin-left-minus-slab",
                        attrs: { icon: "chevron-left", size: "18" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        size: "small",
                        variant: "smooth",
                        "color-scheme": "secondary",
                        "is-disabled": _vm.hasPrevPage
                      },
                      on: { click: _vm.onPrevPage }
                    },
                    [
                      _c("fluent-icon", {
                        attrs: { icon: "chevron-left", size: "18" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        size: "small",
                        variant: "smooth",
                        "color-scheme": "secondary"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.currentPage) + "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        size: "small",
                        variant: "smooth",
                        "color-scheme": "secondary",
                        "is-disabled": _vm.hasNextPage
                      },
                      on: { click: _vm.onNextPage }
                    },
                    [
                      _c("fluent-icon", {
                        attrs: { icon: "chevron-right", size: "18" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        size: "small",
                        variant: "smooth",
                        "color-scheme": "secondary",
                        "class-names": "goto-last",
                        "is-disabled": _vm.hasLastPage
                      },
                      on: { click: _vm.onLastPage }
                    },
                    [
                      _c("fluent-icon", {
                        attrs: { icon: "chevron-right", size: "18" }
                      }),
                      _vm._v(" "),
                      _c("fluent-icon", {
                        staticClass: "margin-left-minus-slab",
                        attrs: { icon: "chevron-right", size: "18" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }