var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c(
        "router-link",
        {
          staticClass: "button success button--fixed-right-top",
          attrs: { to: _vm.addAccountScoping("settings/macros/new") }
        },
        [
          _c("fluent-icon", { attrs: { icon: "add-circle" } }),
          _vm._v(" "),
          _c("span", { staticClass: "button__content" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("MACROS.HEADER_BTN_TXT")) + "\n    "
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "small-8 columns with-right-space" },
          [
            !_vm.uiFlags.isFetching && !_vm.records.length
              ? _c("div", { staticClass: "macros__empty-state" }, [
                  _c("p", { staticClass: "no-items-error-message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("MACROS.LIST.404")) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.uiFlags.isFetching
              ? _c("woot-loading-state", {
                  attrs: { message: _vm.$t("MACROS.LOADING") }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.uiFlags.isFetching && _vm.records.length
              ? _c("table", { staticClass: "woot-table" }, [
                  _c(
                    "thead",
                    _vm._l(_vm.$t("MACROS.LIST.TABLE_HEADER"), function(
                      thHeader
                    ) {
                      return _c("th", { key: thHeader }, [
                        _vm._v(
                          "\n            " + _vm._s(thHeader) + "\n          "
                        )
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.records, function(macro, index) {
                      return _c("macros-table-row", {
                        key: index,
                        attrs: { macro: macro },
                        on: {
                          delete: function($event) {
                            return _vm.openDeletePopup(macro, index)
                          }
                        }
                      })
                    }),
                    1
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "small-4 columns" }, [
          _c("span", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.$t("MACROS.SIDEBAR_TXT"),
                expression: "$t('MACROS.SIDEBAR_TXT')"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("LABEL_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.$t("MACROS.DELETE.CONFIRM.MESSAGE"),
          "message-value": _vm.deleteMessage,
          "confirm-text": _vm.$t("MACROS.DELETE.CONFIRM.YES"),
          "reject-text": _vm.$t("MACROS.DELETE.CONFIRM.NO")
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }