var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-body columns content-box small-9" }, [
    _c("div", { staticClass: "login-init full-height text-center" }, [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.requestAuthorization($event)
            }
          }
        },
        [
          _c("woot-submit-button", {
            attrs: {
              icon: "brand-twitter",
              "button-text": "Sign in with Twitter",
              type: "submit",
              loading: _vm.isRequestingAuthorization
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.$t("INBOX_MGMT.ADD.TWITTER.HELP")))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }