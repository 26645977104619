var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", {
    staticClass: "dropdown-menu--divider",
    attrs: { tabindex: null, "aria-disabled": true }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }