var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.richtext
        ? _c(
            "label",
            { staticClass: "greetings--richtext" },
            [
              _c("woot-message-editor", {
                staticClass: "input",
                attrs: {
                  "is-format-mode": true,
                  placeholder: _vm.placeholder,
                  "min-height": 4
                },
                on: { input: _vm.handleInput },
                model: {
                  value: _vm.greetingsMessage,
                  callback: function($$v) {
                    _vm.greetingsMessage = $$v
                  },
                  expression: "greetingsMessage"
                }
              })
            ],
            1
          )
        : _c("resizable-text-area", {
            staticClass: "medium-9 greetings--textarea",
            attrs: {
              rows: "4",
              type: "text",
              label: _vm.label,
              placeholder: _vm.placeholder
            },
            on: { input: _vm.handleInput },
            model: {
              value: _vm.greetingsMessage,
              callback: function($$v) {
                _vm.greetingsMessage = $$v
              },
              expression: "greetingsMessage"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }