var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "row",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.createChannel()
        }
      }
    },
    [
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("label", { class: { error: _vm.$v.inboxName.$error } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL")) +
              "\n      "
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.inboxName,
                expression: "inboxName",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER"
              )
            },
            domProps: { value: _vm.inboxName },
            on: {
              blur: [
                _vm.$v.inboxName.$touch,
                function($event) {
                  return _vm.$forceUpdate()
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.inboxName = $event.target.value.trim()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.inboxName.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR")) +
                    "\n      "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("label", { class: { error: _vm.$v.phoneNumber.$error } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL")) +
              "\n      "
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.phoneNumber,
                expression: "phoneNumber",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER"
              )
            },
            domProps: { value: _vm.phoneNumber },
            on: {
              blur: [
                _vm.$v.phoneNumber.$touch,
                function($event) {
                  return _vm.$forceUpdate()
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phoneNumber = $event.target.value.trim()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.phoneNumber.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR")
                    ) +
                    "\n      "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("label", { class: { error: _vm.$v.phoneNumberId.$error } }, [
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t("INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.LABEL")
                ) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.phoneNumberId,
                expression: "phoneNumberId",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.PLACEHOLDER"
              )
            },
            domProps: { value: _vm.phoneNumberId },
            on: {
              blur: [
                _vm.$v.phoneNumberId.$touch,
                function($event) {
                  return _vm.$forceUpdate()
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phoneNumberId = $event.target.value.trim()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.phoneNumberId.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.ERROR")
                    ) +
                    "\n      "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("label", { class: { error: _vm.$v.businessAccountId.$error } }, [
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t("INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.LABEL")
                ) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.businessAccountId,
                expression: "businessAccountId",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.PLACEHOLDER"
              )
            },
            domProps: { value: _vm.businessAccountId },
            on: {
              blur: [
                _vm.$v.businessAccountId.$touch,
                function($event) {
                  return _vm.$forceUpdate()
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.businessAccountId = $event.target.value.trim()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.businessAccountId.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.ERROR"
                      )
                    ) +
                    "\n      "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("label", { class: { error: _vm.$v.apiKey.$error } }, [
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("INBOX_MGMT.ADD.WHATSAPP.API_KEY.LABEL")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.apiKey,
                expression: "apiKey",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t("INBOX_MGMT.ADD.WHATSAPP.API_KEY.PLACEHOLDER")
            },
            domProps: { value: _vm.apiKey },
            on: {
              blur: [
                _vm.$v.apiKey.$touch,
                function($event) {
                  return _vm.$forceUpdate()
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.apiKey = $event.target.value.trim()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.apiKey.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("INBOX_MGMT.ADD.WHATSAPP.API_KEY.ERROR")) +
                    "\n      "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medium-12 columns" },
        [
          _c("woot-submit-button", {
            attrs: {
              loading: _vm.uiFlags.isCreating,
              "button-text": _vm.$t("INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON")
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }