var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row table-wrap" },
    [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "woot-tabs",
            {
              attrs: { index: _vm.selectedTabIndex },
              on: { change: _vm.onClickTabChange }
            },
            _vm._l(_vm.tabs, function(tab) {
              return _c("woot-tabs-item", {
                key: tab.key,
                attrs: { name: tab.name, "show-badge": false }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "columns with-right-space" },
            [
              !_vm.uiFlags.isFetching && !_vm.attributes.length
                ? _c("p", { staticClass: "no-items-error-message" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("ATTRIBUTES_MGMT.LIST.EMPTY_RESULT.404")
                        ) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.uiFlags.isFetching
                ? _c("woot-loading-state", {
                    attrs: { message: _vm.$t("ATTRIBUTES_MGMT.LOADING") }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.uiFlags.isFetching && _vm.attributes.length
                ? _c("table", { staticClass: "woot-table" }, [
                    _c(
                      "thead",
                      _vm._l(
                        _vm.$t("ATTRIBUTES_MGMT.LIST.TABLE_HEADER"),
                        function(tableHeader) {
                          return _c(
                            "th",
                            { key: tableHeader, staticClass: "item" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(tableHeader) +
                                  "\n          "
                              )
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.attributes, function(attribute) {
                        return _c("tr", { key: attribute.attribute_key }, [
                          _c("td", { staticClass: "item text-truncate" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(attribute.attribute_display_name) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "item-description text-truncate" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(attribute.attribute_description) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("td", { staticClass: "item text-truncatee" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(attribute.attribute_display_type) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "item key text-truncate" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(attribute.attribute_key) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "button-wrapper" },
                            [
                              _c("woot-button", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top",
                                    value: _vm.$t(
                                      "ATTRIBUTES_MGMT.LIST.BUTTONS.EDIT"
                                    ),
                                    expression:
                                      "$t('ATTRIBUTES_MGMT.LIST.BUTTONS.EDIT')",
                                    modifiers: { top: true }
                                  }
                                ],
                                attrs: {
                                  variant: "smooth",
                                  size: "tiny",
                                  "color-scheme": "secondary",
                                  "class-names": "grey-btn",
                                  icon: "edit"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openEditPopup(attribute)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("woot-button", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top",
                                    value: _vm.$t(
                                      "ATTRIBUTES_MGMT.LIST.BUTTONS.DELETE"
                                    ),
                                    expression:
                                      "$t('ATTRIBUTES_MGMT.LIST.BUTTONS.DELETE')",
                                    modifiers: { top: true }
                                  }
                                ],
                                attrs: {
                                  variant: "smooth",
                                  "color-scheme": "alert",
                                  size: "tiny",
                                  icon: "dismiss-circle",
                                  "class-names": "grey-btn"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openDelete(attribute)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "small-4 columns" }, [
        _c("span", {
          directives: [
            {
              name: "dompurify-html",
              rawName: "v-dompurify-html",
              value: _vm.$t("ATTRIBUTES_MGMT.SIDEBAR_TXT"),
              expression: "$t('ATTRIBUTES_MGMT.SIDEBAR_TXT')"
            }
          ]
        })
      ]),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showEditPopup, "on-close": _vm.hideEditPopup },
          on: {
            "update:show": function($event) {
              _vm.showEditPopup = $event
            }
          }
        },
        [
          _c("edit-attribute", {
            attrs: {
              "selected-attribute": _vm.selectedAttribute,
              "is-updating": _vm.uiFlags.isUpdating
            },
            on: { "on-close": _vm.hideEditPopup }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showDeletePopup
        ? _c("woot-confirm-delete-modal", {
            attrs: {
              show: _vm.showDeletePopup,
              title: _vm.confirmDeleteTitle,
              message: _vm.$t("ATTRIBUTES_MGMT.DELETE.CONFIRM.MESSAGE"),
              "confirm-text": _vm.deleteConfirmText,
              "reject-text": _vm.deleteRejectText,
              "confirm-value": _vm.selectedAttribute.attribute_display_name,
              "confirm-place-holder-text": _vm.confirmPlaceHolderText
            },
            on: {
              "update:show": function($event) {
                _vm.showDeletePopup = $event
              },
              "on-confirm": _vm.confirmDeletion,
              "on-close": _vm.closeDelete
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }