var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-nav secondary-menu" },
    [
      _c("sidebar-header", {
        attrs: {
          "thumbnail-src": _vm.thumbnailSrc,
          "header-title": _vm.headerTitle,
          "sub-title": _vm.subTitle,
          "portal-link": _vm.portalLink
        },
        on: { "open-popover": _vm.openPortalPopover }
      }),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          staticClass: "menu vertical",
          attrs: { name: "menu-list", tag: "ul" }
        },
        [
          _vm._l(_vm.accessibleMenuItems, function(menuItem) {
            return _c("secondary-nav-item", {
              key: menuItem.toState,
              attrs: { "menu-item": menuItem }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.additionalSecondaryMenuItems, function(menuItem) {
            return _c("secondary-nav-item", {
              key: menuItem.key,
              attrs: { "menu-item": menuItem },
              on: { open: _vm.onClickOpenAddCatogoryModal }
            })
          }),
          _vm._v(" "),
          !_vm.hasCategory
            ? _c(
                "p",
                {
                  key: "empty-category-nessage",
                  staticClass: "empty-text text-muted"
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("SIDEBAR.HELP_CENTER.CATEGORY_EMPTY_MESSAGE")
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }