var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDyteIntegration
    ? _c("dyte-video-call", {
        attrs: {
          "message-id": _vm.messageId,
          "meeting-data": _vm.contentAttributes.data
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }