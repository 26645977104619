var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("HELP_CENTER.CATEGORY.ADD.TITLE"),
          "header-content": _vm.$t("HELP_CENTER.CATEGORY.ADD.SUB_TITLE")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onCreate($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("div", { staticClass: "row article-info" }, [
                _c("div", { staticClass: "columns medium-6" }, [
                  _c("label", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("HELP_CENTER.CATEGORY.ADD.PORTAL")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.portalName))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns medium-6" }, [
                  _c("label", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("HELP_CENTER.CATEGORY.ADD.LOCALE")))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.locale))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns",
                class: { error: _vm.$v.name.$error },
                attrs: {
                  error: _vm.nameError,
                  label: _vm.$t("HELP_CENTER.CATEGORY.ADD.NAME.LABEL"),
                  placeholder: _vm.$t(
                    "HELP_CENTER.CATEGORY.ADD.NAME.PLACEHOLDER"
                  ),
                  "help-text": _vm.$t("HELP_CENTER.CATEGORY.ADD.NAME.HELP_TEXT")
                },
                on: { input: _vm.onNameChange },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "name"
                }
              }),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns",
                class: { error: _vm.$v.slug.$error },
                attrs: {
                  error: _vm.slugError,
                  label: _vm.$t("HELP_CENTER.CATEGORY.ADD.SLUG.LABEL"),
                  placeholder: _vm.$t(
                    "HELP_CENTER.CATEGORY.ADD.SLUG.PLACEHOLDER"
                  ),
                  "help-text": _vm.$t("HELP_CENTER.CATEGORY.ADD.SLUG.HELP_TEXT")
                },
                on: { input: _vm.$v.slug.$touch },
                model: {
                  value: _vm.slug,
                  callback: function($$v) {
                    _vm.slug = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "slug"
                }
              }),
              _vm._v(" "),
              _c("label", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("HELP_CENTER.CATEGORY.ADD.DESCRIPTION.LABEL")
                    ) +
                    "\n        "
                ),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description"
                    }
                  ],
                  attrs: {
                    rows: "3",
                    type: "text",
                    placeholder: _vm.$t(
                      "HELP_CENTER.CATEGORY.ADD.DESCRIPTION.PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.description },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.description = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "medium-12 columns" }, [
                _c(
                  "div",
                  { staticClass: "modal-footer justify-content-end w-full" },
                  [
                    _c(
                      "woot-button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClose($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("HELP_CENTER.CATEGORY.ADD.BUTTONS.CANCEL")
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("woot-button", { on: { click: _vm.addCategory } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("HELP_CENTER.CATEGORY.ADD.BUTTONS.CREATE")
                          ) +
                          "\n          "
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }