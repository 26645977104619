var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      staticClass: "ui-snackbar-container",
      attrs: { name: "toast-fade", tag: "div" }
    },
    _vm._l(_vm.snackMessages, function(snackMessage) {
      return _c("woot-snackbar", {
        key: snackMessage.key,
        attrs: { message: snackMessage.message, action: snackMessage.action }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }