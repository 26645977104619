var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "small-12 medium-3 bg-white contact--panel",
      class: { "border-left": _vm.showAvatar }
    },
    [
      _vm.showAvatar
        ? _c(
            "span",
            { staticClass: "close-button", on: { click: _vm.onClose } },
            [
              _c("fluent-icon", {
                staticClass: "close-icon",
                attrs: { icon: "dismiss" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("contact-info", {
        attrs: { "show-avatar": _vm.showAvatar, contact: _vm.contact },
        on: { "panel-close": _vm.onClose }
      }),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticClass: "list-group",
          attrs: {
            list: _vm.contactSidebarItems,
            disabled: !_vm.dragEnabled,
            "ghost-class": "ghost"
          },
          on: {
            start: function($event) {
              _vm.dragging = true
            },
            end: _vm.onDragEnd
          }
        },
        [
          _c(
            "transition-group",
            _vm._l(_vm.contactSidebarItems, function(element) {
              return _c(
                "div",
                { key: element.name, staticClass: "list-group-item" },
                [
                  element.name === "contact_attributes"
                    ? _c(
                        "div",
                        [
                          _c(
                            "accordion-item",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "CONVERSATION_SIDEBAR.ACCORDION.CONTACT_ATTRIBUTES"
                                ),
                                "is-open": _vm.isContactSidebarItemOpen(
                                  "is_ct_custom_attr_open"
                                ),
                                compact: ""
                              },
                              on: {
                                click: function(value) {
                                  return _vm.toggleSidebarUIState(
                                    "is_ct_custom_attr_open",
                                    value
                                  )
                                }
                              }
                            },
                            [
                              _c("custom-attributes", {
                                staticClass: "even",
                                attrs: {
                                  "contact-id": _vm.contact.id,
                                  "attribute-type": "contact_attribute",
                                  "attribute-class": "conversation--attribute",
                                  "custom-attributes":
                                    _vm.contact.custom_attributes
                                }
                              }),
                              _vm._v(" "),
                              _c("custom-attribute-selector", {
                                attrs: {
                                  "attribute-type": "contact_attribute",
                                  "contact-id": _vm.contact.id
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  element.name === "contact_labels"
                    ? _c(
                        "div",
                        [
                          _c(
                            "accordion-item",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "CONTACT_PANEL.SIDEBAR_SECTIONS.CONTACT_LABELS"
                                ),
                                "is-open": _vm.isContactSidebarItemOpen(
                                  "is_ct_labels_open"
                                )
                              },
                              on: {
                                click: function(value) {
                                  return _vm.toggleSidebarUIState(
                                    "is_ct_labels_open",
                                    value
                                  )
                                }
                              }
                            },
                            [
                              _c("contact-label", {
                                staticClass: "contact-labels",
                                attrs: { "contact-id": _vm.contact.id }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  element.name === "previous_conversation"
                    ? _c(
                        "div",
                        [
                          _c(
                            "accordion-item",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "CONTACT_PANEL.SIDEBAR_SECTIONS.PREVIOUS_CONVERSATIONS"
                                ),
                                "is-open": _vm.isContactSidebarItemOpen(
                                  "is_ct_prev_conv_open"
                                )
                              },
                              on: {
                                click: function(value) {
                                  return _vm.toggleSidebarUIState(
                                    "is_ct_prev_conv_open",
                                    value
                                  )
                                }
                              }
                            },
                            [
                              _vm.contact.id
                                ? _c("contact-conversations", {
                                    attrs: {
                                      "contact-id": _vm.contact.id,
                                      "conversation-id": ""
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }