var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings--content" },
    [
      _c(
        "settings-section",
        {
          attrs: {
            title: _vm.$t("INBOX_MGMT.MICROSOFT.TITLE"),
            "sub-title": _vm.$t("INBOX_MGMT.MICROSOFT.SUBTITLE")
          }
        },
        [
          _c("div", { staticClass: "smtp-details-wrap" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.requestAuthorization($event)
                  }
                }
              },
              [
                _c("woot-submit-button", {
                  attrs: {
                    icon: "brand-twitter",
                    "button-text": "Sign in with Microsoft",
                    type: "submit",
                    loading: _vm.isRequestingAuthorization
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }