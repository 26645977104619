var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "settings-section",
    {
      attrs: {
        title: _vm.$t("INBOX_MGMT.FACEBOOK_REAUTHORIZE.TITLE"),
        "sub-title": _vm.$t("INBOX_MGMT.FACEBOOK_REAUTHORIZE.SUBTITLE")
      }
    },
    [
      _c(
        "a",
        {
          staticClass: "fb--login",
          attrs: { href: "#" },
          on: { click: _vm.tryFBlogin }
        },
        [
          _c("img", {
            attrs: {
              src: require("dashboard/assets/images/channels/facebook_login.png"),
              alt: "Facebook-logo"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }