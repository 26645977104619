var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-mask" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "on-clickaway",
            rawName: "v-on-clickaway",
            value: _vm.closeNotificationPanel,
            expression: "closeNotificationPanel"
          }
        ],
        staticClass: "notification-wrap flex-space-between"
      },
      [
        _c("div", { staticClass: "header-wrap w-full flex-space-between" }, [
          _c("div", { staticClass: "header-title--wrap flex-view" }, [
            _c("span", { staticClass: "header-title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.TITLE")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _vm.totalUnreadNotifications
              ? _c("span", { staticClass: "total-count block-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.totalUnreadNotifications) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-view" },
            [
              !_vm.noUnreadNotificationAvailable
                ? _c(
                    "woot-button",
                    {
                      attrs: {
                        "color-scheme": "primary",
                        variant: "smooth",
                        size: "tiny",
                        "class-names": "action-button",
                        "is-loading": _vm.uiFlags.isUpdating
                      },
                      on: { click: _vm.onMarkAllDoneClick }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("NOTIFICATIONS_PAGE.MARK_ALL_DONE")) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("woot-button", {
                attrs: {
                  "color-scheme": "secondary",
                  variant: "smooth",
                  size: "tiny",
                  "class-names": "action-button",
                  icon: "settings"
                },
                on: { click: _vm.openAudioNotificationSettings }
              }),
              _vm._v(" "),
              _c("woot-button", {
                attrs: {
                  "color-scheme": "secondary",
                  variant: "link",
                  size: "tiny",
                  icon: "dismiss"
                },
                on: { click: _vm.closeNotificationPanel }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("notification-panel-list", {
          attrs: {
            notifications: _vm.getUnreadNotifications,
            "is-loading": _vm.uiFlags.isFetching,
            "on-click-notification": _vm.openConversation,
            "in-last-page": _vm.inLastPage
          }
        }),
        _vm._v(" "),
        _vm.records.length !== 0
          ? _c("div", { staticClass: "footer-wrap flex-space-between" }, [
              _c(
                "div",
                { staticClass: "flex-view" },
                [
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        size: "medium",
                        variant: "clear",
                        "color-scheme": "secondary",
                        "class-names": "page-change--button",
                        "is-disabled": _vm.inFirstPage
                      },
                      on: { click: _vm.onClickFirstPage }
                    },
                    [
                      _c("fluent-icon", {
                        attrs: { icon: "chevron-left", size: "16" }
                      }),
                      _vm._v(" "),
                      _c("fluent-icon", {
                        staticClass: "margin-left-minus-slab",
                        attrs: { icon: "chevron-left", size: "16" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("woot-button", {
                    attrs: {
                      "color-scheme": "secondary",
                      variant: "clear",
                      size: "medium",
                      icon: "chevron-left",
                      disabled: _vm.inFirstPage
                    },
                    on: { click: _vm.onClickPreviousPage }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "page-count" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.currentPage) +
                    " - " +
                    _vm._s(_vm.lastPage) +
                    " "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-view" },
                [
                  _c("woot-button", {
                    attrs: {
                      "color-scheme": "secondary",
                      variant: "clear",
                      size: "medium",
                      icon: "chevron-right",
                      disabled: _vm.inLastPage
                    },
                    on: { click: _vm.onClickNextPage }
                  }),
                  _vm._v(" "),
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        size: "medium",
                        variant: "clear",
                        "color-scheme": "secondary",
                        "class-names": "page-change--button",
                        disabled: _vm.inLastPage
                      },
                      on: { click: _vm.onClickLastPage }
                    },
                    [
                      _c("fluent-icon", {
                        attrs: { icon: "chevron-right", size: "16" }
                      }),
                      _vm._v(" "),
                      _c("fluent-icon", {
                        staticClass: "margin-left-minus-slab",
                        attrs: { icon: "chevron-right", size: "16" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _c("div")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }