var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-button",
    {
      staticClass: "label--add",
      attrs: { variant: "smooth", icon: "add", size: "tiny" },
      on: { click: _vm.addLabel }
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.$t("CONTACT_PANEL.LABELS.CONVERSATION.ADD_BUTTON")) +
          "\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }