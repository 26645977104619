var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agent-placeholder" },
    [
      _c("spinner"),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm.$t("CONVERSATION.CARD_CONTEXT_MENU.AGENTS_LOADING")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }