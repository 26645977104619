var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-button",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.right",
          value: _vm.$t("SIDEBAR.PROFILE_SETTINGS"),
          expression: "$t(`SIDEBAR.PROFILE_SETTINGS`)",
          modifiers: { right: true }
        }
      ],
      staticClass: "current-user",
      attrs: { variant: "link" },
      on: { click: _vm.handleClick }
    },
    [
      _c("thumbnail", {
        attrs: {
          src: _vm.currentUser.avatar_url,
          username: _vm.currentUser.name,
          status: _vm.statusOfAgent,
          "should-show-status-always": "",
          size: "32px"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }