var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns notification--page" }, [
    _c(
      "div",
      { staticClass: "notification--content medium-12" },
      [
        _c("notification-table", {
          attrs: {
            notifications: _vm.records,
            "is-loading": _vm.uiFlags.isFetching,
            "is-updating": _vm.uiFlags.isUpdating,
            "on-click-notification": _vm.openConversation,
            "on-mark-all-done-click": _vm.onMarkAllDoneClick
          }
        }),
        _vm._v(" "),
        _c("table-footer", {
          attrs: {
            "current-page": Number(_vm.meta.currentPage),
            "total-count": _vm.meta.count
          },
          on: { "page-change": _vm.onPageChange }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }