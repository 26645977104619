var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "widget-body-container" }, [
    _vm.config.isDefaultScreen
      ? _c(
          "div",
          { staticClass: "availability-content" },
          [
            _c("div", { staticClass: "availability-info" }, [
              _c("div", { staticClass: "team-status" }, [
                _vm._v("\n        " + _vm._s(_vm.getStatusText) + "\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "reply-wait-message" }, [
                _vm._v("\n        " + _vm._s(_vm.config.replyTime) + "\n      ")
              ])
            ]),
            _vm._v(" "),
            _c("thumbnail", { attrs: { username: "J", size: "40px" } })
          ],
          1
        )
      : _c("div", { staticClass: "conversation-content" }, [
          _c("div", { staticClass: "conversation-wrap" }, [
            _c("div", { staticClass: "message-wrap" }, [
              _c("div", { staticClass: "user-message-wrap" }, [
                _c("div", { staticClass: "user-message" }, [
                  _c("div", { staticClass: "message-wrap" }, [
                    _c(
                      "div",
                      {
                        staticClass: "chat-bubble user",
                        style: { background: _vm.config.color }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "INBOX_MGMT.WIDGET_BUILDER.BODY.USER_MESSAGE"
                              )
                            )
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "agent-message-wrap" }, [
              _c("div", { staticClass: "agent-message" }, [
                _c("div", { staticClass: "avatar-wrap" }),
                _vm._v(" "),
                _c("div", { staticClass: "message-wrap" }, [
                  _c("div", { staticClass: "chat-bubble agent" }, [
                    _c("div", { staticClass: "message-content" }, [
                      _c("p", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.$t(
                                "INBOX_MGMT.WIDGET_BUILDER.BODY.AGENT_MESSAGE"
                              )
                            ) +
                            "\n                "
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }