var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length
    ? _c(
        "ul",
        {
          staticClass: "vertical dropdown menu mention--box",
          class: { "with-bottom-border": _vm.items.length <= 4 }
        },
        _vm._l(_vm.items, function(agent, index) {
          return _c(
            "li",
            {
              key: agent.id,
              class: { active: index === _vm.selectedIndex },
              attrs: { id: "mention-item-" + index },
              on: {
                click: function($event) {
                  return _vm.onAgentSelect(index)
                },
                mouseover: function($event) {
                  return _vm.onHover(index)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "mention--thumbnail" },
                [
                  _c("woot-thumbnail", {
                    attrs: {
                      src: agent.thumbnail,
                      username: agent.name,
                      size: "32px"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mention--metadata text-truncate" }, [
                _c(
                  "h5",
                  {
                    staticClass:
                      "text-block-title mention--user-name text-truncate"
                  },
                  [_vm._v("\n        " + _vm._s(agent.name) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-truncate mention--email text-truncate" },
                  [_vm._v("\n        " + _vm._s(agent.email) + "\n      ")]
                )
              ])
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }