var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-body small-9 columns" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("TEAMS_SETTINGS.EDIT_FLOW.CREATE.TITLE"),
          "header-content": _vm.$t("TEAMS_SETTINGS.EDIT_FLOW.CREATE.DESC")
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row channels" },
        [
          _vm.showTeamForm
            ? _c("team-form", {
                attrs: {
                  "on-submit": _vm.updateTeam,
                  "submit-in-progress": false,
                  "submit-button-text": _vm.$t(
                    "TEAMS_SETTINGS.EDIT_FLOW.CREATE.BUTTON_TEXT"
                  ),
                  "form-data": _vm.teamData
                }
              })
            : _c("spinner")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }