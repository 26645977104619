var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-list-item--wrap h-full flex-view " },
    [
      _vm._l(_vm.notifications, function(notificationItem) {
        return _c(
          "woot-button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoading,
                expression: "!isLoading"
              }
            ],
            key: notificationItem.id,
            attrs: {
              size: "expanded",
              "color-scheme": "secondary",
              variant: "link"
            },
            on: {
              click: function() {
                return _vm.onClickNotification(notificationItem)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "notification-list--wrap flex-view w-full" },
              [
                !notificationItem.read_at
                  ? _c("div", { staticClass: "notification-unread--indicator" })
                  : _c("div", { staticClass: "empty flex-view" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "notification-content--wrap w-full flex-space-between"
                  },
                  [
                    _c("div", { staticClass: "flex-space-between" }, [
                      _c("div", { staticClass: "title-wrap flex-view " }, [
                        _c("span", { staticClass: "notification-title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                "#" +
                                  (notificationItem.primary_actor
                                    ? notificationItem.primary_actor.id
                                    : _vm.$t("NOTIFICATIONS_PAGE.DELETE_TITLE"))
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "notification-type" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "NOTIFICATIONS_PAGE.TYPE_LABEL." +
                                    notificationItem.notification_type
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          notificationItem.primary_actor.meta.assignee
                            ? _c("thumbnail", {
                                attrs: {
                                  src:
                                    notificationItem.primary_actor.meta.assignee
                                      .thumbnail,
                                  size: "16px",
                                  username:
                                    notificationItem.primary_actor.meta.assignee
                                      .name
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-full flex-view " }, [
                      _c(
                        "span",
                        { staticClass: "notification-message text-truncate" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(notificationItem.push_message_title) +
                              "\n          "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "timestamp flex-view" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.dynamicTime(notificationItem.created_at)) +
                          "\n        "
                      )
                    ])
                  ]
                )
              ]
            )
          ]
        )
      }),
      _vm._v(" "),
      _vm.showEmptyResult
        ? _c("empty-state", {
            attrs: {
              title: _vm.$t(
                "NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.EMPTY_MESSAGE"
              )
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && _vm.inLastPage
        ? _c(
            "woot-button",
            {
              attrs: {
                size: "medium",
                variant: "clear",
                "color-scheme": "primary",
                "class-names": "action-button"
              },
              on: { click: _vm.openNotificationPage }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t(
                      "NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.ALL_NOTIFICATIONS"
                    )
                  ) +
                  "\n  "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "notifications-loader flex-view" },
            [
              _c("spinner"),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "NOTIFICATIONS_PAGE.UNREAD_NOTIFICATION.LOADING_UNREAD_MESSAGE"
                    )
                  )
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }