var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column content-box" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "column small-12 medium-8 conversation-metric" },
        [
          _c(
            "metric-card",
            {
              attrs: {
                header: this.$t(
                  "OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.HEADER"
                ),
                "is-loading": _vm.uiFlags.isFetchingAccountConversationMetric,
                "loading-message": _vm.$t(
                  "OVERVIEW_REPORTS.ACCOUNT_CONVERSATIONS.LOADING_MESSAGE"
                )
              }
            },
            _vm._l(_vm.conversationMetrics, function(metric, name, index) {
              return _c(
                "div",
                { key: index, staticClass: "metric-content column" },
                [
                  _c("h3", { staticClass: "heading" }, [
                    _vm._v("\n            " + _vm._s(name) + "\n          ")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "metric" }, [_vm._v(_vm._s(metric))])
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column small-12 medium-4" },
        [
          _c(
            "metric-card",
            {
              attrs: { header: this.$t("OVERVIEW_REPORTS.AGENT_STATUS.HEADER") }
            },
            _vm._l(_vm.agentStatusMetrics, function(metric, name, index) {
              return _c(
                "div",
                { key: index, staticClass: "metric-content column" },
                [
                  _c("h3", { staticClass: "heading" }, [
                    _vm._v("\n            " + _vm._s(name) + "\n          ")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "metric" }, [_vm._v(_vm._s(metric))])
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "metric-card",
          {
            attrs: {
              header: this.$t("OVERVIEW_REPORTS.AGENT_CONVERSATIONS.HEADER")
            }
          },
          [
            _c("agent-table", {
              attrs: {
                agents: _vm.agents,
                "agent-metrics": _vm.agentConversationMetric,
                "page-index": _vm.pageIndex,
                "is-loading": _vm.uiFlags.isFetchingAgentConversationMetric
              },
              on: { "page-change": _vm.onPageNumberChange }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }