var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video message-text__wrap" },
    [
      _c("video", {
        attrs: { src: _vm.url, muted: "", playsInline: "" },
        domProps: { muted: true },
        on: { click: _vm.onClick }
      }),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.show, "on-close": _vm.onClose },
          on: {
            "update:show": function($event) {
              _vm.show = $event
            }
          }
        },
        [
          _c("video", {
            staticClass: "modal-video",
            attrs: { src: _vm.url, controls: "", playsInline: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }