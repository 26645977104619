var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      !_vm.selectedWaTemplate
        ? _c("templates-picker", {
            attrs: { "inbox-id": _vm.inboxId },
            on: { onSelect: _vm.pickTemplate }
          })
        : _c("template-parser", {
            attrs: { template: _vm.selectedWaTemplate },
            on: {
              resetTemplate: _vm.onResetTemplate,
              sendMessage: _vm.onSendMessage
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }