var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.show,
        "on-close": _vm.onCancel,
        "modal-type": "right-aligned"
      },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("CREATE_CONTACT.TITLE"),
              "header-content": _vm.$t("CREATE_CONTACT.DESC")
            }
          }),
          _vm._v(" "),
          _c("contact-form", {
            attrs: {
              "in-progress": _vm.uiFlags.isCreating,
              "on-submit": _vm.onSubmit
            },
            on: { success: _vm.onSuccess, cancel: _vm.onCancel }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }