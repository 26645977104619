var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: {
        show: _vm.show,
        size: "medium",
        "on-close": function() {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS")
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "shortcut__wrap margin-top-3" }, [
            _c("div", { staticClass: "title-key__wrap" }, [
              _c("h5", { staticClass: "text-block-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("KEYBOARD_SHORTCUTS.TOGGLE_MODAL")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "shortcut-key__wrap" }, [
                _c("p", { staticClass: "shortcut-key" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t(
                          "KEYBOARD_SHORTCUTS.KEYS.WINDOWS_KEY_AND_COMMAND_KEY"
                        )
                      ) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "shortcut-key key" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("KEYBOARD_SHORTCUTS.KEYS.FORWARD_SLASH_KEY")
                      ) +
                      "\n          "
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "shortcut__wrap" },
            [
              _c("div", { staticClass: "title-key__wrap" }, [
                _c("h5", { staticClass: "text-block-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("KEYBOARD_SHORTCUTS.TITLE.OPEN_CONVERSATION")
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "shortcut-key__wrap" }, [
                  _c("div", { staticClass: "open-conversation__key" }, [
                    _c("span", { staticClass: "shortcut-key" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("KEYBOARD_SHORTCUTS.KEYS.ALT_OR_OPTION_KEY")
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "shortcut-key" }, [
                      _vm._v("\n              J\n            ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "forward-slash text-block-title" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "KEYBOARD_SHORTCUTS.KEYS.FORWARD_SLASH_KEY"
                              )
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "shortcut-key" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("KEYBOARD_SHORTCUTS.KEYS.ALT_OR_OPTION_KEY")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "shortcut-key key" }, [
                    _vm._v("\n            K\n          ")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "title-key__wrap" }, [
                _c("h5", { staticClass: "text-block-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("KEYBOARD_SHORTCUTS.TITLE.RESOLVE_AND_NEXT")
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "shortcut-key__wrap" }, [
                  _c("span", { staticClass: "shortcut-key" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "KEYBOARD_SHORTCUTS.KEYS.WINDOWS_KEY_AND_COMMAND_KEY"
                          )
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "shortcut-key" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("KEYBOARD_SHORTCUTS.KEYS.ALT_OR_OPTION_KEY")
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "shortcut-key key" }, [
                    _vm._v("\n            E\n          ")
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.shortcutKeys, function(shortcutKey) {
                return _c(
                  "div",
                  { key: shortcutKey.id, staticClass: "title-key__wrap" },
                  [
                    _c("h5", { staticClass: "text-block-title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.title(shortcutKey)) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "shortcut-key__wrap" }, [
                      _c("span", { staticClass: "shortcut-key" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(shortcutKey.firstkey) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "shortcut-key key" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(shortcutKey.secondKey) +
                            "\n          "
                        )
                      ])
                    ])
                  ]
                )
              })
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }