var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "row",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.createChannel()
        }
      }
    },
    [
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("label", { class: { error: _vm.$v.channelName.$error } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.LABEL")) +
              "\n      "
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.channelName,
                expression: "channelName",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.PLACEHOLDER"
              )
            },
            domProps: { value: _vm.channelName },
            on: {
              blur: [
                _vm.$v.channelName.$touch,
                function($event) {
                  return _vm.$forceUpdate()
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.channelName = $event.target.value.trim()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.channelName.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  _vm._s(_vm.$t("INBOX_MGMT.ADD.TWILIO.CHANNEL_NAME.ERROR"))
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "medium-8 columns" }, [
        _vm.useMessagingService
          ? _c(
              "label",
              { class: { error: _vm.$v.messagingServiceSID.$error } },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t(
                        "INBOX_MGMT.ADD.TWILIO.MESSAGING_SERVICE_SID.LABEL"
                      )
                    ) +
                    "\n      "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.messagingServiceSID,
                      expression: "messagingServiceSID",
                      modifiers: { trim: true }
                    }
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t(
                      "INBOX_MGMT.ADD.TWILIO.MESSAGING_SERVICE_SID.PLACEHOLDER"
                    )
                  },
                  domProps: { value: _vm.messagingServiceSID },
                  on: {
                    blur: [
                      _vm.$v.messagingServiceSID.$touch,
                      function($event) {
                        return _vm.$forceUpdate()
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.messagingServiceSID = $event.target.value.trim()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.$v.messagingServiceSID.$error
                  ? _c("span", { staticClass: "message" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "INBOX_MGMT.ADD.TWILIO.MESSAGING_SERVICE_SID.ERROR"
                          )
                        )
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.useMessagingService
        ? _c("div", { staticClass: "medium-8 columns" }, [
            _c("label", { class: { error: _vm.$v.phoneNumber.$error } }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.LABEL")) +
                  "\n      "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.phoneNumber,
                    expression: "phoneNumber",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.phoneNumber },
                on: {
                  blur: [
                    _vm.$v.phoneNumber.$touch,
                    function($event) {
                      return _vm.$forceUpdate()
                    }
                  ],
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.phoneNumber = $event.target.value.trim()
                  }
                }
              }),
              _vm._v(" "),
              _vm.$v.phoneNumber.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      _vm._s(_vm.$t("INBOX_MGMT.ADD.TWILIO.PHONE_NUMBER.ERROR"))
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "medium-8 columns messagingServiceHelptext" }, [
        _c("label", { attrs: { for: "useMessagingService" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.useMessagingService,
                expression: "useMessagingService"
              }
            ],
            staticClass: "checkbox",
            attrs: { id: "useMessagingService", type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.useMessagingService)
                ? _vm._i(_vm.useMessagingService, null) > -1
                : _vm.useMessagingService
            },
            on: {
              change: function($event) {
                var $$a = _vm.useMessagingService,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.useMessagingService = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.useMessagingService = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.useMessagingService = $$c
                }
              }
            }
          }),
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(
                  "INBOX_MGMT.ADD.TWILIO.MESSAGING_SERVICE_SID.USE_MESSAGING_SERVICE"
                )
              ) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("label", { class: { error: _vm.$v.accountSID.$error } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.LABEL")) +
              "\n      "
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.accountSID,
                expression: "accountSID",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.PLACEHOLDER"
              )
            },
            domProps: { value: _vm.accountSID },
            on: {
              blur: [
                _vm.$v.accountSID.$touch,
                function($event) {
                  return _vm.$forceUpdate()
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.accountSID = $event.target.value.trim()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.accountSID.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(
                  _vm._s(_vm.$t("INBOX_MGMT.ADD.TWILIO.ACCOUNT_SID.ERROR"))
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("label", { class: { error: _vm.$v.authToken.$error } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("INBOX_MGMT.ADD.TWILIO.AUTH_TOKEN.LABEL")) +
              "\n      "
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.authToken,
                expression: "authToken",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t(
                "INBOX_MGMT.ADD.TWILIO.AUTH_TOKEN.PLACEHOLDER"
              )
            },
            domProps: { value: _vm.authToken },
            on: {
              blur: [
                _vm.$v.authToken.$touch,
                function($event) {
                  return _vm.$forceUpdate()
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.authToken = $event.target.value.trim()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.authToken.$error
            ? _c("span", { staticClass: "message" }, [
                _vm._v(_vm._s(_vm.$t("INBOX_MGMT.ADD.TWILIO.AUTH_TOKEN.ERROR")))
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medium-12 columns" },
        [
          _c("woot-submit-button", {
            attrs: {
              loading: _vm.uiFlags.isCreating,
              "button-text": _vm.$t("INBOX_MGMT.ADD.TWILIO.SUBMIT_BUTTON")
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }