var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.provider
    ? _c(
        "div",
        { staticClass: "wizard-body small-12 medium-9 columns height-auto" },
        [
          _c("page-header", {
            attrs: {
              "header-title": _vm.$t("INBOX_MGMT.ADD.EMAIL_PROVIDER.TITLE"),
              "header-content": _vm.$t(
                "INBOX_MGMT.ADD.EMAIL_PROVIDER.DESCRIPTION"
              )
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row channel-list" },
            _vm._l(_vm.emailProviderList, function(emailProvider) {
              return _c("channel-selector", {
                key: emailProvider.key,
                attrs: { title: emailProvider.title, src: emailProvider.src },
                on: {
                  click: function() {
                    return _vm.onClick(emailProvider.key)
                  }
                }
              })
            }),
            1
          )
        ],
        1
      )
    : _vm.provider === "microsoft"
    ? _c("microsoft")
    : _vm.provider === "other_provider"
    ? _c("forward-to-option")
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }