var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-wrap" },
    [
      _vm.config.isDefaultScreen
        ? _c(
            "custom-button",
            {
              staticClass: "start-conversation",
              style: { background: _vm.config.color }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t(
                      "INBOX_MGMT.WIDGET_BUILDER.FOOTER.START_CONVERSATION_BUTTON_TEXT"
                    )
                  ) +
                  "\n  "
              )
            ]
          )
        : _c(
            "div",
            { staticClass: "chat-message-input is-focused" },
            [
              _c("resizable-text-area", {
                staticClass: "user-message-input is-focused",
                attrs: {
                  id: "chat-input",
                  placeholder: _vm.$t(
                    "INBOX_MGMT.WIDGET_BUILDER.FOOTER.CHAT_INPUT_PLACEHOLDER"
                  )
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "button-wrap" },
                [
                  _c("fluent-icon", { attrs: { icon: "emoji" } }),
                  _vm._v(" "),
                  _c("fluent-icon", {
                    staticClass: "icon-send",
                    attrs: { icon: "send" }
                  })
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }