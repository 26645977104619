var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("add-note", { on: { add: _vm.onAddNote } }),
      _vm._v(" "),
      _vm._l(_vm.notes, function(note) {
        return _c("contact-note", {
          key: note.id,
          attrs: {
            id: note.id,
            note: note.content,
            user: note.user,
            "created-at": note.created_at
          },
          on: { edit: _vm.onEditNote, delete: _vm.onDeleteNote }
        })
      }),
      _vm._v(" "),
      _vm.isFetching
        ? _c(
            "div",
            { staticClass: "text-center p-normal fs-default" },
            [
              _c("spinner", { attrs: { size: "" } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("NOTES.FETCHING_NOTES")))])
            ],
            1
          )
        : !_vm.notes.length
        ? _c("div", { staticClass: "text-center p-normal fs-default" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("NOTES.NOT_AVAILABLE")))])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }