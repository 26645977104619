var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact--group" },
    [
      _c("fluent-icon", {
        staticClass: "file--icon",
        attrs: { icon: "call", size: "18" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "meta" }, [
        _c("p", { staticClass: "text-truncate margin-bottom-0" }, [
          _vm._v("\n      " + _vm._s(_vm.phoneNumber) + "\n    ")
        ])
      ]),
      _vm._v(" "),
      _vm.formattedPhoneNumber
        ? _c(
            "div",
            { staticClass: "link-wrap" },
            [
              _c(
                "woot-button",
                {
                  attrs: { variant: "clear", size: "small" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.addContact($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CONVERSATION.SAVE_CONTACT")) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }