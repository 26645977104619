var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("note-list", {
    attrs: { "is-fetching": _vm.uiFlags.isFetching, notes: _vm.notes },
    on: { add: _vm.onAdd, delete: _vm.onDelete }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }