var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "medium-12 columns" }, [
    _c(
      "div",
      { staticClass: "templates__list-search" },
      [
        _c("fluent-icon", {
          staticClass: "search-icon",
          attrs: { icon: "search", size: "16" }
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.query,
              expression: "query"
            }
          ],
          ref: "search",
          staticClass: "templates__search-input",
          attrs: {
            type: "search",
            placeholder: _vm.$t("WHATSAPP_TEMPLATES.PICKER.SEARCH_PLACEHOLDER")
          },
          domProps: { value: _vm.query },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.query = $event.target.value
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "template__list-container" },
      [
        _vm._l(_vm.filteredTemplateMessages, function(template, i) {
          return _c("div", { key: template.id }, [
            _c(
              "button",
              {
                staticClass: "template__list-item",
                on: {
                  click: function($event) {
                    return _vm.$emit("onSelect", template)
                  }
                }
              },
              [
                _c("div", [
                  _c("div", { staticClass: "flex-between" }, [
                    _c("p", { staticClass: "label-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(template.name) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "label-lang label" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("WHATSAPP_TEMPLATES.PICKER.LABELS.LANGUAGE")
                          ) +
                          " :\n              " +
                          _vm._s(template.language) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "strong" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t(
                              "WHATSAPP_TEMPLATES.PICKER.LABELS.TEMPLATE_BODY"
                            )
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "label-body" }, [
                      _vm._v(_vm._s(_vm.getTemplatebody(template)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "label-category" }, [
                    _c("p", { staticClass: "strong" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("WHATSAPP_TEMPLATES.PICKER.LABELS.CATEGORY")
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(template.category))])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            i != _vm.filteredTemplateMessages.length - 1
              ? _c("hr", { key: "hr-" + i })
              : _vm._e()
          ])
        }),
        _vm._v(" "),
        !_vm.filteredTemplateMessages.length
          ? _c("div", [
              _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("WHATSAPP_TEMPLATES.PICKER.NO_TEMPLATES_FOUND")
                    ) +
                    "\n        "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.query))])
              ])
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }