var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isMenuItemVisible,
          expression: "isMenuItemVisible"
        }
      ],
      staticClass: "sidebar-item"
    },
    [
      _vm.hasSubMenu
        ? _c("div", { staticClass: "secondary-menu--wrap" }, [
            _c("span", { staticClass: "secondary-menu--header fs-small" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("SIDEBAR." + _vm.menuItem.label)) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _vm.menuItem.showNewButton
              ? _c(
                  "div",
                  { staticClass: "submenu-icons" },
                  [
                    _c("woot-button", {
                      staticClass: "submenu-icon",
                      attrs: {
                        size: "tiny",
                        variant: "clear",
                        "color-scheme": "secondary",
                        icon: "add"
                      },
                      on: { click: _vm.onClickOpen }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _c(
            "router-link",
            {
              staticClass:
                "secondary-menu--title secondary-menu--link fs-small",
              class: _vm.computedClass,
              attrs: { to: _vm.menuItem && _vm.menuItem.toState }
            },
            [
              _c("fluent-icon", {
                staticClass: "secondary-menu--icon",
                attrs: { icon: _vm.menuItem.icon, size: "14" }
              }),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("SIDEBAR." + _vm.menuItem.label)) +
                  "\n    "
              ),
              _vm.showChildCount(_vm.menuItem.count)
                ? _c("span", { staticClass: "count-view" }, [
                    _vm._v(
                      "\n      " + _vm._s("" + _vm.menuItem.count) + "\n    "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.menuItem.beta
                ? _c(
                    "span",
                    {
                      staticClass: "beta",
                      attrs: { "data-view-component": "true", label: "Beta" }
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.$t("SIDEBAR.BETA")) + "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
      _vm._v(" "),
      _vm.hasSubMenu
        ? _c(
            "ul",
            { staticClass: "nested vertical menu" },
            [
              _vm._l(_vm.menuItem.children, function(child) {
                return _c("secondary-child-nav-item", {
                  key: child.id,
                  attrs: {
                    to: child.toState,
                    label: child.label,
                    "label-color": child.color,
                    "should-truncate": child.truncateLabel,
                    icon: _vm.computedInboxClass(child),
                    "warning-icon": _vm.computedInboxErrorClass(child),
                    "show-child-count": _vm.showChildCount(child.count),
                    "child-item-count": child.count
                  }
                })
              }),
              _vm._v(" "),
              _vm.showItem(_vm.menuItem)
                ? _c("router-link", {
                    attrs: { to: _vm.menuItem.toState, custom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var href = ref.href
                            var isActive = ref.isActive
                            var navigate = ref.navigate
                            return [
                              _c("li", { staticClass: "menu-item--new" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "button small link clear secondary",
                                    class: { "is-active": isActive },
                                    attrs: { href: href },
                                    on: {
                                      click: function(e) {
                                        return _vm.newLinkClick(e, navigate)
                                      }
                                    }
                                  },
                                  [
                                    _c("fluent-icon", {
                                      attrs: { icon: "add", size: "16" }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "button__content" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t(
                                                "SIDEBAR." +
                                                  _vm.menuItem.newLinkTag
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4180125266
                    )
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }