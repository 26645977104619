var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "option-item--user" },
    [
      _c("thumbnail", {
        attrs: { src: _vm.thumbnail, size: "28px", username: _vm.name }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "option__user-data" }, [
        _c("h5", { staticClass: "option__title" }, [
          _vm._v("\n      " + _vm._s(_vm.name) + "\n      "),
          _vm.identifier
            ? _c("span", { staticClass: "user-identifier" }, [
                _vm._v("\n        (ID: " + _vm._s(_vm.identifier) + ")\n      ")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "option__body" }, [
          _vm.email
            ? _c(
                "span",
                { staticClass: "email-icon-wrap" },
                [
                  _c("fluent-icon", {
                    staticClass: "merge-contact--icon",
                    attrs: { icon: "mail", size: "12" }
                  }),
                  _vm._v("\n        " + _vm._s(_vm.email) + "\n      ")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.phoneNumber
            ? _c(
                "span",
                { staticClass: "phone-icon-wrap" },
                [
                  _c("fluent-icon", {
                    staticClass: "merge-contact--icon",
                    attrs: { icon: "call", size: "12" }
                  }),
                  _vm._v("\n        " + _vm._s(_vm.phoneNumber) + "\n      ")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.phoneNumber && !_vm.email
            ? _c("span", [_vm._v(_vm._s("---"))])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }