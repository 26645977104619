var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "merge-contacts" }, [
        _c(
          "div",
          { staticClass: "multiselect-wrap--medium" },
          [
            _c(
              "label",
              { staticClass: "multiselect__label" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("MERGE_CONTACTS.PRIMARY.TITLE")) +
                    "\n        "
                ),
                _c("woot-label", {
                  staticClass: "label--merge-warning",
                  attrs: {
                    title: _vm.$t("MERGE_CONTACTS.PRIMARY.HELP_LABEL"),
                    "color-scheme": "success",
                    small: ""
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("multiselect", {
              attrs: {
                value: _vm.primaryContact,
                disabled: "",
                options: [],
                "show-labels": false,
                label: "name",
                "track-by": "id"
              },
              scopedSlots: _vm._u([
                {
                  key: "singleLabel",
                  fn: function(props) {
                    return [
                      _c("contact-dropdown-item", {
                        attrs: {
                          thumbnail: props.option.thumbnail,
                          name: props.option.name,
                          identifier: props.option.id,
                          email: props.option.email,
                          "phone-number": props.option.phoneNumber
                        }
                      })
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "child-contact-wrap" }, [
          _c(
            "div",
            { staticClass: "child-arrow" },
            [
              _c("fluent-icon", {
                staticClass: "up",
                attrs: { icon: "arrow-up", size: "17" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "child-contact multiselect-wrap--medium",
              class: { error: _vm.$v.childContact.$error }
            },
            [
              _c(
                "label",
                { staticClass: "multiselect__label" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("MERGE_CONTACTS.CHILD.TITLE"))
                  ),
                  _c("woot-label", {
                    staticClass: "label--merge-warning",
                    attrs: {
                      title: _vm.$t("MERGE_CONTACTS.CHILD.HELP_LABEL"),
                      "color-scheme": "alert",
                      small: ""
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "multiselect",
                {
                  attrs: {
                    options: _vm.searchResults,
                    label: "name",
                    "track-by": "id",
                    "internal-search": false,
                    "clear-on-select": false,
                    "show-labels": false,
                    placeholder: _vm.$t("MERGE_CONTACTS.CHILD.PLACEHOLDER"),
                    "allow-empty": true,
                    loading: _vm.isSearching,
                    "max-height": 150,
                    "open-direction": "top"
                  },
                  on: { "search-change": _vm.searchChange },
                  scopedSlots: _vm._u([
                    {
                      key: "singleLabel",
                      fn: function(props) {
                        return [
                          _c("contact-dropdown-item", {
                            attrs: {
                              thumbnail: props.option.thumbnail,
                              identifier: props.option.id,
                              name: props.option.name,
                              email: props.option.email,
                              "phone-number": props.option.phone_number
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "option",
                      fn: function(props) {
                        return [
                          _c("contact-dropdown-item", {
                            attrs: {
                              thumbnail: props.option.thumbnail,
                              identifier: props.option.id,
                              name: props.option.name,
                              email: props.option.email,
                              "phone-number": props.option.phone_number
                            }
                          })
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.childContact,
                    callback: function($$v) {
                      _vm.childContact = $$v
                    },
                    expression: "childContact"
                  }
                },
                [
                  _vm._v(" "),
                  _vm._v(" "),
                  _c(
                    "span",
                    { attrs: { slot: "noResult" }, slot: "noResult" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("AGENT_MGMT.SEARCH.NO_RESULTS")) +
                          "\n          "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.$v.childContact.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("MERGE_CONTACTS.FORM.CHILD_CONTACT.ERROR")
                        ) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("merge-contact-summary", {
        attrs: {
          "primary-contact-name": _vm.primaryContact.name,
          "child-contact-name": _vm.childContactName
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "woot-button",
            {
              attrs: { variant: "clear" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onCancel($event)
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("MERGE_CONTACTS.FORM.CANCEL")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "woot-button",
            { attrs: { type: "submit", "is-loading": _vm.isMerging } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("MERGE_CONTACTS.FORM.SUBMIT")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }