var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mention-box", {
    attrs: { items: _vm.items },
    on: { "mention-select": _vm.handleMentionClick },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("strong", [_vm._v(_vm._s(item.label))]),
            _vm._v(" - " + _vm._s(item.description) + "\n  ")
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }