var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { attrs: { for: "dropdown-select" } }, [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
    ]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value"
          }
        ],
        attrs: { id: "dropdown-select", name: "dropdown-select" },
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.value = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            function($event) {
              return _vm.action(_vm.value)
            }
          ]
        }
      },
      _vm._l(_vm.options, function(option) {
        return _c("option", { key: option.key }, [
          _vm._v("\n      " + _vm._s(option.value) + "\n    ")
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }