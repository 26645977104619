var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row content-box full-height" },
    [
      _vm.showAddButton
        ? _c(
            "woot-button",
            {
              attrs: {
                "color-scheme": "success",
                "class-names": "button--fixed-right-top",
                icon: "add-circle"
              },
              on: { click: _vm.openAddHookModal }
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("INTEGRATION_APPS.ADD_BUTTON")) +
                  "\n  "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showIntegrationHooks
        ? _c("div", { staticClass: "integration-hooks" }, [
            _vm.isIntegrationMultiple
              ? _c(
                  "div",
                  [
                    _c("multiple-integration-hooks", {
                      attrs: { integration: _vm.integration },
                      on: { delete: _vm.openDeletePopup }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isIntegrationSingle
              ? _c(
                  "div",
                  [
                    _c("single-integration-hooks", {
                      attrs: { integration: _vm.integration },
                      on: {
                        add: _vm.openAddHookModal,
                        delete: _vm.openDeletePopup
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showAddHookModal,
            "on-close": _vm.hideAddHookModal
          },
          on: {
            "update:show": function($event) {
              _vm.showAddHookModal = $event
            }
          }
        },
        [
          _c("new-hook", {
            attrs: { integration: _vm.integration },
            on: { close: _vm.hideAddHookModal }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.deleteTitle,
          message: _vm.deleteMessage,
          "confirm-text": _vm.confirmText,
          "reject-text": _vm.cancelText
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }