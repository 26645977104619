var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [_vm._v("\n    " + _vm._s(_vm.app.title) + "\n  ")]),
    _vm._v(" "),
    _c("td", { staticClass: "dashboard-app-label-url" }, [
      _c("span", { staticClass: "text-truncate" }, [
        _vm._v("\n      " + _vm._s(_vm.app.content[0].url) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "button-wrapper" },
      [
        _c("woot-button", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip.top",
              value: _vm.$t(
                "INTEGRATION_SETTINGS.DASHBOARD_APPS.LIST.EDIT_TOOLTIP"
              ),
              expression:
                "\n        $t('INTEGRATION_SETTINGS.DASHBOARD_APPS.LIST.EDIT_TOOLTIP')\n      ",
              modifiers: { top: true }
            }
          ],
          attrs: {
            variant: "smooth",
            size: "tiny",
            "color-scheme": "secondary",
            "class-names": "grey-btn",
            icon: "edit"
          },
          on: {
            click: function($event) {
              return _vm.$emit("edit", _vm.app)
            }
          }
        }),
        _vm._v(" "),
        _c("woot-button", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip.top",
              value: _vm.$t(
                "INTEGRATION_SETTINGS.DASHBOARD_APPS.LIST.DELETE_TOOLTIP"
              ),
              expression:
                "\n        $t('INTEGRATION_SETTINGS.DASHBOARD_APPS.LIST.DELETE_TOOLTIP')\n      ",
              modifiers: { top: true }
            }
          ],
          attrs: {
            variant: "smooth",
            "color-scheme": "alert",
            size: "tiny",
            icon: "dismiss-circle",
            "class-names": "grey-btn"
          },
          on: {
            click: function($event) {
              return _vm.$emit("delete", _vm.app)
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }