var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "multiselect-wrap--small" },
      [
        _c("multiselect", {
          attrs: {
            "track-by": "id",
            label: "name",
            placeholder: _vm.$t("AUTOMATION.ACTION.TEAM_DROPDOWN_PLACEHOLDER"),
            multiple: true,
            "selected-label": "",
            "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
            "deselect-label": "",
            "max-height": 160,
            options: _vm.teams,
            "allow-empty": false
          },
          on: { input: _vm.updateValue },
          model: {
            value: _vm.selectedTeams,
            callback: function($$v) {
              _vm.selectedTeams = $$v
            },
            expression: "selectedTeams"
          }
        }),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.message,
              expression: "message"
            }
          ],
          attrs: {
            rows: "4",
            placeholder: _vm.$t(
              "AUTOMATION.ACTION.TEAM_MESSAGE_INPUT_PLACEHOLDER"
            )
          },
          domProps: { value: _vm.message },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.message = $event.target.value
              },
              _vm.updateValue
            ]
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }