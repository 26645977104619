var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onClose },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: { "header-title": _vm.$t("FILTER.CUSTOM_VIEWS.ADD.TITLE") }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.saveCustomViews($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-input", {
                class: { error: _vm.$v.name.$error },
                attrs: {
                  label: _vm.$t("FILTER.CUSTOM_VIEWS.ADD.LABEL"),
                  type: "text",
                  error: _vm.$v.name.$error
                    ? _vm.$t("FILTER.CUSTOM_VIEWS.ADD.ERROR_MESSAGE")
                    : "",
                  placeholder: _vm.$t("FILTER.CUSTOM_VIEWS.ADD.PLACEHOLDER")
                },
                on: { blur: _vm.$v.name.$touch },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "woot-button",
                    { attrs: { disabled: _vm.isButtonDisabled } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("FILTER.CUSTOM_VIEWS.ADD.SAVE_BUTTON")
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "woot-button",
                    {
                      attrs: { variant: "clear" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onClose($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("FILTER.CUSTOM_VIEWS.ADD.CANCEL_BUTTON")
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }