var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-snackbar" }, [
      _c("div", { staticClass: "ui-snackbar-text" }, [
        _vm._v("\n      " + _vm._s(_vm.message) + "\n    ")
      ]),
      _vm._v(" "),
      _vm.action
        ? _c(
            "div",
            { staticClass: "ui-snackbar-action" },
            [
              _vm.action.type == "link"
                ? _c("router-link", { attrs: { to: _vm.action.to } }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.action.message) + "\n      "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }