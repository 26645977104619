var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-body columns content-box small-9" }, [
    !_vm.hasLoginStarted
      ? _c("div", { staticClass: "login-init full-height" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  return _vm.startLogin()
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("dashboard/assets/images/channels/facebook_login.png"),
                  alt: "Facebook-logo"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.useInstallationName(
                    _vm.$t("INBOX_MGMT.ADD.FB.HELP"),
                    _vm.globalConfig.installationName
                  )
                ) +
                "\n    "
            )
          ])
        ])
      : _c(
          "div",
          [
            _vm.showLoader
              ? _c("loading-state", {
                  attrs: { message: _vm.emptyStateMessage }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.showLoader
              ? _c(
                  "form",
                  {
                    staticClass: "row",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.createChannel()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "medium-12 columns" },
                      [
                        _c("page-header", {
                          attrs: {
                            "header-title": _vm.$t(
                              "INBOX_MGMT.ADD.DETAILS.TITLE"
                            ),
                            "header-content": _vm.useInstallationName(
                              _vm.$t("INBOX_MGMT.ADD.DETAILS.DESC"),
                              _vm.globalConfig.installationName
                            )
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "medium-7 columns" }, [
                      _c("div", { staticClass: "medium-12 columns" }, [
                        _c(
                          "div",
                          {
                            staticClass: "input-wrap",
                            class: { error: _vm.$v.selectedPage.$error }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("INBOX_MGMT.ADD.FB.CHOOSE_PAGE")
                                ) +
                                "\n            "
                            ),
                            _c("multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "allow-empty": true,
                                options: _vm.getSelectablePages,
                                "track-by": "id",
                                label: "name",
                                "select-label": _vm.$t(
                                  "FORMS.MULTISELECT.ENTER_TO_SELECT"
                                ),
                                "deselect-label": _vm.$t(
                                  "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                                ),
                                placeholder: _vm.$t(
                                  "INBOX_MGMT.ADD.FB.PICK_A_VALUE"
                                ),
                                "selected-label": ""
                              },
                              on: { select: _vm.setPageName },
                              model: {
                                value: _vm.selectedPage,
                                callback: function($$v) {
                                  _vm.selectedPage =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "selectedPage"
                              }
                            }),
                            _vm._v(" "),
                            _vm.$v.selectedPage.$error
                              ? _c("span", { staticClass: "message" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "INBOX_MGMT.ADD.FB.CHOOSE_PLACEHOLDER"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "medium-12 columns" }, [
                        _c(
                          "label",
                          { class: { error: _vm.$v.pageName.$error } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("INBOX_MGMT.ADD.FB.INBOX_NAME")) +
                                "\n            "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.pageName,
                                  expression: "pageName",
                                  modifiers: { trim: true }
                                }
                              ],
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t(
                                  "INBOX_MGMT.ADD.FB.PICK_NAME"
                                )
                              },
                              domProps: { value: _vm.pageName },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.pageName = $event.target.value.trim()
                                  },
                                  _vm.$v.pageName.$touch
                                ],
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.$v.pageName.$error
                              ? _c("span", { staticClass: "message" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("INBOX_MGMT.ADD.FB.ADD_NAME")
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(0)
                    ])
                  ]
                )
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "medium-12 columns text-right" }, [
      _c("input", {
        staticClass: "button",
        attrs: { type: "submit", value: "Create Inbox" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }