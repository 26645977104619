var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.computedClass,
      attrs: {
        type: _vm.type,
        "data-testid": "submit_button",
        disabled: _vm.disabled
      },
      on: { click: _vm.onClick }
    },
    [
      !!_vm.iconClass
        ? _c("fluent-icon", {
            staticClass: "icon",
            attrs: { icon: _vm.iconClass }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.buttonText))]),
      _vm._v(" "),
      _vm.loading ? _c("spinner") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }