var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "contact--form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "columns" },
          [
            _c("woot-avatar-uploader", {
              staticClass: "settings-item",
              attrs: {
                label: _vm.$t("CONTACT_FORM.FORM.AVATAR.LABEL"),
                src: _vm.avatarUrl,
                "username-avatar": _vm.name,
                "delete-avatar": !!_vm.avatarUrl
              },
              on: {
                change: _vm.handleImageUpload,
                onAvatarDelete: _vm.handleAvatarDelete
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "columns" }, [
          _c("label", { class: { error: _vm.$v.name.$error } }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("CONTACT_FORM.FORM.NAME.LABEL")) +
                "\n        "
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.name,
                  expression: "name",
                  modifiers: { trim: true }
                }
              ],
              attrs: {
                type: "text",
                placeholder: _vm.$t("CONTACT_FORM.FORM.NAME.PLACEHOLDER")
              },
              domProps: { value: _vm.name },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value.trim()
                  },
                  _vm.$v.name.$touch
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("label", { class: { error: _vm.$v.email.$error } }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("CONTACT_FORM.FORM.EMAIL_ADDRESS.LABEL")) +
                "\n        "
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.email,
                  expression: "email",
                  modifiers: { trim: true }
                }
              ],
              attrs: {
                type: "text",
                placeholder: _vm.$t(
                  "CONTACT_FORM.FORM.EMAIL_ADDRESS.PLACEHOLDER"
                )
              },
              domProps: { value: _vm.email },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value.trim()
                  },
                  _vm.$v.email.$touch
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.email.$error
              ? _c("span", { staticClass: "message" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("CONTACT_FORM.FORM.EMAIL_ADDRESS.ERROR")) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "medium-12 columns" }, [
        _c("label", { class: { error: _vm.$v.description.$error } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("CONTACT_FORM.FORM.BIO.LABEL")) +
              "\n      "
          ),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.description,
                expression: "description",
                modifiers: { trim: true }
              }
            ],
            attrs: {
              type: "text",
              placeholder: _vm.$t("CONTACT_FORM.FORM.BIO.PLACEHOLDER")
            },
            domProps: { value: _vm.description },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.description = $event.target.value.trim()
                },
                _vm.$v.description.$touch
              ],
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "medium-12 columns" }, [
          _c("label", { class: { error: _vm.$v.phoneNumber.$error } }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("CONTACT_FORM.FORM.PHONE_NUMBER.LABEL")) +
                "\n        "
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.phoneNumber,
                  expression: "phoneNumber",
                  modifiers: { trim: true }
                }
              ],
              attrs: {
                type: "text",
                placeholder: _vm.$t(
                  "CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER"
                )
              },
              domProps: { value: _vm.phoneNumber },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.phoneNumber = $event.target.value.trim()
                  },
                  _vm.$v.phoneNumber.$touch
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.phoneNumber.$error
              ? _c("span", { staticClass: "message" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("CONTACT_FORM.FORM.PHONE_NUMBER.ERROR")) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.$v.phoneNumber.$error || !_vm.phoneNumber
            ? _c("div", { staticClass: "callout small warning" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("CONTACT_FORM.FORM.PHONE_NUMBER.HELP")) +
                    "\n      "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("woot-input", {
        staticClass: "columns",
        attrs: {
          label: _vm.$t("CONTACT_FORM.FORM.COMPANY_NAME.LABEL"),
          placeholder: _vm.$t("CONTACT_FORM.FORM.COMPANY_NAME.PLACEHOLDER")
        },
        model: {
          value: _vm.companyName,
          callback: function($$v) {
            _vm.companyName = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "companyName"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medium-12 columns" },
        [
          _c("label", [_vm._v("\n      Social Profiles\n    ")]),
          _vm._v(" "),
          _vm._l(_vm.socialProfileKeys, function(socialProfile) {
            return _c(
              "div",
              { key: socialProfile.key, staticClass: "input-group" },
              [
                _c("span", { staticClass: "input-group-label" }, [
                  _vm._v(_vm._s(socialProfile.prefixURL))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.socialProfileUserNames[socialProfile.key],
                      expression: "socialProfileUserNames[socialProfile.key]"
                    }
                  ],
                  staticClass: "input-group-field",
                  attrs: { type: "text" },
                  domProps: {
                    value: _vm.socialProfileUserNames[socialProfile.key]
                  },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.socialProfileUserNames,
                        socialProfile.key,
                        $event.target.value
                      )
                    }
                  }
                })
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "div",
          { staticClass: "medium-12 columns" },
          [
            _c("woot-submit-button", {
              attrs: {
                loading: _vm.inProgress,
                "button-text": _vm.$t("CONTACT_FORM.FORM.SUBMIT")
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button clear",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onCancel($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("CONTACT_FORM.FORM.CANCEL")) +
                    "\n      "
                )
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }