var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.availableProfiles.length
    ? _c(
        "div",
        { staticClass: "social--icons" },
        _vm._l(_vm.availableProfiles, function(profile) {
          return _c(
            "a",
            {
              key: profile.key,
              staticClass: "contact--social-icon",
              attrs: {
                href: "" + profile.link + _vm.socialProfiles[profile.key],
                target: "_blank",
                rel: "noopener noreferrer nofollow"
              }
            },
            [
              _c("fluent-icon", {
                attrs: { icon: "brand-" + profile.key, size: "16" }
              })
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }