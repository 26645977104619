var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "toggle-button",
      class: { active: _vm.value, small: _vm.size === "small" },
      attrs: {
        type: "button",
        role: "switch",
        "aria-checked": _vm.value.toString()
      },
      on: { click: _vm.onClick }
    },
    [
      _c("span", {
        class: { active: _vm.value },
        attrs: { "aria-hidden": "true" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }