var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c("div", { staticClass: "webhook--link" }, [
        _vm._v(_vm._s(_vm.webhook.url))
      ]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "webhook--subscribed-events" },
        [
          _c("span", { staticClass: "webhook--subscribed-label" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.SUBSCRIBED_EVENTS")
                ) +
                ":\n      "
            )
          ]),
          _vm._v(" "),
          _c("show-more", { attrs: { text: _vm.subscribedEvents, limit: 60 } })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "button-wrapper" },
      [
        _c("woot-button", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip.top",
              value: _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.EDIT.BUTTON_TEXT"),
              expression: "$t('INTEGRATION_SETTINGS.WEBHOOK.EDIT.BUTTON_TEXT')",
              modifiers: { top: true }
            }
          ],
          attrs: {
            variant: "smooth",
            size: "tiny",
            "color-scheme": "secondary",
            icon: "edit"
          },
          on: {
            click: function($event) {
              return _vm.$emit("edit", _vm.webhook)
            }
          }
        }),
        _vm._v(" "),
        _c("woot-button", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip.top",
              value: _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.DELETE.BUTTON_TEXT"),
              expression:
                "$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.BUTTON_TEXT')",
              modifiers: { top: true }
            }
          ],
          attrs: {
            variant: "smooth",
            "color-scheme": "alert",
            size: "tiny",
            icon: "dismiss-circle"
          },
          on: {
            click: function($event) {
              return _vm.$emit("delete", _vm.webhook, _vm.index)
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }