var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inbox--name" },
    [
      _c("fluent-icon", {
        staticClass: "inbox--icon",
        attrs: { icon: _vm.computedInboxClass, size: "12" }
      }),
      _vm._v("\n  " + _vm._s(_vm.inbox.name) + "\n")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }