var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      staticClass: "account-selector--modal",
      attrs: {
        show: _vm.show,
        "on-close": function() {
          return _vm.$emit("close-account-create-modal")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("CREATE_ACCOUNT.NEW_ACCOUNT"),
              "header-content": _vm.$t("CREATE_ACCOUNT.SELECTOR_SUBTITLE")
            }
          }),
          _vm._v(" "),
          !_vm.hasAccounts
            ? _c("div", { staticClass: "alert-wrap" }, [
                _c("div", { staticClass: "callout alert" }, [
                  _c(
                    "div",
                    { staticClass: "icon-wrap" },
                    [_c("fluent-icon", { attrs: { icon: "warning" } })],
                    1
                  ),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("CREATE_ACCOUNT.NO_ACCOUNT_WARNING")) +
                      "\n      "
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "row",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.addAccount($event)
                }
              }
            },
            [
              _c("div", { staticClass: "medium-12 columns" }, [
                _c("label", { class: { error: _vm.$v.accountName.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("CREATE_ACCOUNT.FORM.NAME.LABEL")) +
                      "\n          "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.accountName,
                        expression: "accountName",
                        modifiers: { trim: true }
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "CREATE_ACCOUNT.FORM.NAME.PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.accountName },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.accountName = $event.target.value.trim()
                        },
                        _vm.$v.accountName.$touch
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer medium-12 columns" }, [
                _c(
                  "div",
                  { staticClass: "medium-12 columns" },
                  [
                    _c("woot-submit-button", {
                      attrs: {
                        disabled:
                          _vm.$v.accountName.$invalid ||
                          _vm.$v.accountName.$invalid ||
                          _vm.uiFlags.isCreating,
                        "button-text": _vm.$t("CREATE_ACCOUNT.FORM.SUBMIT"),
                        loading: _vm.uiFlags.isCreating,
                        "button-class": "large expanded"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }