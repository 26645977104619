var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row csat--metrics-container" },
    [
      _c("csat-metric-card", {
        attrs: {
          label: _vm.$t("CSAT_REPORTS.METRIC.TOTAL_RESPONSES.LABEL"),
          "info-text": _vm.$t("CSAT_REPORTS.METRIC.TOTAL_RESPONSES.TOOLTIP"),
          value: _vm.responseCount
        }
      }),
      _vm._v(" "),
      _c("csat-metric-card", {
        attrs: {
          label: _vm.$t("CSAT_REPORTS.METRIC.SATISFACTION_SCORE.LABEL"),
          "info-text": _vm.$t("CSAT_REPORTS.METRIC.SATISFACTION_SCORE.TOOLTIP"),
          value: _vm.formatToPercent(_vm.satisfactionScore)
        }
      }),
      _vm._v(" "),
      _c("csat-metric-card", {
        attrs: {
          label: _vm.$t("CSAT_REPORTS.METRIC.RESPONSE_RATE.LABEL"),
          "info-text": _vm.$t("CSAT_REPORTS.METRIC.RESPONSE_RATE.TOOLTIP"),
          value: _vm.formatToPercent(_vm.responseRate)
        }
      }),
      _vm._v(" "),
      _vm.metrics.totalResponseCount
        ? _c("div", { staticClass: "medium-6 report-card" }, [
            _c("h3", { staticClass: "heading" }, [
              _c(
                "div",
                { staticClass: "emoji--distribution" },
                _vm._l(_vm.ratingPercentage, function(rating, key, index) {
                  return _c(
                    "div",
                    {
                      key: rating + key + index,
                      staticClass: "emoji--distribution-item"
                    },
                    [
                      _c("span", { staticClass: "emoji--distribution-key" }, [
                        _vm._v(_vm._s(_vm.csatRatings[key - 1].emoji))
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.formatToPercent(rating)))])
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "emoji--distribution-chart" },
              [
                _c("woot-horizontal-bar", {
                  attrs: { collection: _vm.chartData, height: 24 }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }