var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "multiselect-wrap--small" },
        [
          _c("contact-details-item", {
            attrs: {
              compact: "",
              title: _vm.$t("CONVERSATION_SIDEBAR.ASSIGNEE_LABEL")
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function() {
                  return [
                    _vm.showSelfAssign
                      ? _c(
                          "woot-button",
                          {
                            attrs: {
                              icon: "arrow-right",
                              variant: "link",
                              size: "small"
                            },
                            on: { click: _vm.onSelfAssign }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("CONVERSATION_SIDEBAR.SELF_ASSIGN")
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm._v(" "),
          _c("multiselect-dropdown", {
            attrs: {
              options: _vm.agentsList,
              "selected-item": _vm.assignedAgent,
              "multiselector-title": _vm.$t(
                "AGENT_MGMT.MULTI_SELECTOR.TITLE.AGENT"
              ),
              "multiselector-placeholder": _vm.$t(
                "AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER"
              ),
              "no-search-result": _vm.$t(
                "AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.AGENT"
              ),
              "input-placeholder": _vm.$t(
                "AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.AGENT"
              )
            },
            on: { click: _vm.onClickAssignAgent }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "multiselect-wrap--small" },
        [
          _c("contact-details-item", {
            attrs: {
              compact: "",
              title: _vm.$t("CONVERSATION_SIDEBAR.TEAM_LABEL")
            }
          }),
          _vm._v(" "),
          _c("multiselect-dropdown", {
            attrs: {
              options: _vm.teamsList,
              "selected-item": _vm.assignedTeam,
              "multiselector-title": _vm.$t(
                "AGENT_MGMT.MULTI_SELECTOR.TITLE.TEAM"
              ),
              "multiselector-placeholder": _vm.$t(
                "AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER"
              ),
              "no-search-result": _vm.$t(
                "AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.TEAM"
              ),
              "input-placeholder": _vm.$t(
                "AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.TEAM"
              )
            },
            on: { click: _vm.onClickAssignTeam }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("contact-details-item", {
        attrs: {
          compact: "",
          title: _vm.$t("CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_LABELS")
        }
      }),
      _vm._v(" "),
      _c("conversation-labels", {
        attrs: { "conversation-id": _vm.conversationId }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }