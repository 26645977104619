var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "small-6 medium-4 large-3 channel",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("img", { attrs: { src: _vm.src, alt: _vm.title } }),
      _vm._v(" "),
      _c("h3", { staticClass: "channel__title" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }