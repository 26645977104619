var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portal", class: { active: _vm.active } },
    [
      _c("thumbnail", {
        attrs: { username: _vm.portal.name, variant: "square" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "actions-container" }, [
        _c(
          "header",
          [
            _c("div", [
              _c("h3", { staticClass: "sub-block-title portal-title" }, [
                _vm._v(_vm._s(_vm.portal.name))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "portal-count" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.articlesCount) +
                    "\n          " +
                    _vm._s(_vm.$t("HELP_CENTER.PORTAL.ARTICLES_LABEL")) +
                    "\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _vm.active
              ? _c("woot-label", {
                  attrs: {
                    variant: "smooth",
                    size: "small",
                    "color-scheme": "success",
                    title: _vm.$t("HELP_CENTER.PORTAL.ACTIVE_BADGE")
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "portal-locales" }, [
          _c("h5", { staticClass: "locale-title sub-block-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("HELP_CENTER.PORTAL.CHOOSE_LOCALE_LABEL")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.locales, function(locale) {
              return _c(
                "li",
                { key: locale.code },
                [
                  _c(
                    "woot-button",
                    {
                      attrs: {
                        variant:
                          "locale-item " +
                          (_vm.isLocaleActive(locale.code, _vm.activePortalSlug)
                            ? "smooth"
                            : "clear"),
                        size: "large",
                        "color-scheme": "secondary"
                      },
                      on: {
                        click: function(event) {
                          return _vm.onClick(event, locale.code, _vm.portal)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "locale-content" }, [
                        _c("div", { staticClass: "meta" }, [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "text-block-title text-left locale-name"
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.localeName(locale.code)) +
                                    "\n                  "
                                )
                              ]),
                              _vm._v(" "),
                              _vm.isLocaleDefault(locale.code)
                                ? _c(
                                    "span",
                                    { staticClass: "fs-small text-muted" },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            "(" +
                                              _vm.$t(
                                                "HELP_CENTER.PORTAL.DEFAULT"
                                              ) +
                                              ")"
                                          ) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "locale-meta" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(locale.articles_count) +
                                "\n                  " +
                                _vm._s(
                                  _vm.$t("HELP_CENTER.PORTAL.ARTICLES_LABEL")
                                ) +
                                " -\n                  " +
                                _vm._s(locale.code) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.isLocaleActive(locale.code, _vm.activePortalSlug)
                          ? _c(
                              "div",
                              [
                                _c("fluent-icon", {
                                  staticClass: "locale__radio",
                                  attrs: { icon: "checkmark" }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }