var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "login-box medium-4 column align-self-middle",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.login()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column log-in-form" },
        [
          _c("h4", [_vm._v(_vm._s(_vm.$t("SET_NEW_PASSWORD.TITLE")))]),
          _vm._v(" "),
          _c(
            "label",
            { class: { error: _vm.$v.credentials.password.$error } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("LOGIN.PASSWORD.LABEL")) + "\n      "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.credentials.password,
                    expression: "credentials.password",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "password",
                  placeholder: _vm.$t("SET_NEW_PASSWORD.PASSWORD.PLACEHOLDER")
                },
                domProps: { value: _vm.credentials.password },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.credentials,
                        "password",
                        $event.target.value.trim()
                      )
                    },
                    _vm.$v.credentials.password.$touch
                  ],
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.$v.credentials.password.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("SET_NEW_PASSWORD.PASSWORD.ERROR")) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "label",
            { class: { error: _vm.$v.credentials.confirmPassword.$error } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("SET_NEW_PASSWORD.CONFIRM_PASSWORD.LABEL")) +
                  "\n      "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.credentials.confirmPassword,
                    expression: "credentials.confirmPassword",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "password",
                  placeholder: _vm.$t(
                    "SET_NEW_PASSWORD.CONFIRM_PASSWORD.PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.credentials.confirmPassword },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.credentials,
                        "confirmPassword",
                        $event.target.value.trim()
                      )
                    },
                    _vm.$v.credentials.confirmPassword.$touch
                  ],
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.$v.credentials.confirmPassword.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("SET_NEW_PASSWORD.CONFIRM_PASSWORD.ERROR")
                        ) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("woot-submit-button", {
            attrs: {
              disabled:
                _vm.$v.credentials.password.$invalid ||
                _vm.$v.credentials.confirmPassword.$invalid ||
                _vm.newPasswordAPI.showLoading,
              "button-text": _vm.$t("SET_NEW_PASSWORD.SUBMIT"),
              loading: _vm.newPasswordAPI.showLoading,
              "button-class": "expanded"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }