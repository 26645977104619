var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "integration--image" }, [
      _c("img", {
        attrs: { src: "/dashboard/images/integrations/" + _vm.integrationLogo }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "column" }, [
      _c("h3", { staticClass: "integration--title" }, [
        _vm._v("\n      " + _vm._s(_vm.integrationName) + "\n    ")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "integration--description" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.useInstallationName(
                _vm.integrationDescription,
                _vm.globalConfig.installationName
              )
            ) +
            "\n    "
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "small-2 column button-wrap" },
      [
        _c("woot-label", {
          attrs: { title: _vm.labelText, "color-scheme": _vm.labelColor }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "small-2 column button-wrap" },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: _vm.frontendURL(
                "accounts/" +
                  _vm.accountId +
                  "/settings/applications/" +
                  _vm.integrationId
              )
            }
          },
          [
            _c("woot-button", { attrs: { icon: "settings" } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("INTEGRATION_APPS.CONFIGURE")) +
                  "\n      "
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }