var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "back-button",
      on: {
        "!click": function($event) {
          return _vm.goBack($event)
        }
      }
    },
    [
      _c("fluent-icon", { attrs: { icon: "chevron-left" } }),
      _vm._v(
        "\n  " +
          _vm._s(_vm.buttonLabel || _vm.$t("GENERAL_SETTINGS.BACK")) +
          "\n"
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }