var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-header--wrap" }, [
    _c(
      "div",
      { staticClass: "header-left--side" },
      [
        _c("thumbnail", {
          attrs: {
            size: "32px",
            src: _vm.thumbnailSrc,
            username: _vm.headerTitle,
            variant: "square"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "header-title--wrap" }, [
          _c(
            "h4",
            { staticClass: "text-block-title title-view text-truncate" },
            [_vm._v("\n        " + _vm._s(_vm.headerTitle) + "\n      ")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "sub-title--view" }, [
            _vm._v(_vm._s(_vm.subTitle))
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "header-right--side" },
      [
        _c("woot-button", {
          attrs: {
            variant: "clear",
            "color-scheme": "secondary",
            size: "small",
            icon: "arrow-up-right"
          },
          on: { click: _vm.popoutHelpCenter }
        }),
        _vm._v(" "),
        _c("woot-button", {
          attrs: {
            variant: "clear",
            size: "small",
            "color-scheme": "secondary",
            icon: "arrow-swap"
          },
          on: { click: _vm.openPortalPopover }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }