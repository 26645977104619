import { render, staticRenderFns } from "./CardLabels.vue?vue&type=template&id=6c359941&scoped=true&"
import script from "./CardLabels.vue?vue&type=script&lang=js&"
export * from "./CardLabels.vue?vue&type=script&lang=js&"
import style0 from "./CardLabels.vue?vue&type=style&index=0&id=6c359941&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c359941",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c359941')) {
      api.createRecord('6c359941', component.options)
    } else {
      api.reload('6c359941', component.options)
    }
    module.hot.accept("./CardLabels.vue?vue&type=template&id=6c359941&scoped=true&", function () {
      api.rerender('6c359941', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/components/widgets/conversation/conversationCardComponents/CardLabels.vue"
export default component.exports