var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", {
    attrs: { to: _vm.to, custom: "", "active-class": "active" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var href = ref.href
          var isActive = ref.isActive
          var navigate = ref.navigate
          return [
            _c("li", { class: { active: isActive } }, [
              _c(
                "a",
                {
                  staticClass: "button clear menu-item text-truncate",
                  class: {
                    "is-active": isActive,
                    "text-truncate": _vm.shouldTruncate
                  },
                  attrs: { href: href },
                  on: { click: navigate }
                },
                [
                  _vm.icon
                    ? _c(
                        "span",
                        { staticClass: "badge--icon" },
                        [
                          _c("fluent-icon", {
                            staticClass: "inbox-icon",
                            attrs: { icon: _vm.icon, size: "12" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.labelColor
                    ? _c("span", {
                        staticClass: "badge--label",
                        style: { backgroundColor: _vm.labelColor }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "menu-label button__content",
                      class: { "text-truncate": _vm.shouldTruncate },
                      attrs: { title: _vm.menuTitle }
                    },
                    [
                      _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
                      _vm.showChildCount
                        ? _c("span", { staticClass: "count-view" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.childItemCount) +
                                "\n        "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.count
                    ? _c(
                        "span",
                        {
                          staticClass: "badge",
                          class: { secondary: !isActive }
                        },
                        [_vm._v("\n        " + _vm._s(_vm.count) + "\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.warningIcon
                    ? _c(
                        "span",
                        { staticClass: "badge--icon" },
                        [
                          _c("fluent-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top-end",
                                value: _vm.$t("SIDEBAR.FACEBOOK_REAUTHORIZE"),
                                expression:
                                  "$t('SIDEBAR.FACEBOOK_REAUTHORIZE')",
                                modifiers: { "top-end": true }
                              }
                            ],
                            staticClass: "inbox-icon",
                            attrs: { icon: _vm.warningIcon, size: "12" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }