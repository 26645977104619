var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.uiFlags.isFetching && !_vm.macros.length
        ? _c(
            "div",
            { staticClass: "macros_list--empty-state" },
            [
              _c("p", { staticClass: "no-items-error-message" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("MACROS.LIST.404")) + "\n    "
                )
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: _vm.addAccountScoping("settings/macros") } },
                [
                  _c(
                    "woot-button",
                    {
                      staticClass: "macros_add-button",
                      attrs: { variant: "smooth", icon: "add", size: "tiny" }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("MACROS.HEADER_BTN_TXT")) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.uiFlags.isFetching
        ? _c("woot-loading-state", {
            attrs: { message: _vm.$t("MACROS.LOADING") }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.uiFlags.isFetching && _vm.macros.length
        ? _c(
            "div",
            { staticClass: "macros-list" },
            _vm._l(_vm.macros, function(macro) {
              return _c("macro-item", {
                key: macro.id,
                attrs: { macro: macro, "conversation-id": _vm.conversationId }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }