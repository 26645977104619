var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-body columns content-box small-9" }, [
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.addAgents($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "medium-12 columns" },
          [
            _c("page-header", {
              attrs: {
                "header-title": _vm.headerTitle,
                "header-content": _vm.$t("TEAMS_SETTINGS.EDIT_FLOW.AGENTS.DESC")
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "medium-12 columns" },
          [
            _vm.$v.selectedAgents.$error
              ? _c("div", [
                  _c("p", { staticClass: "error-message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("TEAMS_SETTINGS.ADD.AGENT_VALIDATION_ERROR")
                        ) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showAgentsList
              ? _c("agent-selector", {
                  attrs: {
                    "agent-list": _vm.agentList,
                    "selected-agents": _vm.selectedAgents,
                    "update-selected-agents": _vm.updateSelectedAgents,
                    "is-working": _vm.isCreating,
                    "submit-button-text": _vm.$t(
                      "TEAMS_SETTINGS.EDIT_FLOW.AGENTS.BUTTON_TEXT"
                    )
                  }
                })
              : _c("spinner")
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }