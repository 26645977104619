var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner", class: _vm.bannerClasses },
    [
      _c("span", { staticClass: "banner-message" }, [
        _vm._v("\n    " + _vm._s(_vm.bannerMessage) + "\n    "),
        _vm.hrefLink
          ? _c(
              "a",
              {
                attrs: {
                  href: _vm.hrefLink,
                  rel: "noopener noreferrer nofollow",
                  target: "_blank"
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.hrefLinkText) + "\n    ")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.hasActionButton
        ? _c(
            "woot-button",
            {
              attrs: {
                size: "small",
                variant: "link",
                icon: "arrow-right",
                "color-scheme": "primary",
                "class-names": "banner-action__button"
              },
              on: { click: _vm.onClick }
            },
            [_vm._v("\n    " + _vm._s(_vm.actionButtonLabel) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasCloseButton
        ? _c("woot-button", {
            attrs: {
              size: "small",
              variant: "link",
              "color-scheme": "secondary",
              icon: "dismiss-circle",
              "class-names": "banner-action__button"
            },
            on: { click: _vm.onClickClose }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }