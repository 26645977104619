var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "colorpicker" },
    [
      _c("div", {
        staticClass: "colorpicker--selected",
        style: "background-color: " + _vm.value,
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.toggleColorPicker($event)
          }
        }
      }),
      _vm._v(" "),
      _vm.isPickerOpen
        ? _c("chrome", {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.closeTogglePicker,
                expression: "closeTogglePicker"
              }
            ],
            staticClass: "colorpicker--chrome",
            attrs: { "disable-alpha": true, value: _vm.value },
            on: { input: _vm.updateColor }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }