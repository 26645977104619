var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "medium-12 column login" }, [
    _c(
      "section",
      { staticClass: "text-center medium-12 login__hero align-self-top" },
      [
        _c("img", {
          staticClass: "hero__logo",
          attrs: {
            src: _vm.globalConfig.logo,
            alt: _vm.globalConfig.installationName
          }
        }),
        _vm._v(" "),
        _c("h2", { staticClass: "hero__title" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.useInstallationName(
                  _vm.$t("LOGIN.TITLE"),
                  _vm.globalConfig.installationName
                )
              ) +
              "\n    "
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "row align-center" },
      [
        !_vm.email
          ? _c("div", { staticClass: "small-12 medium-4 column" }, [
              _c(
                "div",
                { staticClass: "login-box column align-self-top" },
                [
                  _vm.showGoogleOAuth()
                    ? _c("GoogleOAuthButton", {
                        staticClass: "oauth-reverse",
                        attrs: { "button-size": "large" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticClass: "column log-in-form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.login()
                        }
                      }
                    },
                    [
                      _c(
                        "label",
                        { class: { error: _vm.$v.credentials.email.$error } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("LOGIN.EMAIL.LABEL")) +
                              "\n            "
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.credentials.email,
                                expression: "credentials.email",
                                modifiers: { trim: true }
                              }
                            ],
                            attrs: {
                              type: "text",
                              "data-testid": "email_input",
                              placeholder: _vm.$t("LOGIN.EMAIL.PLACEHOLDER")
                            },
                            domProps: { value: _vm.credentials.email },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.credentials,
                                    "email",
                                    $event.target.value.trim()
                                  )
                                },
                                _vm.$v.credentials.email.$touch
                              ],
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          class: { error: _vm.$v.credentials.password.$error }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("LOGIN.PASSWORD.LABEL")) +
                              "\n            "
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.credentials.password,
                                expression: "credentials.password",
                                modifiers: { trim: true }
                              }
                            ],
                            attrs: {
                              type: "password",
                              "data-testid": "password_input",
                              placeholder: _vm.$t("LOGIN.PASSWORD.PLACEHOLDER")
                            },
                            domProps: { value: _vm.credentials.password },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.credentials,
                                    "password",
                                    $event.target.value.trim()
                                  )
                                },
                                _vm.$v.credentials.password.$touch
                              ],
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("woot-submit-button", {
                        attrs: {
                          disabled:
                            _vm.$v.credentials.email.$invalid ||
                            _vm.$v.credentials.password.$invalid ||
                            _vm.loginApi.showLoading,
                          "button-text": _vm.$t("LOGIN.SUBMIT"),
                          loading: _vm.loginApi.showLoading,
                          "button-class": "large expanded"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text-center column sigin__footer" }, [
                !_vm.globalConfig.disableUserProfileUpdate
                  ? _c(
                      "p",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "auth/reset/password" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("LOGIN.FORGOT_PASSWORD")) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSignupLink()
                  ? _c(
                      "p",
                      [
                        _c("router-link", { attrs: { to: "auth/signup" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("LOGIN.CREATE_NEW_ACCOUNT")) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          : _c("woot-spinner", { attrs: { size: "" } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }