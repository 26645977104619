var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-reports", {
    key: "inbox-reports",
    attrs: {
      type: "inbox",
      "getter-key": "inboxes/getInboxes",
      "action-key": "inboxes/get",
      "download-button-label": _vm.$t("INBOX_REPORTS.DOWNLOAD_INBOX_REPORTS")
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }