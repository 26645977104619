var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-body columns content-box small-9" }, [
    _c(
      "form",
      {
        staticClass: "row",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.addAgents()
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "medium-12 columns" },
          [
            _c("page-header", {
              attrs: {
                "header-title": _vm.$t("INBOX_MGMT.ADD.AGENTS.TITLE"),
                "header-content": _vm.$t("INBOX_MGMT.ADD.AGENTS.DESC")
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "medium-7 columns" }, [
          _c("div", { staticClass: "medium-12 columns" }, [
            _c(
              "label",
              { class: { error: _vm.$v.selectedAgents.$error } },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("INBOX_MGMT.ADD.AGENTS.TITLE")) +
                    "\n          "
                ),
                _c("multiselect", {
                  attrs: {
                    options: _vm.agentList,
                    "track-by": "id",
                    label: "name",
                    multiple: true,
                    "close-on-select": false,
                    "clear-on-select": false,
                    "hide-selected": true,
                    "selected-label": "",
                    "select-label": _vm.$t("FORMS.MULTISELECT.ENTER_TO_SELECT"),
                    "deselect-label": _vm.$t(
                      "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                    ),
                    placeholder: _vm.$t("INBOX_MGMT.ADD.AGENTS.PICK_AGENTS")
                  },
                  on: { select: _vm.$v.selectedAgents.$touch },
                  model: {
                    value: _vm.selectedAgents,
                    callback: function($$v) {
                      _vm.selectedAgents = $$v
                    },
                    expression: "selectedAgents"
                  }
                }),
                _vm._v(" "),
                _vm.$v.selectedAgents.$error
                  ? _c("span", { staticClass: "message" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("INBOX_MGMT.ADD.AGENTS.VALIDATION_ERROR")
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-submit-button", {
                attrs: {
                  "button-text": _vm.$t("INBOX_MGMT.AGENTS.BUTTON_TEXT"),
                  loading: _vm.isCreating
                }
              })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }