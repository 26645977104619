var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings--content" },
    [
      _vm.uiFlags.isFetching || _vm.uiFlags.isFetchingAgentBot
        ? _c("loading-state")
        : _c(
            "form",
            {
              staticClass: "row",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.updateActiveAgentBot($event)
                }
              }
            },
            [
              _c(
                "settings-section",
                {
                  attrs: {
                    title: _vm.$t("AGENT_BOTS.BOT_CONFIGURATION.TITLE"),
                    "sub-title": _vm.$t("AGENT_BOTS.BOT_CONFIGURATION.DESC")
                  }
                },
                [
                  _c("div", { staticClass: "medium-7 columns" }, [
                    _c("label", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedAgentBotId,
                              expression: "selectedAgentBotId"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedAgentBotId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { value: "", disabled: "", selected: "" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "AGENT_BOTS.BOT_CONFIGURATION.SELECT_PLACEHOLDER"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.agentBots, function(agentBot) {
                            return _c(
                              "option",
                              {
                                key: agentBot.id,
                                domProps: { value: agentBot.id }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(agentBot.name) +
                                    "\n            "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "button-container" },
                      [
                        _c("woot-submit-button", {
                          attrs: {
                            "button-text": _vm.$t(
                              "AGENT_BOTS.BOT_CONFIGURATION.SUBMIT"
                            ),
                            loading: _vm.uiFlags.isSettingAgentBot
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "woot-button",
                          {
                            staticClass: "button--disconnect",
                            attrs: {
                              type: "button",
                              disabled: !_vm.selectedAgentBotId,
                              loading: _vm.uiFlags.isDisconnecting,
                              variant: "smooth",
                              "color-scheme": "alert"
                            },
                            on: { click: _vm.disconnectBot }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "AGENT_BOTS.BOT_CONFIGURATION.DISCONNECT"
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }