var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.EDIT.TITLE")
        }
      }),
      _vm._v(" "),
      _c("webhook-form", {
        attrs: {
          value: _vm.value,
          "is-submitting": _vm.uiFlags.updatingItem,
          "submit-label": _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.FORM.EDIT_SUBMIT"
          )
        },
        on: { submit: _vm.onSubmit, cancel: _vm.onClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }