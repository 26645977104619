var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-attributes--panel" },
    _vm._l(_vm.filteredAttributes, function(attribute) {
      return _c("custom-attribute", {
        key: attribute.id,
        class: _vm.attributeClass,
        attrs: {
          "attribute-key": attribute.attribute_key,
          "attribute-type": attribute.attribute_display_type,
          values: attribute.attribute_values,
          label: attribute.attribute_display_name,
          icon: attribute.icon,
          emoji: "",
          value: attribute.value,
          "show-actions": true
        },
        on: { update: _vm.onUpdate, delete: _vm.onDelete, copy: _vm.onCopy }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }