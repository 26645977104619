var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "widget-preview-container" }, [
    _vm.isWidgetVisible
      ? _c(
          "div",
          { staticClass: "screen-selector" },
          [
            _c("input-radio-group", {
              attrs: {
                name: "widget-screen",
                items: _vm.widgetScreens,
                action: _vm.handleScreenChange
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isWidgetVisible
      ? _c(
          "div",
          { staticClass: "widget-wrapper" },
          [
            _c("WidgetHead", { attrs: { config: _vm.getWidgetHeadConfig } }),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("WidgetBody", {
                  attrs: { config: _vm.getWidgetBodyConfig }
                }),
                _vm._v(" "),
                _c("WidgetFooter", {
                  attrs: { config: _vm.getWidgetFooterConfig }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "branding" }, [
                  _c("a", { staticClass: "branding-link" }, [
                    _c("img", {
                      staticClass: "branding-image",
                      attrs: { src: _vm.globalConfig.logoThumbnail }
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.useInstallationName(
                              _vm.$t("INBOX_MGMT.WIDGET_BUILDER.BRANDING_TEXT"),
                              _vm.globalConfig.installationName
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "widget-bubble", style: _vm.getBubblePositionStyle },
      [
        _c(
          "button",
          {
            staticClass: "bubble",
            class: _vm.getBubbleTypeClass,
            style: { background: _vm.color },
            on: { click: _vm.toggleWidget }
          },
          [
            !_vm.isWidgetVisible
              ? _c("img", {
                  attrs: {
                    src: require("dashboard/assets/images/bubble-logo.svg"),
                    alt: ""
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.getWidgetBubbleLauncherTitle) +
                  "\n      "
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }