var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-body small-9 columns" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.LINE_CHANNEL.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.LINE_CHANNEL.DESC")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.createChannel()
            }
          }
        },
        [
          _c("div", { staticClass: "medium-8 columns" }, [
            _c("label", { class: { error: _vm.$v.channelName.$error } }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("INBOX_MGMT.ADD.LINE_CHANNEL.CHANNEL_NAME.LABEL")
                  ) +
                  "\n        "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.channelName,
                    expression: "channelName",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.LINE_CHANNEL.CHANNEL_NAME.PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.channelName },
                on: {
                  blur: [
                    _vm.$v.channelName.$touch,
                    function($event) {
                      return _vm.$forceUpdate()
                    }
                  ],
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.channelName = $event.target.value.trim()
                  }
                }
              }),
              _vm._v(" "),
              _vm.$v.channelName.$error
                ? _c("span", { staticClass: "message" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("INBOX_MGMT.ADD.LINE_CHANNEL.CHANNEL_NAME.ERROR")
                      )
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "medium-8 columns" }, [
            _c("label", { class: { error: _vm.$v.lineChannelId.$error } }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("INBOX_MGMT.ADD.LINE_CHANNEL.LINE_CHANNEL_ID.LABEL")
                  ) +
                  "\n        "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.lineChannelId,
                    expression: "lineChannelId",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.LINE_CHANNEL.LINE_CHANNEL_ID.PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.lineChannelId },
                on: {
                  blur: [
                    _vm.$v.lineChannelId.$touch,
                    function($event) {
                      return _vm.$forceUpdate()
                    }
                  ],
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.lineChannelId = $event.target.value.trim()
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "medium-8 columns" }, [
            _c("label", { class: { error: _vm.$v.lineChannelSecret.$error } }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "INBOX_MGMT.ADD.LINE_CHANNEL.LINE_CHANNEL_SECRET.LABEL"
                    )
                  ) +
                  "\n        "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.lineChannelSecret,
                    expression: "lineChannelSecret",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.LINE_CHANNEL.LINE_CHANNEL_SECRET.PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.lineChannelSecret },
                on: {
                  blur: [
                    _vm.$v.lineChannelSecret.$touch,
                    function($event) {
                      return _vm.$forceUpdate()
                    }
                  ],
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.lineChannelSecret = $event.target.value.trim()
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "medium-8 columns" }, [
            _c("label", { class: { error: _vm.$v.lineChannelToken.$error } }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "INBOX_MGMT.ADD.LINE_CHANNEL.LINE_CHANNEL_TOKEN.LABEL"
                    )
                  ) +
                  "\n        "
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.lineChannelToken,
                    expression: "lineChannelToken",
                    modifiers: { trim: true }
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$t(
                    "INBOX_MGMT.ADD.LINE_CHANNEL.LINE_CHANNEL_TOKEN.PLACEHOLDER"
                  )
                },
                domProps: { value: _vm.lineChannelToken },
                on: {
                  blur: [
                    _vm.$v.lineChannelToken.$touch,
                    function($event) {
                      return _vm.$forceUpdate()
                    }
                  ],
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.lineChannelToken = $event.target.value.trim()
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-submit-button", {
                attrs: {
                  loading: _vm.uiFlags.isCreating,
                  "button-text": _vm.$t(
                    "INBOX_MGMT.ADD.LINE_CHANNEL.SUBMIT_BUTTON"
                  )
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }