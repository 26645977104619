var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "add-agents__header" }),
    _vm._v(" "),
    _c("table", { staticClass: "woot-table" }, [
      _c("thead", [
        _c("tr", [
          _c("td", [
            _c("div", { staticClass: "checkbox-wrap" }, [
              _c("input", {
                attrs: {
                  name: "select-all-agents",
                  type: "checkbox",
                  title: _vm.$t("TEAMS_SETTINGS.AGENTS.SELECT_ALL")
                },
                domProps: { checked: _vm.allAgentsSelected ? "checked" : "" },
                on: {
                  click: function($event) {
                    if ($event.target !== $event.currentTarget) {
                      return null
                    }
                    return _vm.selectAllAgents($event)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.$t("TEAMS_SETTINGS.AGENTS.AGENT")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.$t("TEAMS_SETTINGS.AGENTS.EMAIL")))])
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.agentList, function(agent) {
          return _c(
            "tr",
            { key: agent.id, class: _vm.agentRowClass(agent.id) },
            [
              _c("td", { staticClass: "checkbox-cell" }, [
                _c("div", { staticClass: "checkbox-wrap" }, [
                  _c("input", {
                    attrs: { type: "checkbox" },
                    domProps: { checked: _vm.isAgentSelected(agent.id) },
                    on: {
                      click: function($event) {
                        if ($event.target !== $event.currentTarget) {
                          return null
                        }
                        return (function() {
                          return _vm.handleSelectAgent(agent.id)
                        })($event)
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "div",
                  { staticClass: "user-info-wrap" },
                  [
                    _c("thumbnail", {
                      attrs: {
                        src: agent.thumbnail,
                        size: "24px",
                        username: agent.name,
                        status: agent.availability_status
                      }
                    }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "sub-block-title user-name" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(agent.name) +
                          "\n            "
                      )
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n          " + _vm._s(agent.email || "---") + "\n        "
                )
              ])
            ]
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "add-agents__footer" },
      [
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("TEAMS_SETTINGS.AGENTS.SELECTED_COUNT", {
                  selected: _vm.selectedAgents.length,
                  total: _vm.agentList.length
                })
              ) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("woot-submit-button", {
          attrs: {
            "button-text": _vm.submitButtonText,
            loading: _vm.isWorking,
            disabled: _vm.disableSubmitButton
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }