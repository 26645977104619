var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.integration.name,
          "header-content": _vm.integration.description
        }
      }),
      _vm._v(" "),
      _c("formulate-form", {
        on: { submit: _vm.submitForm },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hasErrors = ref.hasErrors
              return [
                _vm._l(_vm.formItems, function(item) {
                  return _c(
                    "formulate-input",
                    _vm._b({ key: item.name }, "formulate-input", item, false)
                  )
                }),
                _vm._v(" "),
                _vm.isHookTypeInbox
                  ? _c("formulate-input", {
                      attrs: {
                        options: _vm.inboxes,
                        type: "select",
                        name: "inbox",
                        placeholder: _vm.$t(
                          "INTEGRATION_APPS.ADD.FORM.INBOX.LABEL"
                        ),
                        label: _vm.$t(
                          "INTEGRATION_APPS.ADD.FORM.INBOX.PLACEHOLDER"
                        ),
                        validation: "required",
                        "validation-name": "Inbox"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-footer" },
                  [
                    _c(
                      "woot-button",
                      {
                        attrs: {
                          disabled: hasErrors,
                          loading: _vm.uiFlags.isCreatingHook
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("INTEGRATION_APPS.ADD.FORM.SUBMIT")) +
                            "\n      "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "woot-button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClose($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("INTEGRATION_APPS.ADD.FORM.CANCEL")) +
                            "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.values,
          callback: function($$v) {
            _vm.values = $$v
          },
          expression: "values"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }