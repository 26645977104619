var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manage-subscription" }, [
    _c("div", [
      _c("h6", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("p", { staticClass: "manage-subscription--description" }, [
        _vm._v("\n      " + _vm._s(_vm.description) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "woot-button",
          {
            attrs: { variant: "smooth", icon: _vm.buttonIcon },
            on: {
              click: function($event) {
                return _vm.$emit("click")
              }
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.buttonLabel) + "\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }