var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agent-table-container" },
    [
      _c("ve-table", {
        attrs: {
          "max-height": "calc(100vh - 35rem)",
          "fixed-header": true,
          columns: _vm.columns,
          "table-data": _vm.tableData
        }
      }),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "agents-loader" },
            [
              _c("spinner"),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "OVERVIEW_REPORTS.AGENT_CONVERSATIONS.LOADING_MESSAGE"
                    )
                  )
                )
              ])
            ],
            1
          )
        : !_vm.isLoading && !_vm.agentMetrics.length
        ? _c("empty-state", {
            attrs: {
              title: _vm.$t("OVERVIEW_REPORTS.AGENT_CONVERSATIONS.NO_AGENTS")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.agentMetrics.length > 0
        ? _c(
            "div",
            { staticClass: "table-pagination" },
            [
              _c("ve-pagination", {
                attrs: {
                  total: _vm.agents.length,
                  "page-index": _vm.pageIndex,
                  "page-size": 25,
                  "page-size-option": [25]
                },
                on: { "on-page-number-change": _vm.onPageNumberChange }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }