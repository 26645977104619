var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    { attrs: { show: _vm.show, "on-close": _vm.closeModal } },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", { attrs: { "header-title": _vm.header } }),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "row",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                }
              }
            },
            [
              _c("woot-input", {
                staticClass: "medium-12 columns",
                class: { error: _vm.$v.app.title.$error },
                attrs: {
                  label: _vm.$t(
                    "INTEGRATION_SETTINGS.DASHBOARD_APPS.FORM.TITLE_LABEL"
                  ),
                  placeholder: _vm.$t(
                    "INTEGRATION_SETTINGS.DASHBOARD_APPS.FORM.TITLE_PLACEHOLDER"
                  ),
                  error: _vm.$v.app.title.$error
                    ? _vm.$t(
                        "INTEGRATION_SETTINGS.DASHBOARD_APPS.FORM.TITLE_ERROR"
                      )
                    : null,
                  "data-testid": "app-title"
                },
                on: { input: _vm.$v.app.title.$touch },
                model: {
                  value: _vm.app.title,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.app,
                      "title",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "app.title"
                }
              }),
              _vm._v(" "),
              _c("woot-input", {
                staticClass: "medium-12 columns app--url_input",
                class: { error: _vm.$v.app.content.url.$error },
                attrs: {
                  label: _vm.$t(
                    "INTEGRATION_SETTINGS.DASHBOARD_APPS.FORM.URL_LABEL"
                  ),
                  placeholder: _vm.$t(
                    "INTEGRATION_SETTINGS.DASHBOARD_APPS.FORM.URL_PLACEHOLDER"
                  ),
                  error: _vm.$v.app.content.url.$error
                    ? _vm.$t(
                        "INTEGRATION_SETTINGS.DASHBOARD_APPS.FORM.URL_ERROR"
                      )
                    : null,
                  "data-testid": "app-url"
                },
                on: { input: _vm.$v.app.content.url.$touch },
                model: {
                  value: _vm.app.content.url,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.app.content,
                      "url",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "app.content.url"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "div",
                  { staticClass: "medium-12 columns" },
                  [
                    _c(
                      "woot-button",
                      {
                        attrs: {
                          "is-loading": _vm.isLoading,
                          "is-disabled": _vm.$v.$invalid,
                          "data-testid": "label-submit"
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.submitButtonLabel) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "woot-button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.closeModal($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "INTEGRATION_SETTINGS.DASHBOARD_APPS.CREATE.FORM_CANCEL"
                              )
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }