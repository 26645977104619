var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldShowBanner
    ? _c("banner", {
        staticClass: "update-banner",
        attrs: {
          "color-scheme": "primary",
          "banner-message": _vm.bannerMessage,
          "href-link": "https://github.com/chatwoot/chatwoot/releases",
          "href-link-text": _vm.$t("GENERAL_SETTINGS.LEARN_MORE"),
          "has-close-button": ""
        },
        on: { close: _vm.dismissUpdateBanner }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }