var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bulk-action__agents" }, [
    _c("div", { staticClass: "triangle", style: _vm.cssVars }, [
      _c(
        "svg",
        { attrs: { height: "12", viewBox: "0 0 24 12", width: "24" } },
        [
          _c("path", {
            attrs: {
              d: "M20 12l-8-8-12 12",
              fill: "var(--white)",
              "fill-rule": "evenodd",
              stroke: "var(--s-50)",
              "stroke-width": "1px"
            }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "header flex-between" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("BULK_ACTION.AGENT_SELECT_LABEL")))]),
        _vm._v(" "),
        _c("woot-button", {
          attrs: {
            size: "tiny",
            variant: "clear",
            "color-scheme": "secondary",
            icon: "dismiss"
          },
          on: { click: _vm.onClose }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _vm.assignableAgentsUiFlags.isFetching
        ? _c(
            "div",
            { staticClass: "agent__list-loading" },
            [
              _c("spinner"),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("BULK_ACTION.AGENT_LIST_LOADING")))
              ])
            ],
            1
          )
        : _c("div", { staticClass: "agent__list-container" }, [
            !_vm.selectedAgent
              ? _c(
                  "ul",
                  [
                    _c("li", { staticClass: "search-container" }, [
                      _c(
                        "div",
                        { staticClass: "agent-list-search flex-between" },
                        [
                          _c("fluent-icon", {
                            staticClass: "search-icon",
                            attrs: { icon: "search", size: "16" }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.query,
                                expression: "query"
                              }
                            ],
                            ref: "search",
                            staticClass: "agent--search_input",
                            attrs: { type: "search", placeholder: "Search" },
                            domProps: { value: _vm.query },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.query = $event.target.value
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.filteredAgents, function(agent) {
                      return _c("li", { key: agent.id }, [
                        _c(
                          "div",
                          {
                            staticClass: "agent-list-item",
                            on: {
                              click: function($event) {
                                return _vm.assignAgent(agent)
                              }
                            }
                          },
                          [
                            _c("thumbnail", {
                              staticClass: "margin-right-small",
                              attrs: {
                                src: agent.thumbnail,
                                status: agent.availability_status,
                                username: agent.name,
                                size: "22px"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "reports-option__title" },
                              [_vm._v(_vm._s(agent.name))]
                            )
                          ],
                          1
                        )
                      ])
                    })
                  ],
                  2
                )
              : _c("div", { staticClass: "agent-confirmation-container" }, [
                  _vm.selectedAgent.id
                    ? _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("BULK_ACTION.ASSIGN_CONFIRMATION_LABEL", {
                                conversationCount: _vm.conversationCount,
                                conversationLabel: _vm.conversationLabel
                              })
                            ) +
                            "\n          "
                        ),
                        _c("strong", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.selectedAgent.name) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v("?")])
                      ])
                    : _c("p", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "BULK_ACTION.UNASSIGN_CONFIRMATION_LABEL",
                                {
                                  conversationCount: _vm.conversationCount,
                                  conversationLabel: _vm.conversationLabel
                                }
                              )
                            ) +
                            "\n        "
                        )
                      ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "agent-confirmation-actions" },
                    [
                      _c(
                        "woot-button",
                        {
                          attrs: {
                            "color-scheme": "primary",
                            variant: "smooth"
                          },
                          on: { click: _vm.goBack }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("BULK_ACTION.GO_BACK_LABEL")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "woot-button",
                        {
                          attrs: {
                            "color-scheme": "primary",
                            variant: "flat",
                            "is-loading": _vm.uiFlags.isUpdating
                          },
                          on: { click: _vm.submit }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("BULK_ACTION.YES")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }