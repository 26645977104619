var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings--content" },
    [
      _c(
        "settings-section",
        {
          attrs: {
            title: _vm.$t("INBOX_MGMT.IMAP.TITLE"),
            "sub-title": _vm.$t("INBOX_MGMT.IMAP.SUBTITLE"),
            note: _vm.$t("INBOX_MGMT.IMAP.NOTE_TEXT")
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.updateInbox($event)
                }
              }
            },
            [
              _c("label", { attrs: { for: "toggle-imap-enable" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isIMAPEnabled,
                      expression: "isIMAPEnabled"
                    }
                  ],
                  attrs: { type: "checkbox", name: "toggle-imap-enable" },
                  domProps: {
                    checked: Array.isArray(_vm.isIMAPEnabled)
                      ? _vm._i(_vm.isIMAPEnabled, null) > -1
                      : _vm.isIMAPEnabled
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.isIMAPEnabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isIMAPEnabled = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isIMAPEnabled = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isIMAPEnabled = $$c
                      }
                    }
                  }
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("INBOX_MGMT.IMAP.TOGGLE_AVAILABILITY")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.$t("INBOX_MGMT.IMAP.TOGGLE_HELP")))]),
              _vm._v(" "),
              _vm.isIMAPEnabled
                ? _c(
                    "div",
                    { staticClass: "imap-details-wrap" },
                    [
                      _c("woot-input", {
                        staticClass: "medium-9 columns",
                        class: { error: _vm.$v.address.$error },
                        attrs: {
                          label: _vm.$t("INBOX_MGMT.IMAP.ADDRESS.LABEL"),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.IMAP.ADDRESS.PLACE_HOLDER"
                          )
                        },
                        on: { blur: _vm.$v.address.$touch },
                        model: {
                          value: _vm.address,
                          callback: function($$v) {
                            _vm.address =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "address"
                        }
                      }),
                      _vm._v(" "),
                      _c("woot-input", {
                        staticClass: "medium-9 columns",
                        class: { error: _vm.$v.port.$error },
                        attrs: {
                          type: "number",
                          label: _vm.$t("INBOX_MGMT.IMAP.PORT.LABEL"),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.IMAP.PORT.PLACE_HOLDER"
                          )
                        },
                        on: { blur: _vm.$v.port.$touch },
                        model: {
                          value: _vm.port,
                          callback: function($$v) {
                            _vm.port = $$v
                          },
                          expression: "port"
                        }
                      }),
                      _vm._v(" "),
                      _c("woot-input", {
                        staticClass: "medium-9 columns",
                        class: { error: _vm.$v.login.$error },
                        attrs: {
                          label: _vm.$t("INBOX_MGMT.IMAP.LOGIN.LABEL"),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.IMAP.LOGIN.PLACE_HOLDER"
                          )
                        },
                        on: { blur: _vm.$v.login.$touch },
                        model: {
                          value: _vm.login,
                          callback: function($$v) {
                            _vm.login = $$v
                          },
                          expression: "login"
                        }
                      }),
                      _vm._v(" "),
                      _c("woot-input", {
                        staticClass: "medium-9 columns",
                        class: { error: _vm.$v.password.$error },
                        attrs: {
                          label: _vm.$t("INBOX_MGMT.IMAP.PASSWORD.LABEL"),
                          placeholder: _vm.$t(
                            "INBOX_MGMT.IMAP.PASSWORD.PLACE_HOLDER"
                          ),
                          type: "password"
                        },
                        on: { blur: _vm.$v.password.$touch },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "toggle-enable-ssl" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.isSSLEnabled,
                              expression: "isSSLEnabled"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            name: "toggle-enable-ssl"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.isSSLEnabled)
                              ? _vm._i(_vm.isSSLEnabled, null) > -1
                              : _vm.isSSLEnabled
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.isSSLEnabled,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.isSSLEnabled = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.isSSLEnabled = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.isSSLEnabled = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("INBOX_MGMT.IMAP.ENABLE_SSL")) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("woot-submit-button", {
                attrs: {
                  "button-text": _vm.$t("INBOX_MGMT.IMAP.UPDATE"),
                  loading: _vm.uiFlags.isUpdatingIMAP,
                  disabled:
                    (_vm.$v.$invalid && _vm.isIMAPEnabled) ||
                    _vm.uiFlags.isUpdatingIMAP
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }