var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-right-top",
            icon: "add-circle"
          },
          on: {
            click: function($event) {
              return _vm.openAddPopup()
            }
          }
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("ATTRIBUTES_MGMT.HEADER_BTN_TXT")) + "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("custom-attribute"),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showAddPopup, "on-close": _vm.hideAddPopup },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [_c("add-attribute", { attrs: { "on-close": _vm.hideAddPopup } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }