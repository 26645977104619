var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showSeparator
      ? _c("div", { staticClass: "separator" }, [_vm._v("\n    OR\n  ")])
      : _vm._e(),
    _vm._v(" "),
    _c("a", { attrs: { href: _vm.getGoogleAuthUrl() } }, [
      _c(
        "button",
        {
          staticClass: "button expanded button__google_login",
          class: {
            // Explicit checking to ensure no other value is used
            large: _vm.buttonSize === "large",
            small: _vm.buttonSize === "small",
            tiny: _vm.buttonSize === "tiny"
          }
        },
        [
          _c("img", {
            staticClass: "icon",
            attrs: { src: "/assets/images/auth/google.svg", alt: "Google Logo" }
          }),
          _vm._v(" "),
          _vm._t("default", [
            _vm._v(_vm._s(_vm.$t("LOGIN.OAUTH.GOOGLE_LOGIN")))
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }