var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "dropdown-menu__item",
      class: {
        "is-disabled": _vm.disabled
      },
      attrs: {
        tabindex: _vm.disabled ? null : -1,
        "aria-disabled": _vm.disabled
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }