var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conv-header" }, [
    _c("div", { staticClass: "conversation-header--details" }, [
      _c(
        "div",
        { staticClass: "user" },
        [
          _vm.showBackButton
            ? _c("back-button", { attrs: { "back-url": _vm.backButtonUrl } })
            : _vm._e(),
          _vm._v(" "),
          _c("Thumbnail", {
            attrs: {
              src: _vm.currentContact.thumbnail,
              badge: _vm.inboxBadge,
              username: _vm.currentContact.name,
              status: _vm.currentContact.availability_status
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "user--profile__meta" }, [
            _c(
              "h3",
              { staticClass: "user--name text-truncate" },
              [
                _c("span", { staticClass: "margin-right-smaller" }, [
                  _vm._v(_vm._s(_vm.currentContact.name))
                ]),
                _vm._v(" "),
                !_vm.isHMACVerified
                  ? _c("fluent-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t("CONVERSATION.UNVERIFIED_SESSION"),
                          expression: "$t('CONVERSATION.UNVERIFIED_SESSION')"
                        }
                      ],
                      staticClass: "hmac-warning__icon",
                      attrs: { size: "14", icon: "warning" }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "conversation--header--actions text-truncate" },
              [
                _vm.hasMultipleInboxes
                  ? _c("inbox-name", {
                      staticClass: "margin-right-small",
                      attrs: { inbox: _vm.inbox }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isSnoozed
                  ? _c(
                      "span",
                      {
                        staticClass: "snoozed--display-text margin-right-small"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.snoozedDisplayText) +
                            "\n          "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "woot-button",
                  {
                    staticClass: "user--profile__button margin-right-small",
                    attrs: { size: "small", variant: "link" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("contact-panel-toggle")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.contactPanelToggleText) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "header-actions-wrap",
          class: { "has-open-sidebar": _vm.isContactPanelOpen }
        },
        [
          _c("more-actions", {
            attrs: { "conversation-id": _vm.currentChat.id }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }