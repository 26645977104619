var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.updateSignature()
        }
      }
    },
    [
      _c("div", { staticClass: "profile--settings--row row" }, [
        _c("div", { staticClass: "columns small-3" }, [
          _c("h4", { staticClass: "block-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.$t(
                    "PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.TITLE"
                  )
                ) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.$t("PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.NOTE")
              )
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "columns small-9 medium-5" },
          [
            _c(
              "div",
              [
                _c("label", { attrs: { for: "message-signature-input" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE.LABEL")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("woot-message-editor", {
                  staticClass: "message-editor",
                  attrs: {
                    id: "message-signature-input",
                    "is-format-mode": true,
                    placeholder: _vm.$t(
                      "PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE.PLACEHOLDER"
                    )
                  },
                  on: { blur: _vm.$v.messageSignature.$touch },
                  model: {
                    value: _vm.messageSignature,
                    callback: function($$v) {
                      _vm.messageSignature = $$v
                    },
                    expression: "messageSignature"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "woot-button",
              {
                attrs: {
                  "is-loading": _vm.isUpdating,
                  type: "submit",
                  "is-disabled": _vm.$v.messageSignature.$invalid
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.BTN_TEXT"
                      )
                    ) +
                    "\n      "
                )
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }