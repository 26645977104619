var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-body columns content-box small-9" },
    [
      _c("settings-sub-page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.MICROSOFT.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.MICROSOFT.DESCRIPTION")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "microsoft--sign-in-form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.requestAuthorization($event)
            }
          }
        },
        [
          _c("woot-input", {
            attrs: {
              type: "text",
              placeholder: _vm.$t("INBOX_MGMT.ADD.MICROSOFT.EMAIL_PLACEHOLDER")
            },
            on: { blur: _vm.$v.email.$touch },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "email"
            }
          }),
          _vm._v(" "),
          _c("woot-submit-button", {
            attrs: {
              icon: "brand-twitter",
              "button-text": "Sign in with Microsoft",
              type: "submit",
              loading: _vm.isRequestingAuthorization
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }