var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "small-12 medium-8 columns" }, [
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit($event)
            }
          }
        },
        [
          _c("woot-input", {
            staticClass: "medium-12 columns",
            class: { error: _vm.$v.title.$error },
            attrs: {
              label: _vm.$t("TEAMS_SETTINGS.FORM.NAME.LABEL"),
              placeholder: _vm.$t("TEAMS_SETTINGS.FORM.NAME.PLACEHOLDER")
            },
            on: { input: _vm.$v.title.$touch },
            model: {
              value: _vm.title,
              callback: function($$v) {
                _vm.title = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "title"
            }
          }),
          _vm._v(" "),
          _c("woot-input", {
            staticClass: "medium-12 columns",
            class: { error: _vm.$v.description.$error },
            attrs: {
              label: _vm.$t("TEAMS_SETTINGS.FORM.DESCRIPTION.LABEL"),
              placeholder: _vm.$t("TEAMS_SETTINGS.FORM.DESCRIPTION.PLACEHOLDER")
            },
            on: { input: _vm.$v.description.$touch },
            model: {
              value: _vm.description,
              callback: function($$v) {
                _vm.description = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "description"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "medium-12" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.allowAutoAssign,
                  expression: "allowAutoAssign"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                value: true,
                checked: Array.isArray(_vm.allowAutoAssign)
                  ? _vm._i(_vm.allowAutoAssign, true) > -1
                  : _vm.allowAutoAssign
              },
              on: {
                change: function($event) {
                  var $$a = _vm.allowAutoAssign,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = true,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.allowAutoAssign = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.allowAutoAssign = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.allowAutoAssign = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "conversation_creation" } }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("TEAMS_SETTINGS.FORM.AUTO_ASSIGN.LABEL")) +
                  "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "div",
              { staticClass: "medium-12 columns" },
              [
                _c("woot-submit-button", {
                  attrs: {
                    disabled: _vm.$v.title.$invalid || _vm.submitInProgress,
                    "button-text": _vm.submitButtonText,
                    loading: _vm.submitInProgress
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }