var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logo" },
    [
      _c("router-link", { attrs: { to: _vm.dashboardPath, replace: "" } }, [
        _c("img", { attrs: { src: _vm.source, alt: _vm.name } })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }