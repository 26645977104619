var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-info--row" }, [
    _vm.href
      ? _c(
          "a",
          { staticClass: "contact-info--details", attrs: { href: _vm.href } },
          [
            _c("emoji-or-icon", {
              attrs: { icon: _vm.icon, emoji: _vm.emoji, "icon-size": "14" }
            }),
            _vm._v(" "),
            _vm.value
              ? _c(
                  "span",
                  { staticClass: "text-truncate", attrs: { title: _vm.value } },
                  [_vm._v(_vm._s(_vm.value))]
                )
              : _c("span", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("CONTACT_PANEL.NOT_AVAILABLE")))
                ]),
            _vm._v(" "),
            _vm.showCopy
              ? _c("woot-button", {
                  attrs: {
                    type: "submit",
                    variant: "clear",
                    size: "tiny",
                    "color-scheme": "secondary",
                    icon: "clipboard",
                    "class-names": "copy-icon"
                  },
                  on: { click: _vm.onCopy }
                })
              : _vm._e()
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "contact-info--details" },
          [
            _c("emoji-or-icon", {
              attrs: { icon: _vm.icon, emoji: _vm.emoji, "icon-size": "14" }
            }),
            _vm._v(" "),
            _vm.value
              ? _c("span", { staticClass: "text-truncate" }, [
                  _vm._v(_vm._s(_vm.value))
                ])
              : _c("span", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.$t("CONTACT_PANEL.NOT_AVAILABLE")))
                ])
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }