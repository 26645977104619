var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("h5", [_vm._v(_vm._s(_vm.header))]),
      _vm._v(" "),
      _c("span", { staticClass: "live" }, [
        _c("span", { staticClass: "ellipse" }),
        _c("span", [_vm._v(_vm._s(_vm.$t("OVERVIEW_REPORTS.LIVE")))])
      ])
    ]),
    _vm._v(" "),
    !_vm.isLoading
      ? _c("div", { staticClass: "card-body row" }, [_vm._t("default")], 2)
      : _vm.isLoading
      ? _c(
          "div",
          { staticClass: "conversation-metric-loader" },
          [
            _c("spinner"),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.loadingMessage))])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }