var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-body columns content-box small-9" },
    [
      _c(
        "empty-state",
        {
          attrs: {
            title: _vm.$t("TEAMS_SETTINGS.FINISH.TITLE"),
            message: _vm.$t("TEAMS_SETTINGS.FINISH.MESSAGE"),
            "button-text": _vm.$t("TEAMS_SETTINGS.FINISH.BUTTON_TEXT")
          }
        },
        [
          _c(
            "div",
            { staticClass: "medium-12 columns text-center" },
            [
              _c(
                "router-link",
                {
                  staticClass: "button success nice",
                  attrs: {
                    to: {
                      name: "settings_teams_list"
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("TEAMS_SETTINGS.FINISH.BUTTON_TEXT")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }