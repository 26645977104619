var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "medium-2 small-6 csat--metric-card" }, [
    _c(
      "h3",
      { staticClass: "heading" },
      [
        _c("span", [_vm._v(_vm._s(_vm.label))]),
        _vm._v(" "),
        _c("fluent-icon", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.infoText,
              expression: "infoText"
            }
          ],
          staticClass: "csat--icon",
          attrs: { size: "14", icon: "info" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("h4", { staticClass: "metric" }, [
      _vm._v("\n    " + _vm._s(_vm.value) + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }