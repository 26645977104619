var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-wrapper" }, [
    _c("div", { staticClass: "header-branding" }, [
      _c("div", { staticClass: "header" }, [
        _vm.config.logo
          ? _c("img", {
              staticClass: "logo",
              class: { small: !_vm.isDefaultScreen },
              attrs: { src: _vm.config.logo }
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.isDefaultScreen
          ? _c("div", [
              _c("div", { staticClass: "title-block" }, [
                _c("span", [_vm._v(_vm._s(_vm.config.websiteName))]),
                _vm._v(" "),
                _vm.config.isOnline
                  ? _c("div", { staticClass: "online-dot" })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.config.replyTime))])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.isDefaultScreen
        ? _c("div", { staticClass: "header-expanded" }, [
            _c("h2", [_vm._v(_vm._s(_vm.config.welcomeHeading))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.config.welcomeTagline))])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }