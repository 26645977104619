var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      staticClass: "account-selector--modal",
      attrs: {
        show: _vm.showAccountModal,
        "on-close": function() {
          return _vm.$emit("close-account-modal")
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("SIDEBAR_ITEMS.CHANGE_ACCOUNTS"),
          "header-content": _vm.$t("SIDEBAR_ITEMS.SELECTOR_SUBTITLE")
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "account-selector--wrap" },
        _vm._l(_vm.currentUser.accounts, function(account) {
          return _c(
            "div",
            { key: account.id, staticClass: "account-selector" },
            [
              _c(
                "button",
                {
                  staticClass: "button expanded clear link",
                  on: {
                    click: function($event) {
                      return _vm.onChangeAccount(account.id)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "button__content" }, [
                    _c(
                      "label",
                      {
                        staticClass: "account-details--wrap",
                        attrs: { for: account.name }
                      },
                      [
                        _c("div", { staticClass: "account--name" }, [
                          _vm._v(_vm._s(account.name))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "account--role" }, [
                          _vm._v(_vm._s(account.role))
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("fluent-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: account.id === _vm.accountId,
                        expression: "account.id === accountId"
                      }
                    ],
                    staticClass: "selected--account",
                    attrs: {
                      icon: "checkmark-circle",
                      type: "solid",
                      size: "24"
                    }
                  })
                ],
                1
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.globalConfig.createNewAccountFromDashboard
        ? _c("div", { staticClass: "modal-footer delete-item" }, [
            _c(
              "button",
              {
                staticClass: "button success large expanded nice",
                on: {
                  click: function($event) {
                    return _vm.$emit("show-create-account-modal")
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("CREATE_ACCOUNT.NEW_ACCOUNT")) +
                    "\n    "
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }