var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.onCancel },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "column content-box" },
        [
          _c("woot-modal-header", {
            attrs: {
              "header-title": _vm.$t("EMAIL_TRANSCRIPT.TITLE"),
              "header-content": _vm.$t("EMAIL_TRANSCRIPT.DESC")
            }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                }
              }
            },
            [
              _c("div", { staticClass: "medium-12 columns" }, [
                _vm.currentChat.meta.sender && _vm.currentChat.meta.sender.email
                  ? _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedType,
                            expression: "selectedType"
                          }
                        ],
                        attrs: {
                          id: "contact",
                          type: "radio",
                          name: "selectedType",
                          value: "contact"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedType, "contact")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedType = "contact"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "contact" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("EMAIL_TRANSCRIPT.FORM.SEND_TO_CONTACT")
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentChat.meta.assignee
                  ? _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedType,
                            expression: "selectedType"
                          }
                        ],
                        attrs: {
                          id: "assignee",
                          type: "radio",
                          name: "selectedType",
                          value: "assignee"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedType, "assignee")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedType = "assignee"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "assignee" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("EMAIL_TRANSCRIPT.FORM.SEND_TO_AGENT"))
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedType,
                        expression: "selectedType"
                      }
                    ],
                    attrs: {
                      id: "other_email_address",
                      type: "radio",
                      name: "selectedType",
                      value: "other_email_address"
                    },
                    domProps: {
                      checked: _vm._q(_vm.selectedType, "other_email_address")
                    },
                    on: {
                      change: function($event) {
                        _vm.selectedType = "other_email_address"
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "other_email_address" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "EMAIL_TRANSCRIPT.FORM.SEND_TO_OTHER_EMAIL_ADDRESS"
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.sentToOtherEmailAddress
                  ? _c("div", { staticClass: "medium-6 columns" }, [
                      _c("label", { class: { error: _vm.$v.email.$error } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.email,
                              expression: "email",
                              modifiers: { trim: true }
                            }
                          ],
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "EMAIL_TRANSCRIPT.FORM.EMAIL.PLACEHOLDER"
                            )
                          },
                          domProps: { value: _vm.email },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.email = $event.target.value.trim()
                              },
                              _vm.$v.email.$touch
                            ],
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.$v.email.$error
                          ? _c("span", { staticClass: "message" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("EMAIL_TRANSCRIPT.FORM.EMAIL.ERROR")
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "div",
                  { staticClass: "medium-12 row" },
                  [
                    _c("woot-submit-button", {
                      attrs: {
                        "button-text": _vm.$t("EMAIL_TRANSCRIPT.SUBMIT"),
                        disabled: !_vm.isFormValid
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button clear",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onCancel($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("EMAIL_TRANSCRIPT.CANCEL")) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }