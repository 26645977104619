var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showEmoji
    ? _c("i", { class: _vm.className }, [_vm._v(_vm._s(_vm.iconContent))])
    : _vm.showIcon
    ? _c("fluent-icon", {
        class: _vm.className,
        attrs: { size: _vm.iconSize, icon: _vm.icon }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }