var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "login-box medium-4 column align-self-middle",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit()
        }
      }
    },
    [
      _c("h4", [_vm._v(_vm._s(_vm.$t("RESET_PASSWORD.TITLE")))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column log-in-form" },
        [
          _c("label", { class: { error: _vm.$v.credentials.email.$error } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("RESET_PASSWORD.EMAIL.LABEL")) +
                "\n      "
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.credentials.email,
                  expression: "credentials.email",
                  modifiers: { trim: true }
                }
              ],
              attrs: {
                type: "text",
                placeholder: _vm.$t("RESET_PASSWORD.EMAIL.PLACEHOLDER")
              },
              domProps: { value: _vm.credentials.email },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.credentials,
                      "email",
                      $event.target.value.trim()
                    )
                  },
                  _vm.$v.credentials.email.$touch
                ],
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.$v.credentials.email.$error
              ? _c("span", { staticClass: "message" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("RESET_PASSWORD.EMAIL.ERROR")) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("woot-submit-button", {
            attrs: {
              disabled:
                _vm.$v.credentials.email.$invalid ||
                _vm.resetPassword.showLoading,
              "button-text": _vm.$t("RESET_PASSWORD.SUBMIT"),
              loading: _vm.resetPassword.showLoading,
              "button-class": "expanded"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }