var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.show, "on-close": _vm.closeModal },
      on: {
        "update:show": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("woot-modal-header", {
        attrs: { "header-title": _vm.title, "header-content": _vm.message }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onConfirm($event)
            }
          }
        },
        [
          _c("woot-input", {
            class: { error: _vm.$v.value.$error },
            attrs: { type: "text", placeholder: _vm.confirmPlaceHolderText },
            on: { blur: _vm.$v.value.$touch },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "button-wrapper" },
            [
              _c(
                "woot-button",
                {
                  attrs: {
                    "color-scheme": "alert",
                    "is-disabled": _vm.$v.value.$invalid
                  }
                },
                [_vm._v("\n        " + _vm._s(_vm.confirmText) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  staticClass: "clear",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.closeModal($event)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(_vm.rejectText) + "\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }