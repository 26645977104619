var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "conversation-page" },
    [
      _c(
        "chat-list",
        {
          attrs: {
            "show-conversation-list": _vm.showConversationList,
            "conversation-inbox": _vm.inboxId,
            label: _vm.label,
            "team-id": _vm.teamId,
            "conversation-type": _vm.conversationType,
            "folders-id": _vm.foldersId,
            "is-on-expanded-layout": _vm.isOnExpandedLayout
          },
          on: { "conversation-load": _vm.onConversationLoad }
        },
        [
          _c("pop-over-search", {
            attrs: { "is-on-expanded-layout": _vm.isOnExpandedLayout },
            on: { "toggle-conversation-layout": _vm.toggleConversationLayout }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showMessageView
        ? _c("conversation-box", {
            attrs: {
              "inbox-id": _vm.inboxId,
              "is-contact-panel-open": _vm.isContactPanelOpen,
              "is-on-expanded-layout": _vm.isOnExpandedLayout
            },
            on: { "contact-panel-toggle": _vm.onToggleContactPanel }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }