var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cw-accordion" }, [
    _c(
      "button",
      {
        staticClass: "cw-accordion--title drag-handle",
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "cw-accordion--title-wrap" },
          [
            _c("emoji-or-icon", {
              staticClass: "icon-or-emoji",
              attrs: { icon: _vm.icon, emoji: _vm.emoji }
            }),
            _vm._v(" "),
            _c("h5", [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "button-icon--wrap" },
          [
            _vm._t("button"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "chevron-icon__wrap" },
              [
                _vm.isOpen
                  ? _c("fluent-icon", {
                      attrs: { size: "24", icon: "subtract", type: "solid" }
                    })
                  : _c("fluent-icon", {
                      attrs: { size: "24", icon: "add", type: "solid" }
                    })
              ],
              1
            )
          ],
          2
        )
      ]
    ),
    _vm._v(" "),
    _vm.isOpen
      ? _c(
          "div",
          {
            staticClass: "cw-accordion--content",
            class: { compact: _vm.compact }
          },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }