var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "avatar-container",
      style: _vm.style,
      attrs: { "aria-hidden": "true" }
    },
    [_vm._v("\n  " + _vm._s(_vm.userInitial) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }