var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conversation--details" },
    [
      _vm.initiatedAt
        ? _c("contact-details-item", {
            staticClass: "conversation--attribute",
            attrs: {
              title: _vm.$t("CONTACT_PANEL.INITIATED_AT"),
              value: _vm.initiatedAt.timestamp
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.browserLanguage
        ? _c("contact-details-item", {
            staticClass: "conversation--attribute",
            attrs: {
              title: _vm.$t("CONTACT_PANEL.BROWSER_LANGUAGE"),
              value: _vm.browserLanguage
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.referer
        ? _c(
            "contact-details-item",
            {
              staticClass: "conversation--attribute",
              attrs: {
                title: _vm.$t("CONTACT_PANEL.INITIATED_FROM"),
                value: _vm.referer
              }
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.referer,
                    rel: "noopener noreferrer nofollow",
                    target: "_blank"
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.referer) + "\n    ")]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.browserName
        ? _c("contact-details-item", {
            staticClass: "conversation--attribute",
            attrs: {
              title: _vm.$t("CONTACT_PANEL.BROWSER"),
              value: _vm.browserName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.platformName
        ? _c("contact-details-item", {
            staticClass: "conversation--attribute",
            attrs: {
              title: _vm.$t("CONTACT_PANEL.OS"),
              value: _vm.platformName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.ipAddress
        ? _c("contact-details-item", {
            staticClass: "conversation--attribute",
            attrs: {
              title: _vm.$t("CONTACT_PANEL.IP_ADDRESS"),
              value: _vm.ipAddress
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("custom-attributes", {
        class: _vm.customAttributeRowClass,
        attrs: {
          "attribute-type": "conversation_attribute",
          "attribute-class": "conversation--attribute"
        }
      }),
      _vm._v(" "),
      _c("custom-attribute-selector", {
        attrs: { "attribute-type": "conversation_attribute" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }