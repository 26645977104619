var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image message-text__wrap" },
    [
      _c("img", {
        attrs: { src: _vm.url },
        on: {
          click: _vm.onClick,
          error: function($event) {
            return _vm.onImgError()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            "full-width": true,
            show: _vm.show,
            "on-close": _vm.onClose
          },
          on: {
            "update:show": function($event) {
              _vm.show = $event
            }
          }
        },
        [_c("img", { staticClass: "modal-image", attrs: { src: _vm.url } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }