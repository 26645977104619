var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-dropdown-item",
    [
      _c(
        "woot-button",
        { attrs: { variant: "clear" }, on: { click: _vm.onClick } },
        [
          _c(
            "span",
            { staticClass: "label-text", attrs: { title: _vm.title } },
            [_vm._v(_vm._s(_vm.title))]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }