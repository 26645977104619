var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reply-box", class: _vm.replyBoxClass },
    [
      _vm.showSelfAssignBanner
        ? _c("banner", {
            attrs: {
              "color-scheme": "secondary",
              "banner-message": _vm.$t("CONVERSATION.NOT_ASSIGNED_TO_YOU"),
              "has-action-button": true,
              "action-button-label": _vm.$t("CONVERSATION.ASSIGN_TO_ME")
            },
            on: { click: _vm.onClickSelfAssign }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("reply-top-panel", {
        attrs: {
          mode: _vm.replyType,
          "set-reply-mode": _vm.setReplyMode,
          "is-message-length-reaching-threshold":
            _vm.isMessageLengthReachingThreshold,
          "characters-remaining": _vm.charactersRemaining,
          "popout-reply-box": _vm.popoutReplyBox
        },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "reply-box__top" },
        [
          _vm.showMentions && _vm.hasSlashCommand
            ? _c("canned-response", {
                directives: [
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.hideMentions,
                    expression: "hideMentions"
                  }
                ],
                staticClass: "normal-editor__canned-box",
                attrs: { "search-key": _vm.mentionSearchKey },
                on: { click: _vm.replaceText }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showEmojiPicker
            ? _c("emoji-input", {
                directives: [
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.hideEmojiPicker,
                    expression: "hideEmojiPicker"
                  }
                ],
                class: _vm.emojiDialogClassOnExpanedLayout,
                attrs: { "on-click": _vm.emojiOnClick }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showReplyHead
            ? _c("reply-email-head", {
                attrs: {
                  "cc-emails": _vm.ccEmails,
                  "bcc-emails": _vm.bccEmails
                },
                on: {
                  "update:ccEmails": function($event) {
                    _vm.ccEmails = $event
                  },
                  "update:cc-emails": function($event) {
                    _vm.ccEmails = $event
                  },
                  "update:bccEmails": function($event) {
                    _vm.bccEmails = $event
                  },
                  "update:bcc-emails": function($event) {
                    _vm.bccEmails = $event
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showAudioRecorderEditor
            ? _c("woot-audio-recorder", {
                ref: "audioRecorderInput",
                attrs: { "audio-record-format": _vm.audioRecordFormat },
                on: {
                  "state-recorder-progress-changed":
                    _vm.onStateProgressRecorderChanged,
                  "state-recorder-changed": _vm.onStateRecorderChanged,
                  "finish-record": _vm.onFinishRecorder
                }
              })
            : !_vm.showRichContentEditor
            ? _c("resizable-text-area", {
                ref: "messageInput",
                staticClass: "input",
                attrs: { placeholder: _vm.messagePlaceHolder, "min-height": 4 },
                on: {
                  "typing-off": _vm.onTypingOff,
                  "typing-on": _vm.onTypingOn,
                  focus: _vm.onFocus,
                  blur: _vm.onBlur
                },
                model: {
                  value: _vm.message,
                  callback: function($$v) {
                    _vm.message = $$v
                  },
                  expression: "message"
                }
              })
            : _c("woot-message-editor", {
                staticClass: "input",
                attrs: {
                  "editor-id": _vm.editorStateId,
                  "is-private": _vm.isOnPrivateNote,
                  placeholder: _vm.messagePlaceHolder,
                  "update-selection-with": _vm.updateEditorSelectionWith,
                  "min-height": 4,
                  "enable-variables": true,
                  variables: _vm.messageVariables
                },
                on: {
                  "typing-off": _vm.onTypingOff,
                  "typing-on": _vm.onTypingOn,
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  "toggle-user-mention": _vm.toggleUserMention,
                  "toggle-canned-menu": _vm.toggleCannedMenu,
                  "toggle-variables-menu": _vm.toggleVariablesMenu,
                  "clear-selection": _vm.clearEditorSelection
                },
                model: {
                  value: _vm.message,
                  callback: function($$v) {
                    _vm.message = $$v
                  },
                  expression: "message"
                }
              })
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAttachments
        ? _c(
            "div",
            {
              staticClass: "attachment-preview-box",
              on: { paste: _vm.onPaste }
            },
            [
              _c("attachment-preview", {
                attrs: {
                  attachments: _vm.attachedFiles,
                  "remove-attachment": _vm.removeAttachment
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSignatureEnabledForInbox
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("CONVERSATION.FOOTER.MESSAGE_SIGN_TOOLTIP"),
                  expression: "$t('CONVERSATION.FOOTER.MESSAGE_SIGN_TOOLTIP')"
                }
              ],
              staticClass: "message-signature-wrap"
            },
            [
              _vm.isSignatureAvailable
                ? _c("p", {
                    directives: [
                      {
                        name: "dompurify-html",
                        rawName: "v-dompurify-html",
                        value: _vm.formatMessage(_vm.messageSignature),
                        expression: "formatMessage(messageSignature)"
                      }
                    ],
                    staticClass: "message-signature"
                  })
                : _c(
                    "p",
                    { staticClass: "message-signature" },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.$t(
                              "CONVERSATION.FOOTER.MESSAGE_SIGNATURE_NOT_CONFIGURED"
                            )
                          ) +
                          "\n      "
                      ),
                      _c("router-link", { attrs: { to: _vm.profilePath } }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("CONVERSATION.FOOTER.CLICK_HERE")) +
                            "\n      "
                        )
                      ])
                    ],
                    1
                  )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("reply-bottom-panel", {
        attrs: {
          "conversation-id": _vm.conversationId,
          "enable-multiple-file-upload": _vm.enableMultipleFileUpload,
          "has-whatsapp-templates": _vm.hasWhatsappTemplates,
          inbox: _vm.inbox,
          "is-on-private-note": _vm.isOnPrivateNote,
          "is-recording-audio": _vm.isRecordingAudio,
          "is-send-disabled": _vm.isReplyButtonDisabled,
          mode: _vm.replyType,
          "on-file-upload": _vm.onFileUpload,
          "on-send": _vm.onSendReply,
          "recording-audio-duration-text": _vm.recordingAudioDurationText,
          "recording-audio-state": _vm.recordingAudioState,
          "send-button-text": _vm.replyButtonLabel,
          "show-audio-recorder": _vm.showAudioRecorder,
          "show-editor-toggle": _vm.isAPIInbox && !_vm.isOnPrivateNote,
          "show-emoji-picker": _vm.showEmojiPicker,
          "show-file-upload": _vm.showFileUpload,
          "toggle-audio-recorder-play-pause": _vm.toggleAudioRecorderPlayPause,
          "toggle-audio-recorder": _vm.toggleAudioRecorder,
          "toggle-emoji-picker": _vm.toggleEmojiPicker
        },
        on: {
          selectWhatsappTemplate: _vm.openWhatsappTemplateModal,
          "toggle-editor": _vm.toggleRichContentEditor
        }
      }),
      _vm._v(" "),
      _c("whatsapp-templates", {
        attrs: {
          "inbox-id": _vm.inbox.id,
          show: _vm.showWhatsAppTemplatesModal
        },
        on: {
          close: _vm.hideWhatsappTemplatesModal,
          "on-send": _vm.onSendWhatsAppReply,
          cancel: _vm.hideWhatsappTemplatesModal
        }
      }),
      _vm._v(" "),
      _c("woot-confirm-modal", {
        ref: "confirmDialog",
        attrs: {
          title: _vm.$t("CONVERSATION.REPLYBOX.UNDEFINED_VARIABLES.TITLE"),
          description: _vm.undefinedVariableMessage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }