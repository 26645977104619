var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "network-notification-fade", tag: "div" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showNotification,
              expression: "showNotification"
            }
          ],
          staticClass: "ui-notification-container"
        },
        [
          _c(
            "div",
            { staticClass: "ui-notification" },
            [
              _c("fluent-icon", { attrs: { icon: "wifi-off" } }),
              _vm._v(" "),
              _c("p", { staticClass: "ui-notification-text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.useInstallationName(
                        _vm.$t("NETWORK.NOTIFICATION.TEXT"),
                        _vm.globalConfig.installationName
                      )
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  attrs: { variant: "clear", size: "small" },
                  on: { click: _vm.refreshPage }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("NETWORK.BUTTON.REFRESH")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("woot-button", {
                attrs: {
                  variant: "smooth",
                  size: "small",
                  "color-scheme": "warning",
                  icon: "dismiss-circle"
                },
                on: { click: _vm.closeNotification }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }