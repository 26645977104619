var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column" }, [
    _c("h2", { staticClass: "page-sub-title" }, [
      _vm._v("\n    " + _vm._s(_vm.headerTitle) + "\n  ")
    ]),
    _vm._v(" "),
    _c("p", {
      directives: [
        {
          name: "dompurify-html",
          rawName: "v-dompurify-html",
          value: _vm.headerContent,
          expression: "headerContent"
        }
      ],
      staticClass: "small-12 column"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }