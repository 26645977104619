var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("LABEL_MGMT.ADD.TITLE"),
          "header-content": _vm.$t("LABEL_MGMT.ADD.DESC")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.addLabel($event)
            }
          }
        },
        [
          _c("woot-input", {
            staticClass: "medium-12 columns",
            class: { error: _vm.$v.title.$error },
            attrs: {
              label: _vm.$t("LABEL_MGMT.FORM.NAME.LABEL"),
              placeholder: _vm.$t("LABEL_MGMT.FORM.NAME.PLACEHOLDER"),
              error: _vm.getLabelTitleErrorMessage,
              "data-testid": "label-title"
            },
            on: { input: _vm.$v.title.$touch },
            model: {
              value: _vm.title,
              callback: function($$v) {
                _vm.title = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "title"
            }
          }),
          _vm._v(" "),
          _c("woot-input", {
            staticClass: "medium-12 columns",
            class: { error: _vm.$v.description.$error },
            attrs: {
              label: _vm.$t("LABEL_MGMT.FORM.DESCRIPTION.LABEL"),
              placeholder: _vm.$t("LABEL_MGMT.FORM.DESCRIPTION.PLACEHOLDER"),
              "data-testid": "label-description"
            },
            on: { input: _vm.$v.description.$touch },
            model: {
              value: _vm.description,
              callback: function($$v) {
                _vm.description = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "description"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "medium-12" }, [
            _c(
              "label",
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("LABEL_MGMT.FORM.COLOR.LABEL")) +
                    "\n        "
                ),
                _c("woot-color-picker", {
                  model: {
                    value: _vm.color,
                    callback: function($$v) {
                      _vm.color = $$v
                    },
                    expression: "color"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "medium-12" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.showOnSidebar,
                  expression: "showOnSidebar"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                value: true,
                checked: Array.isArray(_vm.showOnSidebar)
                  ? _vm._i(_vm.showOnSidebar, true) > -1
                  : _vm.showOnSidebar
              },
              on: {
                change: function($event) {
                  var $$a = _vm.showOnSidebar,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = true,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.showOnSidebar = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.showOnSidebar = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.showOnSidebar = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "conversation_creation" } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("LABEL_MGMT.FORM.SHOW_ON_SIDEBAR.LABEL")) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "div",
              { staticClass: "medium-12 columns" },
              [
                _c(
                  "woot-button",
                  {
                    attrs: {
                      "is-disabled":
                        _vm.$v.title.$invalid || _vm.uiFlags.isCreating,
                      "is-loading": _vm.uiFlags.isCreating,
                      "data-testid": "label-submit"
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("LABEL_MGMT.FORM.CREATE")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "woot-button",
                  {
                    staticClass: "button clear",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onClose($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("LABEL_MGMT.FORM.CANCEL")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }