var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file message-text__wrap" }, [
    _c(
      "div",
      { staticClass: "icon-wrap" },
      [
        _c("fluent-icon", {
          staticClass: "file--icon",
          attrs: { icon: "document", size: "32" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "meta" }, [
      _c("h5", { staticClass: "text-block-title" }, [
        _vm._v("\n      " + _vm._s(decodeURI(_vm.fileName)) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "download clear link button small",
          attrs: {
            rel: "noreferrer noopener nofollow",
            target: "_blank",
            href: _vm.url
          }
        },
        [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("CONVERSATION.DOWNLOAD")) + "\n    "
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }