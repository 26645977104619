var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "menu",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.$emit("click")
        }
      }
    },
    [
      _vm.variant === "icon" && _vm.option.icon
        ? _c("fluent-icon", {
            staticClass: "menu-icon",
            attrs: { icon: _vm.option.icon, size: "14" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.variant === "label" && _vm.option.color
        ? _c("span", {
            staticClass: "label-pill",
            style: { backgroundColor: _vm.option.color }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.variant === "agent"
        ? _c("thumbnail", {
            staticClass: "agent-thumbnail",
            attrs: {
              username: _vm.option.label,
              src: _vm.option.thumbnail,
              size: "20px"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "menu-label text-truncate" }, [
        _vm._v(_vm._s(_vm.option.label))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }