var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label-selector", {
    attrs: { "all-labels": _vm.allLabels, "saved-labels": _vm.savedLabels },
    on: { add: _vm.addItem, remove: _vm.removeItem }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }