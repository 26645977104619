var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-tabs",
    { attrs: { index: _vm.activeTabIndex }, on: { change: _vm.onTabChange } },
    _vm._l(_vm.items, function(item) {
      return _c("woot-tabs-item", {
        key: item.key,
        attrs: { name: item.name, count: item.count }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }