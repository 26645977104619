var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-body small-9 columns" },
    [
      _c("page-header", {
        attrs: {
          "header-title": _vm.$t("INBOX_MGMT.ADD.SMS.TITLE"),
          "header-content": _vm.$t("INBOX_MGMT.ADD.SMS.DESC")
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "medium-8 columns" }, [
        _c("label", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("INBOX_MGMT.ADD.SMS.PROVIDERS.LABEL")) +
              "\n      "
          ),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.provider,
                  expression: "provider"
                }
              ],
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.provider = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c("option", { attrs: { value: "twilio" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("INBOX_MGMT.ADD.SMS.PROVIDERS.TWILIO")) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("option", { attrs: { value: "360dialog" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("INBOX_MGMT.ADD.SMS.PROVIDERS.BANDWIDTH")) +
                    "\n        "
                )
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.provider === "twilio"
        ? _c("twilio", { attrs: { type: "sms" } })
        : _c("bandwidth-sms")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }