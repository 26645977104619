var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-attribute--selector" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "on-clickaway",
            rawName: "v-on-clickaway",
            value: _vm.closeDropdown,
            expression: "closeDropdown"
          }
        ],
        staticClass: "label-wrap",
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.closeDropdown($event)
          }
        }
      },
      [
        _c(
          "woot-button",
          {
            attrs: { size: "small", variant: "link", icon: "add" },
            on: { click: _vm.toggleAttributeDropDown }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("CUSTOM_ATTRIBUTES.ADD_BUTTON_TEXT")) +
                "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "dropdown-wrap" }, [
          _c(
            "div",
            {
              staticClass: "dropdown-pane",
              class: { "dropdown-pane--open": _vm.showAttributeDropDown }
            },
            [
              _vm.showAttributeDropDown
                ? _c("custom-attribute-drop-down", {
                    attrs: {
                      "attribute-type": _vm.attributeType,
                      "contact-id": _vm.contactId
                    },
                    on: { "add-attribute": _vm.addAttribute }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }