var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "watchers-wrap" }, [
    _c("div", { staticClass: "watchers--collapsed" }, [
      _c(
        "div",
        { staticClass: "content-wrap" },
        [
          _c("div", [
            _vm.watchersList.length
              ? _c(
                  "p",
                  { staticClass: "total-watchers message-text" },
                  [
                    _vm.watchersUiFlas.isFetching
                      ? _c("spinner", { attrs: { size: "tiny" } })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.totalWatchersText) +
                        "\n        "
                    )
                  ],
                  1
                )
              : _c("p", { staticClass: "text-muted message-text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("CONVERSATION_PARTICIPANTS.NO_PARTICIPANTS_TEXT")
                      ) +
                      "\n        "
                  )
                ])
          ]),
          _vm._v(" "),
          _c("woot-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.left",
                value: _vm.$t("CONVERSATION_PARTICIPANTS.ADD_PARTICIPANTS"),
                expression: "$t('CONVERSATION_PARTICIPANTS.ADD_PARTICIPANTS')",
                modifiers: { left: true }
              }
            ],
            attrs: {
              title: _vm.$t("CONVERSATION_PARTICIPANTS.ADD_PARTICIPANTS"),
              icon: "settings",
              size: "tiny",
              variant: "smooth",
              "color-scheme": "secondary"
            },
            on: { click: _vm.onOpenDropdown }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c("thumbnail-group", {
          attrs: {
            "more-thumbnails-text": _vm.moreThumbnailsText,
            "show-more-thumbnails-count": _vm.showMoreThumbs,
            "users-list": _vm.thumbnailList
          }
        }),
        _vm._v(" "),
        _vm.isUserWatching
          ? _c("p", { staticClass: "text-muted message-text" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("CONVERSATION_PARTICIPANTS.YOU_ARE_WATCHING")) +
                  "\n    "
              )
            ])
          : _c(
              "woot-button",
              {
                attrs: { icon: "arrow-right", variant: "link", size: "small" },
                on: { click: _vm.onSelfAssign }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("CONVERSATION_PARTICIPANTS.WATCH_CONVERSATION")
                    ) +
                    "\n    "
                )
              ]
            )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "on-clickaway",
            rawName: "v-on-clickaway",
            value: function() {
              _vm.onCloseDropdown()
            },
            expression:
              "\n      () => {\n        onCloseDropdown();\n      }\n    "
          }
        ],
        staticClass: "dropdown-pane",
        class: { "dropdown-pane--open": _vm.showDropDown }
      },
      [
        _c(
          "div",
          { staticClass: "dropdown__header" },
          [
            _c("h4", { staticClass: "text-block-title text-truncate" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CONVERSATION_PARTICIPANTS.ADD_PARTICIPANTS")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("woot-button", {
              attrs: {
                icon: "dismiss",
                size: "tiny",
                "color-scheme": "secondary",
                variant: "clear"
              },
              on: { click: _vm.onCloseDropdown }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("multiselect-dropdown-items", {
          attrs: {
            options: _vm.agentsList,
            "selected-items": _vm.selectedWatchers,
            "has-thumbnail": true
          },
          on: { click: _vm.onClickItem }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }