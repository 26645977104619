var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("report-filter-selector", {
        attrs: {
          "agents-filter": "",
          "agents-filter-items-list": _vm.agentList,
          "show-business-hours-switch": false
        },
        on: {
          "date-range-change": _vm.onDateRangeChange,
          "agents-filter-change": _vm.onAgentsFilterChange
        }
      }),
      _vm._v(" "),
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-right-top",
            icon: "arrow-download"
          },
          on: { click: _vm.downloadReports }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("CSAT_REPORTS.DOWNLOAD")) + "\n  ")]
      ),
      _vm._v(" "),
      _c("csat-metrics"),
      _vm._v(" "),
      _c("csat-table", {
        attrs: { "page-index": _vm.pageIndex },
        on: { "page-change": _vm.onPageNumberChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }