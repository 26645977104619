var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    ref: "textarea",
    attrs: { placeholder: _vm.placeholder },
    domProps: { value: _vm.value },
    on: {
      input: _vm.onInput,
      focus: _vm.onFocus,
      keyup: _vm.onKeyup,
      blur: _vm.onBlur
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }